import Image from "next/image";
import { memo } from "react";
import classNames from "src/helpers/classNames";

type Props = { className?: string; lanternClassName?: string };

function RamadanLogo({
  className = "h-6 w-auto",
  lanternClassName = "absolute -right-6 top-0 h-14 w-6",
}: Props) {
  return (
    <div className="flex gap-1">
      <Image
        className={classNames(className)}
        src={`/images/logo-black.svg`}
        alt="Shemsi"
        width={0}
        height={0}
        priority={true}
      />

      <Image
        className={lanternClassName}
        src={"/images/themes/ramadan/ramadan-lantern.png"}
        alt="ramadan-lantern"
        width={0}
        height={0}
        sizes="2rem"
      />
    </div>
  );
}

export default memo(RamadanLogo);
