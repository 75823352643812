export default function getLocalStorage(key: string) {
	if (typeof window === "undefined") return null;

	try {
		const value = window.localStorage.getItem(key);
		return value ? JSON.parse(value) : null;
	} catch (error) {
		const message =
			error instanceof Error ? error.message : `Error getting ${key}.`;
		console.log({ message });
		return null;
	}
}
