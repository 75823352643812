import getCookie from "../cookie/getCookie";
import {
  generateFbcFallbackKey,
  generateFbpFallbackKey,
} from "../generateFacebookPixelKeys";
import getGoogleAnalyticsClientId from "../getGoogleAnalyticsClientId";
import getLocalStorage from "../local-storage/getLocalStorage";
import Cookies from "js-cookie";

export default function getTrackingMetadata() {
  const visitor_id = Cookies.get("visitor_id");
  return {
    fbp: getCookie("_fbp") || getCookie("fbp") || generateFbpFallbackKey(),
    fbc: getCookie("_fbc") || getCookie("fbc") || generateFbcFallbackKey(),
    ScCid: getLocalStorage("ScCid"),
    googleAnalyticsClientId: getGoogleAnalyticsClientId(),
    distinct_id: visitor_id,
    ttclid: getLocalStorage("ttclid") || "",
    ttp: getCookie("_ttp") || "",
  };
}
