import { memo, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import Image from "next/image";
import CountrySelector from "src/sections/country-selector/CountrySelector";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import PopoverButton from "../button/ThemePopoverButton";
import { useTranslations } from "next-intl";
import useCountryCode from "src/hooks/useCountryCode";

type CountryPopoverProps = {};

function CountryPopover({}: CountryPopoverProps) {
  const t = useTranslations("Navigation");
  const countryCode = useCountryCode();

  return (
    <Popover className="flex">
      {({ open, close }) => (
        <>
          <PopoverButton open={open}>
            <>
              {countryCode && (
                <Image
                  className="w-5"
                  src={`/images/coded-flags/${countryCode}.svg`}
                  alt=""
                  width={0}
                  height={0}
                />
              )}
              <span className="country_popover__deliver ms-2 leading-4 lg:ms-2.5 lg:flex lg:flex-col lg:items-start">
                {t("deliver_to")}{" "}
                <span className="font-bold">{t("country")}</span>
              </span>
              {open ? (
                <ChevronUpIcon
                  className="ms-1 w-4 lg:ms-2.5"
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  className="ms-1 w-4 lg:ms-2.5"
                  aria-hidden="true"
                />
              )}
            </>
          </PopoverButton>

          <Popover.Overlay className="fixed inset-0 z-10 bg-black opacity-30" />

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-0 top-full z-10 w-full transform lg:left-auto lg:right-0 lg:w-60">
              <div className="overflow-hidden rounded bg-white shadow-lg ring-1 ring-beige-700 ring-opacity-5">
                <CountrySelector close={close} />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default memo(CountryPopover);
