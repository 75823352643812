import Link from "next/link";
import ThemeLink from "../../../components/link/ThemeLink";

type StckyFooterButtonProps = {
  showStickyFooterNavigationButton?: boolean;
  stickyFooterButtonTitle?: string;
  stickyFooterButtonURL?: string;
};
const NavigateButton = (props: StckyFooterButtonProps) => {
  const {
    showStickyFooterNavigationButton,
    stickyFooterButtonTitle,
    stickyFooterButtonURL,
  } = props;

  const renderNavigateButton = () => {
    if (showStickyFooterNavigationButton) {
      return (
        <div className="md:hidden">
          <Link
            className="w-full"
            href={stickyFooterButtonURL || "/shop-all"}
            passHref
            legacyBehavior
          >
            <ThemeLink className="w-full">{stickyFooterButtonTitle}</ThemeLink>
          </Link>
        </div>
      );
    }
  };

  return <section className="w-5/6 flex-1">{renderNavigateButton()}</section>;
};

export default NavigateButton;
