import { memo, useRef } from "react";
import { useRouter } from "next/router";
import ThemeSelect from "src/components/theme-select/ThemeSelect";
import useLanguage from "src/hooks/useLanguage";
import useCountryCode from "src/hooks/useCountryCode";
import useCountryData from "src/hooks/useCountryData";
import { Locale } from "src/types/localization/Locale";
import useRtl from "src/hooks/useRtl";

type SelectedLanguage = {
  label: string;
  value: string;
};

type LanguageDropDownProps = {
  setLanguageToCookies: (language: string) => void;
};

function LanguageDropDown({ setLanguageToCookies }: LanguageDropDownProps) {
  const router = useRouter();
  const { query, route } = useRouter();
  const countryCode = useCountryCode();
  const isRtl = useRtl();
  const language: SelectedLanguage = useLanguage();
  const countryData = useCountryData();
  const languages = useRef(countryData ? countryData.supportedLanguages : []);
  // multilanguage picker
  const changeLocale = (locale: Locale) => {
    router.push(
      {
        pathname: route,
        query,
      },
      router.asPath,
      { locale }
    );
  };

  const handleLanguageChange = (option: SelectedLanguage) => {
    changeLocale(`${option.value}-${countryCode}` as Locale);
    setLanguageToCookies(option.value);
  };

  return (
    <div className="flex items-center lg:justify-start">
      <ThemeSelect
        options={languages.current}
        value={
          languages.current.find((lang) => lang.value === language.value) ??
          languages.current[0]
        }
        containerClasses="w-full"
        buttonClasses="border-none flex items-center justify-end lg:justify-start w-full hover:cursor-pointer px-0 language_select__button"
        iconsClasses="ms-2 lg:justify-start relative hover:cursor-pointer"
        listOptionsClasses={`${isRtl ? "left-0" : "right-0"} lg:-left-9 w-40`}
        iconDown={true}
        onChange={(option) => handleLanguageChange(option)}
      />
    </div>
  );
}

export default memo(LanguageDropDown);
