import { memo } from "react";
import Image from "next/image";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import Link from "next/link";
import useLanguage from "src/hooks/useLanguage";
import useCountryCode from "src/hooks/useCountryCode";
import countries from "src/config/countries/countries";
import useCountryData from "src/hooks/useCountryData";

interface CountrySelectorProps {
  close: () => void;
}

function CountrySelector({ close }: CountrySelectorProps) {
  const t = useTranslations("Navigation");
  const language = useLanguage();

  const currentCountryCode = useCountryCode();
  const { pathname, asPath, query } = useRouter();
  const currentCountryData = useCountryData();

  const sortedCountries = [
    ...Object.values(countries).filter(
      (country) => country.code === currentCountryCode
    ),
    ...Object.values(countries).filter(
      (country) => country.code !== currentCountryCode
    ),
  ];

  return (
    <ul>
      {sortedCountries
        .filter(
          (country) =>
            (currentCountryData &&
              country.region === currentCountryData.region) ||
            country.code === "US"
        )
        .map((country) => {
          const countryContent = (
            <>
              <Image
                className="h-6 w-6  me-1"
                src={`/images/coded-flags/${country.code}.svg`}
                alt={`${country.name} flag`}
                width={0}
                height={0}
              />
              <div className="flex flex-col ms-1.5">
                <span className="font-medium">
                  {t("country_" + country.code)}
                </span>
                {country.code !== currentCountryCode && (
                  <span className="text-xs">
                    {t("go_to_website", {
                      country_name: t("country_" + country.code),
                    })}
                  </span>
                )}
                {country.code === currentCountryCode && (
                  <span className="text-xs leading-normal">
                    {t("you_are_browsing", {
                      country: t("country_" + country.code),
                    })}
                  </span>
                )}
              </div>
            </>
          );

          return (
            <li
              className="border-b border-beige-700 text-sm last:border-b-0"
              key={country.code}
            >
              {country.code === currentCountryCode ? (
                <div className="flex items-center gap-2 bg-beige-100 px-4 py-3">
                  {countryContent}
                </div>
              ) : (
                <Link
                  href={{
                    pathname,
                    query,
                  }}
                  as={asPath}
                  locale={`${
                    country.supportedLanguages.some(
                      ({ value }) => value === language.value
                    )
                      ? language.value
                      : country.supportedLanguages[0].value
                  }-${country.code}`}
                  className={`country_selector__${country.code} flex items-center gap-2 px-4 py-3 hover:bg-beige-500 focus:bg-beige-500`}
                  onClick={close}
                >
                  {countryContent}
                </Link>
              )}
            </li>
          );
        })}
    </ul>
  );
}

export default memo(CountrySelector);
