import { useRouter } from "next/router";
import consts from "src/config/consts";
import getCountryCodeFromLocale from "src/helpers/localization-helpers/getCountryCodeFromLocale";
import getLocales from "src/helpers/localization-helpers/getLocales";
import { CountryCode } from "src/types/localization/CountryCode";
import { Locale } from "src/types/localization/Locale";

export default function useCountryCode(): CountryCode | void {
  const { locale } = useRouter();

  if (!locale) return undefined;

  if (locale === "default")
    return getCountryCodeFromLocale(consts.DEFAULT_LOCALE as Locale);

  const locales = getLocales();
  if (!locales.includes(locale as Locale)) {
    throw new Error(`${locale} locale is invalid`);
  }

  return getCountryCodeFromLocale(locale as Locale);
}
