import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Image from "next/image";
import InlineInput from "../inline-input/InlineInput";
import ThemeButton from "../button/ThemeButton";
import useEnhancedAsync from "src/hooks/useEnhancedAsync";
import useCountryData from "src/hooks/useCountryData";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import SuccessMessage from "../alerts/SuccessMessage";
import ModalContainer from "../modal/ModalContainer";
import { ServerApiClient } from "src/apis/server-api-client";
import EventTracker from "src/helpers/event-tracking/eventTrackingManager";
import { KlaviyoSources } from "src/types/enums/Klaviyo.enum";
import {
  GrowthbookClientContext,
  GrowthbookClientProps,
} from "src/context/GrowthbookClientContext";
import { AnimatePresence } from "framer-motion";
import * as EmailValidator from "email-validator";
import getLocalStorage from "src/helpers/local-storage/getLocalStorage";
import setLocalStorage from "src/helpers/local-storage/setLocalStorage";
import consts from "src/config/consts";

type Props = {};

const serverApiClient = new ServerApiClient();

function validateEmail(
  email: string,
  setEmailError: Dispatch<SetStateAction<string>>,
  t: (string: string) => string
) {
  // email
  let emailError = "";
  if (!email) {
    emailError = t("email_is_required");
  } else if (!EmailValidator.validate(email)) {
    emailError = t("validate_email");
  }

  setEmailError(emailError);

  if (emailError.length > 0) {
    return false;
  }
  return true;
}

function ModalNewsletterWithDiscount({}: Props) {
  const t = useTranslations("NewsletterModal");
  const countryData = useCountryData();
  const router = useRouter();

  const { visitorId } = useContext(
    GrowthbookClientContext
  ) as GrowthbookClientProps;

  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [isFormSubmittedOnce, setIsFormSubmittedOnce] = useState(false);

  const [handleSubmit, isLoading, submitError, _] = useEnhancedAsync(
    async (event: FormEvent<HTMLFormElement>, newsletterDiscountAmount) => {
      event.preventDefault();
      setIsFormSubmittedOnce(true);
      setIsSuccess(false);

      // 1. Validate email
      const isEmailValid = validateEmail(email, setEmailError, t);
      if (!isEmailValid) return;

      // 2. Subscribe to newsletter
      const result = await serverApiClient.subscribeToNewsletter({
        email,
        mixpanelUserId: visitorId,
        deployment: router.locale || "",
        country: countryData?.name || "",
        omsConnector: countryData?.omsConnector || "shopify-egypt",
        discount: {
          amount: newsletterDiscountAmount,
          amountType: "percentage",
        },
      });

      if (result.isErr()) {
        throw new Error(JSON.stringify(result.error));
      }

      // 3. Send Mixpanel event
      EventTracker.subscribeToNewsletter(
        email,
        KlaviyoSources.NEWSLETTER_MODAL_DISCOUNT,
        newsletterDiscountAmount
      );

      setIsSuccess(true);
    },
    { customError: t("something_went_wrong"), retry: true, retryCount: 5 }
  );

  // effects
  /**
   * Sets newsletter modal visibility status
   */
  useEffect(
    function setModalVisibilityStatus() {
      const isSeenOnce = getLocalStorage("newsletter_modal_seen_once");
      const savedDiscountCodes: string[] =
        getLocalStorage(`discount_codes_${countryData?.code}`) || [];
      const isFreeShippingDiscount = savedDiscountCodes.includes(
        "rabtaa_free_shipping"
      );
      if (
        !countryData?.newsletterModalConfig ||
        isSeenOnce ||
        isFreeShippingDiscount
      ) {
        return;
      }

      const timeoutId = setTimeout(() => {
        setIsOpen(true);
        setLocalStorage("newsletter_modal_seen_once", true);
      }, 15000);

      return () => clearTimeout(timeoutId);
    },
    [countryData]
  );

  useEffect(
    function validateInputsOnChange() {
      if (isFormSubmittedOnce) {
        validateEmail(email, setEmailError, t);
      }
    },
    [isFormSubmittedOnce, email]
  );

  const newsletterDiscountAmount = parseFloat(consts.NEWSLETTER_DISCOUNT_VALUE);

  return (
    <ModalContainer
      panelClassName="max-w-xl p-6"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <form
        className="space-y-4"
        onSubmit={(event) => handleSubmit(event, newsletterDiscountAmount)}
      >
        <div className="flex flex-col items-center gap-y-4 pt-8 sm:flex-row sm:items-start">
          {/* image */}
          <Image
            src={"/images/referral/girls-group.jpg"}
            alt={""}
            className="w-52 max-w-[16rem] sm:w-1/2"
            width={0}
            height={0}
            sizes="100vw"
          />

          {/* content */}
          <div className="space-y-4">
            <p className="text-lg font-semibold">
              {t("get_newsletter_discount", {
                amount: newsletterDiscountAmount,
              })}
            </p>

            <div className="space-y-2">
              <InlineInput
                label={t("enter_your_email")}
                value={email}
                onChange={(event) => setEmail(event.target.value.trim())}
                errorMessage={emailError}
              />

              <ThemeButton
                type="submit"
                className="w-full py-2.5"
                loading={isLoading}
                error={submitError || ""}
              >
                {t("get_my_discount")}
              </ThemeButton>
            </div>
          </div>
        </div>

        {/* Success message */}
        <AnimatePresence>
          {isSuccess && (
            <SuccessMessage
              message={t("newsletter_success_with_discount", {
                discountAmount: newsletterDiscountAmount,
              })}
            />
          )}
        </AnimatePresence>
      </form>
    </ModalContainer>
  );
}

export default ModalNewsletterWithDiscount;
