import { DBUser } from "src/types/database/DBUser";
import Country from "../localization-helpers/countryClass";
import { captureException } from "@sentry/nextjs";
import { ReferralConfig } from "src/types/localization/ReferralConfig";
import { isNumber } from "lodash";

export default function getReferralConfig(
  user: DBUser,
  countryData: Country | null
) {
  if (!user.referralConfigs || user.referralConfigs.length === 0) {
    captureException(
      `😨 User doesn't have a referral config. Might be someone has changed user's schema`
    );
    return null;
  }

  let result: ReferralConfig;

  // Check if affiliate
  const affiliateConfig = user.referralConfigs.find(
    (config) => config.affiliate
  );
  if (affiliateConfig) {
    result = affiliateConfig;
  } else {
    result = user.referralConfigs[0];
  }

  // If user's referral config doesn't have credits amount in the database, use local referral config
  if (countryData?.referralConfig && !isNumber(result.refereeCredits.amount)) {
    result.refereeCredits.amount =
      countryData.referralConfig.refereeCredits.amount;
  }

  return result;
}
