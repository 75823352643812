import { ChangeEvent, Dispatch, memo, SetStateAction, useEffect } from "react";
import { useTranslations } from "next-intl";
import classNames from "src/helpers/classNames";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

type QuantityControlProps = {
  className?: string;
  quantity: number | "";
  quantityError: string | null;
  setQuantityError: Dispatch<SetStateAction<string | null>>;
  disabled?: boolean;
  onInputChange(event: ChangeEvent<HTMLInputElement>): void;
  onPlusClick(): void;
  onMinusClick(): void;
  outOfStock?: boolean;
};

function QuantityControl({
  className = "",
  quantity,
  quantityError,
  setQuantityError,
  disabled = false,
  onInputChange,
  onPlusClick,
  onMinusClick,
  outOfStock,
}: QuantityControlProps) {
  const t = useTranslations("ProductOverview");

  useEffect(
    function removeErrorOnQuantityChange() {
      if (quantity === "" || Number.isInteger(quantity)) setQuantityError(null);
    },
    [quantity, setQuantityError]
  );

  useEffect(
    function setErrorTimer() {
      let timeoutId: NodeJS.Timeout | null = null;

      if (quantityError) {
        timeoutId = setTimeout(() => setQuantityError(null), 5000);
      } else if (!quantityError && timeoutId) {
        clearTimeout(timeoutId);
      }

      return () => {
        if (timeoutId) clearTimeout(timeoutId);
      };
    },
    [quantityError, setQuantityError]
  );

  return (
    <div
      className={classNames(
        "flex",
        className,
        outOfStock ? "border border-red-600" : "border border-gray-300"
      )}
    >
      <button
        className="cartItem__decreaseButton p-3"
        type="button"
        aria-label="Decrease item quantity by 1"
        onClick={onMinusClick}
        disabled={disabled}
      >
        <MinusIcon className="w-4" />
      </button>
      {/* .quantity-control class is added for e2e testing */}
      <input
        aria-label="quantity-control"
        className="quantity-control w-12 appearance-none border-b-0 border-t-0 border-gray-300 px-1 text-center text-base text-gray-900 focus:border-gray-300 focus:ring-0"
        type="number"
        value={quantity}
        onChange={onInputChange}
        onKeyDown={(event) => {
          const { key } = event;
          if (key === "." || key === "e") event.preventDefault();
        }}
        disabled={disabled}
      />
      <button
        className="p-3"
        type="button"
        aria-label="Increase item quantity by 1"
        onClick={onPlusClick}
        disabled={disabled}
      >
        <PlusIcon className="w-4" />
      </button>
    </div>
  );
}

export default memo(QuantityControl);
