import { BundleDiscountSystem } from "src/types/bundle-discounts/BundleDiscountSystem";
import { Cart } from "src/types/storefront/Cart";
import getBundleDiscountBySku from "./getBundleDiscountBySku";
import preciseDecimal from "../preciseDecimal";
import structuredClone from "@ungap/structured-clone";
import { BundleCart } from "src/types/bundle-discounts/BundleCart";
import flattenCartLineItems from "../cart/flattenCartLineItems";
import consts from "src/config/consts";

export default function mapBundleDiscountsToCart(
  cart: Cart,
  bundleDiscountSystems: BundleDiscountSystem[]
): BundleCart | null {
  const cartClone = structuredClone(cart);
  // 1. Check which bundle discount system the cart is qualified to
  bundleDiscountSystems.sort((a, b) => b.minimumItems - a.minimumItems);
  const lineItems = flattenCartLineItems(cart).filter(
    // Exclude gift cards from line items calculatiing bundle discount
    (item) => !item.merchandise.sku.startsWith(consts.GIFT_CARD_SKU_PREFIX)
  );
  const bundleDiscountSystem = bundleDiscountSystems.find((system) => {
    if (lineItems.length >= system.minimumItems) {
      return system;
    }
  });
  if (!bundleDiscountSystem) {
    return null;
  }

  // 2. Start mapping the bundle system to cart
  const cartWithBundleDisocunts: BundleCart = {
    ...cartClone,
    lines: cartClone.lines
      .filter(
        // Don't calculate bundle discount for gift cards
        (item) => !item.merchandise.sku.startsWith(consts.GIFT_CARD_SKU_PREFIX)
      )
      .map((line) => {
        const bundleDiscount = getBundleDiscountBySku(
          line.merchandise.sku,
          bundleDiscountSystem
        );
        const price = Number(line.merchandise.price.amount);

        return {
          ...line,
          bundlePrice: preciseDecimal(
            price - price * (bundleDiscount.amount / 100)
          ),
          bundleDiscount: bundleDiscount,
        };
      }),
  };

  return cartWithBundleDisocunts;
}
