import axios from "axios";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import getDateDifferenceInDays from "src/helpers/date-helpers/getDateDifferenceInDays";
import getDateDifferenceInMs from "src/helpers/date-helpers/getDateDifferenceInMs";
import useCountryCode from "src/hooks/useCountryCode";
import useEnhancedAsync from "src/hooks/useEnhancedAsync";
import { DraftOrderContext, DraftOrderContextProps } from "./draftOrder";
import usePrevious from "src/hooks/usePrevious";
import { TabbyCheckoutSessionResponse } from "src/types/tabby/TabbyCheckoutSessionResponse";
import getTrackingMetadata from "src/helpers/event-tracking/getTrackingMetadata";
import structuredClone from "@ungap/structured-clone";
import { useStoreState } from "src/hooks/storeHooks";
import countries from "src/config/countries/countries";
import { CountryCode } from "src/types/localization/CountryCode";
export interface TabbyProcessingContextProps {
  tabbySession: null | TabbyCheckoutSessionResponse;
  tabbyStatus: string;
  setTabbySession: Dispatch<SetStateAction<TabbyCheckoutSessionResponse | null>>;
  createTabbySession(...args: any[]): Promise<TabbyCheckoutSessionResponse | null>;
  resetTabbySession(): void;
}

export const TabbyProcessingContext = createContext<TabbyProcessingContextProps | null>(null);

export default function TabbyProcessingProvider({ children }: { children: ReactNode }) {
  const countryCode = useCountryCode();
  const router = useRouter();

  const { locale, isReady, query } = router;
  const [tabbySession, setTabbySession] = useState<TabbyCheckoutSessionResponse | null>(null);
  const [tabbyStatus, setTabbyStatus] = useState<string>("");
  const koalaCheckout = useStoreState((state) => state.checkout);
  const countryData = countries[countryCode as CountryCode];
  const countryOMS = countryData.oms;
  const koalaCheckoutId = koalaCheckout?._id || "";

  console.log({ tabbySession });
  const { draftOrder } = useContext(DraftOrderContext) as DraftOrderContextProps;
  const draftId = countryOMS === "koala" ? koalaCheckoutId : draftOrder?.id;
  /**
   * Checks if there's tabby_session_id cookie
   * and gets the associated tabby session
   */
  useEffect(
    function setTabbySessionWithCookie() {
      if (!countryCode || !draftOrder || tabbySession) return;

      const tabbySessionId = Cookies.get(`tabby_session_id_${countryCode}`);
      if (tabbySessionId) {
        getTabbySession(tabbySessionId).then((tabbySession) => {
          setTabbySession(tabbySession);
        });
      }
    },
    [countryCode, draftOrder]
  );

  /**
   * Resets Tabby session in the context after it expires, and creates a new one
   */
  useEffect(
    function resetSessionWhenExpired() {
      if (!tabbySession || !draftOrder) return;

      const timeoutId = setTimeout(() => {
        resetTabbySession();
        createTabbySession().then((tabbySession) => setTabbySession(tabbySession));
      }, getDateDifferenceInMs(new Date(), new Date(tabbySession.configuration.expires_at)));

      return () => clearTimeout(timeoutId);
    },
    [tabbySession]
  );

  /**
   * Create a new tabby session if the draft order changes
   */
  const previousDraftOrder = usePrevious(draftOrder);
  useEffect(
    function createNewTabbySessionOnDraftOrderChange() {
      if (
        tabbySession &&
        draftOrder &&
        previousDraftOrder &&
        JSON.stringify(draftOrder) !== JSON.stringify(previousDraftOrder)
      ) {
        createTabbySession().then((tabbySession) => setTabbySession(tabbySession));
      }
    },
    [draftOrder]
  );

  /**
   * resets tabby checkout session when the customer cancels the payment
   * When a customer cancels Tabby checkout process
   * it redirects to /shop-all with ?tabby_status=cancel param
   */
  useEffect(
    function resetTabbySessionOnPaymentCancel() {
      if (!isReady) return; // guarantees that query's parameters are there

      if (query.tabby_status === "cancel" || query.tabby_status === "failure") {
        setTabbyStatus(query.tabby_status);
        // reset the session as the customer can't use it again tp complete the payment
        resetTabbySession();
        // remove the tabby_status param from the query
        const queryClone = structuredClone(query);
        delete queryClone.tabby_status;
        router.push({ query: queryClone }, undefined, { shallow: true });
      }
    },
    [isReady]
  );

  const [getTabbySession] = useEnhancedAsync(async (sessionId: string): Promise<TabbyCheckoutSessionResponse> => {
    const getSessionResponse = await axios.get<{
      tabbySession?: TabbyCheckoutSessionResponse;
      success: boolean;
      message?: string;
      sessionId: string;
    }>(`/api/payments/tabby/get-session/?sessionId=${sessionId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const { tabbySession, message } = getSessionResponse.data;

    if (!tabbySession) {
      throw new Error(message);
    }

    return tabbySession;
  });

  const [createTabbySession] = useEnhancedAsync(async (): Promise<TabbyCheckoutSessionResponse> => {
    console.log("createTabbySession");

    const createSessionResponse = await axios.post<{
      tabbySession?: TabbyCheckoutSessionResponse;
      success: boolean;
      message?: string;
      sessionId: string;
    }>(
      `/api/payments/tabby/create-session/?countryCode=${countryCode}`,
      {
        draftOrderId: countryOMS === "shopify" ? draftOrder?.id : koalaCheckoutId,
        locale,
        trackingMetadata: getTrackingMetadata(),
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const { tabbySession, message } = createSessionResponse.data;

    if (!tabbySession) {
      throw new Error(message);
    }

    Cookies.set(`tabby_session_id_${countryCode}`, tabbySession.id, {
      expires: getDateDifferenceInDays(new Date(), new Date(tabbySession.configuration.expires_at)),
    });
    return tabbySession;
  });

  const resetTabbySession = () => {
    setTabbySession(null);
    Cookies.remove(`tabby_session_id_${countryCode}`);
  };

  return (
    <TabbyProcessingContext.Provider
      value={{
        tabbySession,
        setTabbySession,
        tabbyStatus,
        createTabbySession,
        resetTabbySession,
      }}
    >
      {children}
    </TabbyProcessingContext.Provider>
  );
}
