import { Collection, Variant } from "src/types/storefront";

export default function getVariantBySkuFromCollection(
  collection: Collection,
  sku: string
): Variant | null {
  let result: Variant | null = null;

  for (const product of collection.products) {
    for (const variant of product.variants) {
      if (variant.sku === sku) {
        result = variant;
      }
    }
  }

  return result;
}
