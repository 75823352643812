import Image from "next/image";
import { memo } from "react";
import classNames from "src/helpers/classNames";
import useCountryData from "src/hooks/useCountryData";

type Props = { className?: string };

function Logo({ className = "h-6 w-auto" }: Props) {
  const countryData = useCountryData();

  return (
    <Image
      className={classNames(className)}
      src={
        countryData?.christmasTheme
          ? `/images/logos/shemsi-logo-christmas.svg`
          : `/images/logo-black.svg`
      }
      alt="Shemsi"
      width={0}
      height={0}
      priority={true}
    />
  );
}

export default memo(Logo);
