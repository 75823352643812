import { sumBy } from "lodash";
import getCartSubtotalPrice from "../cart/getCartSubtotalPrice";
import { BundleCart } from "src/types/bundle-discounts/BundleCart";
import preciseDecimal from "../preciseDecimal";

export default function getBundleDicountAmountFromCart(
  bundleCart: BundleCart
): number {
  const subtotalPrice = getCartSubtotalPrice(bundleCart);
  const bundlePrice = sumBy(
    bundleCart.lines,
    (line) => line.bundlePrice * line.quantity
  );
  return preciseDecimal(subtotalPrice - bundlePrice);
}
