import Head from "next/head";
import TopBar from "../topbar/TopBar";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import LanguageAndCountryBar from "../language-and-country-bar/LanguageAndCountryBar";
import Container from "../container/Container";
import StickyFooter from "../sticky-footer/StickyFooter";
import { ReactNode, useContext, useEffect } from "react";
import useRtl from "src/hooks/useRtl";
import { useTranslations } from "next-intl";
import CheckoutHeader from "../header/CheckoutHeader";
import CheckoutFooter from "../footer/CheckoutFooter";
import { CartContext, CartContextProps } from "src/context/cart";
import CartUpdateModal from "./components/CartUpdateModal";
import useCountryData from "src/hooks/useCountryData";
import { ReferralContext, ReferralContextProps } from "src/context/ReferralContext";
import Modal from "src/components/modal/Modal";
import Notification from "src/components/Notification";
import { CreditsContext, CreditsContextProps } from "src/context/CreditsContext";
import preciseDecimal from "src/helpers/preciseDecimal";
import ModalNewsletterWithDiscount from "src/components/newsletter-modal/ModalNewsletterWithDiscount";
import ChristmaTopBar from "../topbar/ChristmaTopBar";
import SnowBackground from "src/components/snowflakes/SnowflakesBackground";
import classNames from "src/helpers/classNames";
import RamadanTopbar from "../topbar/RamadanTopbar";
import useStoreInitialData from "src/hooks/useStoreInitialData";
import { useStoreState } from "src/hooks/storeHooks";
import KoalaCartUpdateModal from "./components/KoalaCartUpdateModal";

type Props = {
  children: ReactNode;
  pageTitle: string;
  description?: string;
  showStickyFooterNavigationButton?: boolean;
  showStickyFooterPopUpButton?: boolean;
  StickyFooterPopUpComponent?: JSX.Element;
  stickyFooterButtonTitle?: string;
  stickyFooterButtonURL?: string;
  isCheckout?: boolean;
  showTopBar?: boolean;
};

function Layout({
  children,
  pageTitle,
  description = "Generated by create next app",
  showStickyFooterNavigationButton = false,
  showStickyFooterPopUpButton = false,
  StickyFooterPopUpComponent,
  stickyFooterButtonTitle,
  stickyFooterButtonURL = "/shop-all",
  showTopBar = true,
  isCheckout = false,
}: Props) {
  const t = useTranslations("Layout");
  const isRtl = useRtl();

  useEffect(
    function setBodyElementDirection() {
      document.body.dir = isRtl ? "rtl" : "ltr";
    },
    [isRtl]
  );

  const title = `${pageTitle} | ${t("shemsi")} - ${t("country")}`;
  const countryData = useCountryData();
  // const { outOfStockItems } = useContext(CartContext) as CartContextProps;
  const oosItems = useStoreState((state) => state.oosItems);

  /**
   * Referral logic
   */
  const { activeReferralConfig, referralModalIsOpen, setReferralModalIsOpen } = useContext(
    ReferralContext
  ) as ReferralContextProps;

  /**
   * Get credit factor
   */
  const { creditFactor } = useContext(CreditsContext) as CreditsContextProps;

  /**
   * Koala logic
   */
  useStoreInitialData();

  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="google-site-verification" content="og8KfnycO1DRfAImxj6oAOBbIO48jTvcIM681Xy5ZS4" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div
        className={
          countryData?.christmasTheme && !isCheckout ? "bg-gradient-to-b from-cyan-200 via-white via-5% to-white" : ""
        }
      >
        {countryData?.christmasTheme && !isCheckout && (
          <div className="fixed inset-0">
            <SnowBackground />
          </div>
        )}
        {!isCheckout &&
          countryData &&
          countryData.topbar &&
          showTopBar &&
          (countryData.christmasTheme ? <ChristmaTopBar /> : countryData.ramadanTheme ? <RamadanTopbar /> : <TopBar />)}

        {/* Header */}
        <div className="sticky top-0 z-40">
          {!isCheckout && <Header />}
          {isCheckout && <CheckoutHeader />}
        </div>

        {/* Language and country bar */}
        {!isCheckout && (
          <div className="relative z-30 lg:hidden">
            <Container className={classNames("!py-0", !countryData?.christmasTheme ? "bg-white" : "")}>
              <LanguageAndCountryBar />
            </Container>
          </div>
        )}

        <main className="relative">{children}</main>

        <StickyFooter
          showStickyFooterNavigationButton={showStickyFooterNavigationButton}
          showStickyFooterPopUpButton={showStickyFooterPopUpButton}
          stickyFooterButtonTitle={stickyFooterButtonTitle}
          stickyFooterButtonURL={stickyFooterButtonURL}
          StickyFooterPopUpComponent={StickyFooterPopUpComponent}
        />
        {isCheckout && <CheckoutFooter />}
        {!isCheckout && (
          <div className="relative">
            <Footer
              showStickyFooterNavigationButton={showStickyFooterNavigationButton}
              showStickyFooterPopUpButton={showStickyFooterPopUpButton}
            />
          </div>
        )}

        {/* <CartUpdateModal isOpen={outOfStockItems.length > 0} /> */}
        <KoalaCartUpdateModal isOpen={oosItems.length > 0} />

        <Notification />

        {!isCheckout && activeReferralConfig && countryData && creditFactor && (
          <Modal
            id="referral-modal"
            isOpen={referralModalIsOpen}
            onClose={() => setReferralModalIsOpen(false)}
            onClick={() => setReferralModalIsOpen(false)}
            title={t(`referral_modal_title_${activeReferralConfig.refereeCredits.amountType}`, {
              reward:
                activeReferralConfig.refereeCredits.amountType === "percentage"
                  ? activeReferralConfig.refereeCredits.amount
                  : preciseDecimal(activeReferralConfig.refereeCredits.amount * creditFactor),
              currency: countryData.currency,
            })}
            subtitle={t("referral_modal_subtitle", {
              referralCode: activeReferralConfig.code,
            })}
            buttonLabel={t("referral_modal_button")}
            href="/shop-all"
            icon="/images/party.svg"
          />
        )}

        {!isCheckout && <ModalNewsletterWithDiscount />}
      </div>
    </div>
  );
}

export default Layout;
