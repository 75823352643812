enum FacebookPixelEventsEnum {
  ADD_PAYMENT_INFO = "AddPaymentInfo",
  ADD_TO_CART = "AddToCart",
  COMPLETE_REGISTRATION = "CompleteRegistration",
  CONTACT = "Contact",
  CUSTOMISE_PRODUCT = "CustomizeProduct",
  INITIATE_CHECKOUT = "InitiateCheckout",
  LEAD = "Lead",
  PURCHASE = "Purchase Fallback",
  VIEW_CONTENT = "ViewContent",
  PAGE_VIEW = "PageView",
  VISIT = "Visit",
}

export default FacebookPixelEventsEnum;
