import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { Fragment, memo } from "react";
import classNames from "src/helpers/classNames";
import { useTranslations } from "next-intl";
import useCountryCode from "src/hooks/useCountryCode";

type Props = {};

function LearnPopover({}: Props) {
  const t = useTranslations("Navigation");
  const countryCode = useCountryCode();

  const pages1 = [
    { text: t("how_it_works"), href: "/learn" },
    { text: t("how_to_wash"), href: "/how-to-wash" },
    { text: t("size_guide"), href: "/sizing-grid" },

    /*{ text: "Blog", href: "/blog" },*/
  ];

  const pages2 = [
    { text: t("mission"), href: "/mission" },
    { text: t("faqs"), href: "/faq" },
    { text: t("our_ambassadors"), href: "/our-ambassadors" },
    { text: t("why_trust_us"), href: "/they-trust-us" },
    { text: t("store_locator"), href: "/store-finder" },
  ];

  const pages2USA = [
    { text: t("mission"), href: "/mission" },
    { text: t("faqs"), href: "/faq" },
    { text: t("why_trust_us"), href: "/they-trust-us" },
  ];
  return (
    <Popover className="flex">
      {({ open }) => (
        <>
          <div className="relative flex">
            <Popover.Button
              className={classNames(
                open
                  ? "border-beige-900 text-beige-900"
                  : "border-transparent text-gray-700 hover:text-gray-800",
                "relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out focus:border-beige-900 focus:text-beige-900 focus:outline-none focus:ring-0"
              )}
            >
              {t("learn")}
              {/* <ChevronDownIcon className="ms-2 w-4" aria-hidden="true" /> */}
              {open ? (
                <ChevronUpIcon className="ms-2 w-4" aria-hidden="true" />
              ) : (
                <ChevronDownIcon className="ms-2 w-4" aria-hidden="true" />
              )}
            </Popover.Button>
          </div>

          <Popover.Overlay className="fixed inset-0 z-10 bg-black opacity-30" />

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Panel className="absolute inset-x-0 top-full z-10">
              {({ close }) => (
                <>
                  <div
                    className="absolute inset-0 top-1/2 bg-white shadow"
                    aria-hidden="true"
                  />

                  <div className="relative bg-white">
                    <div className="mx-auto max-w-7xl px-8 py-8">
                      <div className="grid grid-cols-4 gap-x-8 gap-y-10">
                        <div>
                          <h3 className="block py-2 font-semibold">
                            {t("how_to_use")}
                          </h3>
                          <ul>
                            {pages1.map((page) => (
                              <li key={page.text}>
                                <Link
                                  href={page.href}
                                  className="block py-1"
                                  onClick={() => close()}
                                >
                                  {page.text}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <h3 className="block py-2 font-semibold">
                            {t("discover")}
                          </h3>
                          <ul>
                            {countryCode === "US"
                              ? pages2USA.map((page) => (
                                  <li key={page.text}>
                                    <Link
                                      href={page.href}
                                      className="block py-1"
                                      onClick={() => close()}
                                    >
                                      {page.text}
                                    </Link>
                                  </li>
                                ))
                              : pages2.map((page) => (
                                  <li key={page.text}>
                                    <Link
                                      href={page.href}
                                      className="block py-1"
                                      onClick={() => close()}
                                    >
                                      {page.text}
                                    </Link>
                                  </li>
                                ))}
                          </ul>
                        </div>
                        <div>
                          {/*<h3 className="block py-2 font-semibold">
														Our story
													</h3>
													<ul>
														{pages3.map((page) => (
															<li key={page.text}>
																<Link href={page.href}>
																	<a
																		className="block py-1"
																		onClick={() => close()}>
																		{page.text}
																	</a>
																</Link>
															</li>
														))}
														</ul>*/}
                        </div>
                        {/* {countryCode !== "US" && (
                          <div>
                            <AutoPlayVideoPlayer
                              videoId="TN5Bzllrq3g"
                              cover="/images/thumbnail-how-it-works.webp"
                              alt={t("alt_video")}
                              imageSize="220px"
                            />
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default memo(LearnPopover);
