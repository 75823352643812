import { useContext } from "react";
import {
  GrowthbookClientContext,
  GrowthbookClientProps,
} from "src/context/GrowthbookClientContext";

export default function useGrowthbookFeature(key: string, fallback: any) {
  const { growthbook } = useContext(
    GrowthbookClientContext
  ) as GrowthbookClientProps;

  const isExperimentActive = growthbook?.getFeatureValue(key, fallback);

  return isExperimentActive;
}
