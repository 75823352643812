import Image from "next/image";
import Link from "next/link";
import React, { useContext, useEffect, useState } from "react";
import ThemeButton from "src/components/button/ThemeButton";
import BottomBar from "./components/BottomBar";
import { useTranslations } from "next-intl";
import classNames from "src/helpers/classNames";
import useRtl from "src/hooks/useRtl";
import useEnhancedAsync from "src/hooks/useEnhancedAsync";
import BlackSpinIcon from "src/components/icon/BlackSpinIcon";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import Aos from "aos";
import "aos/dist/aos.css";
import useCountryCode from "src/hooks/useCountryCode";
import useCountryData from "src/hooks/useCountryData";
import SubscribeToNewsletter from "src/helpers/SubscribeToNewsletter";
import getCookie from "src/helpers/cookie/getCookie";
import { useRouter } from "next/router";
import { KlaviyoSources } from "src/types/enums/Klaviyo.enum";
import {
  GrowthbookClientContext,
  GrowthbookClientProps,
} from "src/context/GrowthbookClientContext";
import Logo from "src/components/logo/Logo";

enum validationErrorTypes {
  email,
}

type FooterProps = {
  showStickyFooterNavigationButton?: boolean;
  showStickyFooterPopUpButton?: boolean;
};

const Footer = ({
  showStickyFooterNavigationButton,
  showStickyFooterPopUpButton,
}: FooterProps) => {
  const { pathname } = useRouter();
  const [newsletterStatus, setNewsletterStatus] = useState({
    success: false,
    message: "",
  });
  const [email, setEmail] = useState("");
  const [validationErrors, setValidationErrors] = useState<
    validationErrorTypes[]
  >([]);
  const [hasFormSubmittedOnce, setHasFormSubmittedOnce] = useState(false);

  const rtl = useRtl();

  const t = useTranslations("Navigation");
  const countryCode = useCountryCode();
  const countryData = useCountryData();
  const { locale } = useRouter();

  // const visitor_id = Cookies.get("visitor_id");

  const { visitorId } = useContext(
    GrowthbookClientContext
  ) as GrowthbookClientProps;

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      once: true,
    });
  });

  useEffect(() => {
    if (hasFormSubmittedOnce) validateInputs();
  }, [email]);

  const footerNavigation = countryData && {
    explore: [
      { name: t("home"), href: "/" },
      { name: t("shop"), href: "/shop-all" },
      { name: t("learn"), href: "/learn" },
      { name: t("how_to_wash"), href: "/how-to-wash" },
      { name: t("ambassadors"), href: "/ambassadors" },
      { name: t("mission"), href: "/mission" },
      { name: t("store_locator"), href: "/store-finder" },
    ],
    informations: [
      { name: t("legal"), href: "/legal" },
      { name: t("privacy_policy"), href: "/privacy-policy" },
      { name: t("returns-exchanges"), href: "/returns-exchanges" },
      { name: t("faqs"), href: "/faq" },
      { name: t("size_guide"), href: "/sizing-grid" },
      { name: t("our_ambassadors"), href: "/our-ambassadors" },
      { name: t("contact"), href: "/contact" },
      { name: t("ngo"), href: "/ngo" },
    ],
    followUs: [
      {
        name: "Instagram",
        src: "/images/social/instagram.png",
        href:
          "https://www.instagram.com/" +
          countryData.socialInfo.INSTAGRAM_HANDLE +
          "/",
      },
      {
        name: "Facebook",
        src: "/images/social/facebook.png",
        href:
          "https://www.facebook.com/" +
          countryData.socialInfo.FACEBOOK_PAGE_HANDLE +
          "/",
      },
      {
        name: "YouTube",
        src: "/images/social/youtube.png",
        href:
          "https://www.youtube.com/channel/" +
          countryData.socialInfo.YOUTUBE_CHANNEL,
      },
      {
        name: "Tiktok",
        src: "/images/social/tiktok.png",
        href:
          "https://www.tiktok.com/@" + countryData.socialInfo.TIKTOK_CHANNEL,
      } /*,
      {
        name: "snapchat",
        src: "/images/social/snapchat.png",
        href: "https://www.snapchat.com",
      },*/,
    ],
  };

  const footerNavigationUSA = countryData && {
    explore: [
      { name: t("home"), href: "/" },
      { name: t("shop"), href: "/shop-all" },
      { name: t("learn"), href: "/learn" },
      { name: t("how_to_wash"), href: "/how-to-wash" },
      { name: t("ambassadors"), href: "/ambassadors" },
      { name: t("mission"), href: "/mission" },
    ],
    informations: [
      { name: t("legal"), href: "/legal" },
      { name: t("privacy_policy"), href: "/privacy-policy" },
      { name: t("faqs"), href: "/faq" },
      { name: t("size_guide"), href: "/sizing-grid" },
      { name: t("contact"), href: "/contact" },
    ],
    followUs: [
      {
        name: "Instagram",
        src: "/images/social/instagram.png",
        href:
          "https://www.instagram.com/" +
          countryData.socialInfo.INSTAGRAM_HANDLE +
          "/",
      },
      {
        name: "Facebook",
        src: "/images/social/facebook.png",
        href:
          "https://www.facebook.com/" +
          countryData.socialInfo.FACEBOOK_PAGE_HANDLE +
          "/",
      },
      {
        name: "YouTube",
        src: "/images/social/youtube.png",
        href:
          "https://www.youtube.com/channel/" +
          countryData.socialInfo.YOUTUBE_CHANNEL,
      },
      {
        name: "Tiktok",
        src: "/images/social/tiktok.png",
        href:
          "https://www.tiktok.com/@" + countryData.socialInfo.TIKTOK_CHANNEL,
      } /*,
      {
        name: "snapchat",
        src: "/images/social/snapchat.png",
        href: "https://www.snapchat.com",
      },*/,
    ],
  };

  const validateInputs = (): validationErrorTypes[] => {
    // const emojisRegex =
    // 	/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
    const errors: validationErrorTypes[] = [];
    if (
      !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email) ||
      // emojisRegex.test(email) ||
      email.length == 0
    )
      errors.push(validationErrorTypes.email);

    setValidationErrors(errors);
    return errors;
  };

  const handleResetEmail = () => {
    setEmail("");
  };

  const [_handleSubmit, submissionLoading, error] = useEnhancedAsync(
    async (e) => {
      e.preventDefault();

      setHasFormSubmittedOnce(true);

      const errors = validateInputs();

      if (errors.length > 0) {
        setNewsletterStatus({
          success: false,
          message: t("enter_valid_email"),
        });
        return;
      }

      try {
        SubscribeToNewsletter({
          email,
          source: KlaviyoSources.SITE_FOOTER,
          country: getCookie("country") || "",
          deployment: locale || "",
          mixpanelUserId: visitorId,
        });
        handleResetEmail();
        setNewsletterStatus({
          success: true,
          message: t("newsletter_submit_successful"),
        });
      } catch (error) {
        setNewsletterStatus({
          success: false,
          message: "Error: " + error,
        });
      }
    },
    { customError: t("something_went_wrong") }
  );

  // if (pathname.includes("/drshemsi")) return <></>;

  return (
    <footer
      aria-labelledby="footer-heading"
      className={`bg-beige-200 ${
        showStickyFooterNavigationButton === false &&
        showStickyFooterPopUpButton === false
          ? ""
          : "mb-20"
      } md:mb-0 ${pathname.includes("/drshemsi") ? "hidden md:block" : ""}`}
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 sm:pt-10 lg:px-8 lg:pt-12">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          {/* About Shemsi sections */}
          <div className="mb-12 lg:mb-16">
            <Logo
              className={classNames(
                "mb-5 w-auto",
                countryData?.christmasTheme ? "h-9" : "h-6"
              )}
            />

            <p className="max-w-xl font-medium">
              {t("shemsi_brand_explanation")}
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-flow-col md:auto-rows-min md:grid-cols-12 md:gap-x-8 md:gap-y-16">
            {/* Sitemap sections */}
            <div className="col-span-1 mt-10 grid grid-cols-2 gap-8 sm:grid-cols-3 md:col-span-12 md:col-start-1 md:row-start-1 md:mt-0 lg:col-span-7 lg:col-start-1">
              <div className="grid grid-cols-1 gap-y-12 sm:col-span-2 sm:grid-cols-2 sm:gap-x-8">
                <div>
                  <h3 className="font-semibold text-gray-900">
                    {t("discover")}
                  </h3>
                  <ul role="list" className="mt-6 space-y-6">
                    {countryCode === "US"
                      ? footerNavigationUSA &&
                        footerNavigationUSA.explore.map((item) => (
                          <li key={item.name} className="text-sm">
                            <Link
                              href={item.href}
                              className="hover:text-gray-600"
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))
                      : footerNavigation &&
                        footerNavigation.explore.map((item) => (
                          <li key={item.name} className="text-sm">
                            <Link
                              href={item.href}
                              className="hover:text-gray-600"
                              legacyBehavior
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))}
                  </ul>
                </div>
                <div>
                  <h3 className="font-semibold text-gray-900">
                    {t("information")}
                  </h3>
                  <ul role="list" className="mt-6 space-y-6">
                    {countryCode === "US"
                      ? footerNavigationUSA &&
                        footerNavigationUSA.informations.map((item) => (
                          <li key={item.name} className="text-sm">
                            <Link
                              href={item.href}
                              className="hover:text-gray-600"
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))
                      : footerNavigation &&
                        footerNavigation.informations.map((item) => (
                          <li key={item.name} className="text-sm">
                            <Link
                              href={item.href}
                              className="hover:text-gray-600"
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))}
                  </ul>
                </div>
              </div>
              <div>
                <h3 className="font-semibold text-gray-900">
                  {t("follow_us")}
                </h3>
                <ul
                  role="list"
                  className={classNames(
                    "-ms-3 mt-4 flex items-center",
                    rtl ? "-mr-3" : "-ml-3"
                  )}
                >
                  {countryCode === "US"
                    ? footerNavigationUSA &&
                      footerNavigationUSA.followUs.map((item) => (
                        <li key={item.name} className="text-sm">
                          <Link
                            href={item.href}
                            className="block p-3 hover:text-gray-600"
                            target="_blank"
                          >
                            <Image
                              className="h-auto"
                              src={item.src}
                              alt={item.name}
                              width={24}
                              height={24}
                            />
                          </Link>
                        </li>
                      ))
                    : footerNavigation &&
                      footerNavigation.followUs.map((item) => (
                        <li key={item.name} className="text-sm">
                          <Link
                            href={item.href}
                            className="block p-3 hover:text-gray-600"
                            target="_blank"
                          >
                            <Image
                              className="h-auto"
                              src={item.src}
                              alt={item.name}
                              width={24}
                              height={24}
                            />
                          </Link>
                        </li>
                      ))}
                </ul>
              </div>
            </div>

            {/* Newsletter section */}
            <div className="mt-12 md:col-span-8 md:col-start-1 md:row-start-2 md:mt-0 lg:col-span-4 lg:col-start-9 lg:row-start-1">
              <h3 className="font-semibold text-gray-900">
                {t("sign_up_newsletter")}
              </h3>

              <form
                className="mt-2 sm:max-w-md"
                onSubmit={_handleSubmit}
                noValidate
              >
                <div className="flex">
                  <label htmlFor="email-address" className="sr-only">
                    {t("email_address")}
                  </label>
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    placeholder={t("enter_your_email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full min-w-0 appearance-none rounded-sm border border-gray-300 bg-white px-4 py-2 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-beige-700 focus:outline-none focus:ring-1 focus:ring-gray-700"
                  />
                  <div className="ms-4 flex-shrink-0">
                    <ThemeButton type="submit" disabled={submissionLoading}>
                      <>
                        {submissionLoading && (
                          <BlackSpinIcon className="-ms-1 me-3" />
                        )}
                        {t("join_newsletter")}
                      </>
                    </ThemeButton>
                  </div>
                </div>
                {!submissionLoading &&
                  newsletterStatus.message &&
                  (newsletterStatus.success ? (
                    <div className="mt-4" data-aos="fade-left">
                      <div className="rounded-md bg-green-50 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <CheckCircleIcon
                              className="h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ms-3">
                            <h3 className="text-sm font-medium text-green-800">
                              {newsletterStatus.message}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-4" data-aos="fade-left">
                      <div className="rounded-md bg-red-200 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <XCircleIcon
                              className="h-5 w-5 text-red-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ms-3">
                            <h3 className="text-sm font-medium text-red-800">
                              {newsletterStatus.message}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                <p className="mt-6 text-sm">{t("newsletter_subtext")}</p>
              </form>
            </div>
          </div>
        </div>
      </div>

      <BottomBar />
    </footer>
  );
};

export default Footer;
