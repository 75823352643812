import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import useCountryData from "src/hooks/useCountryData";
import { CartContext, CartContextProps } from "./cart";
import mapBundleDiscountsToCart from "src/helpers/bundle-discounts/mapBundleDiscountsToCart";
import { Cart } from "src/types/storefront/Cart";
import Country from "src/helpers/localization-helpers/countryClass";
import { BundleCart } from "src/types/bundle-discounts/BundleCart";

export const BundleDiscountContext = createContext<BundleCart | null>(null);

async function applyBundleDiscountBasedOnItemsQuantity({
  countryData,
  cart,
  setBundleCart,
}: {
  countryData: Country | null;
  cart: Cart | null;
  setBundleCart: Dispatch<SetStateAction<BundleCart | null>>;
}) {
  /**
   * Exits if:
   * 1. deployment doesn't support bundle discount
   * 2. customer didn't add items to cart yet
   */
  if (!countryData?.bundleDiscountSystems || !cart) {
    setBundleCart(null);
    return;
  }

  const cartWithBundleDiscounts = mapBundleDiscountsToCart(
    cart,
    countryData.bundleDiscountSystems
  );
  setBundleCart(cartWithBundleDiscounts);
}

export default function BundleDiscountProvider({
  children,
}: {
  children: ReactNode;
}) {
  // Dependencies
  const countryData = useCountryData();
  const { cart } = useContext(CartContext) as CartContextProps;
  const [bundleCart, setBundleCart] = useState<null | BundleCart>(null);

  // Check if cart items quantity qualify for the bundle discount
  useEffect(() => {
    applyBundleDiscountBasedOnItemsQuantity({
      countryData,
      cart,
      setBundleCart,
    });
  }, [cart, countryData]);

  return (
    <BundleDiscountContext.Provider value={bundleCart}>
      {children}
    </BundleDiscountContext.Provider>
  );
}
