import { BundleDiscountSystem } from "src/types/bundle-discounts/BundleDiscountSystem";
import { BundleDiscountType } from "src/types/bundle-discounts/BundleDiscountType";

export const bundleDiscountSystems: BundleDiscountSystem[] = [
  {
    type: BundleDiscountType.bundle3,
    minimumItems: 3,
    range: ["3", "4"],
    discountsPerItem: {
      // ipanema
      C01BM: {
        amount: 10,
        type: "percentage",
      },
      C01CM: {
        amount: 10,
        type: "percentage",
      },
      C01PM: {
        amount: 10,
        type: "percentage",
      },
      C01VM: {
        amount: 10,
        type: "percentage",
      },
      C01DM: {
        amount: 10,
        type: "percentage",
      },
      // ipanema+
      C01BF: {
        amount: 9,
        type: "percentage",
      },
      C01CF: {
        amount: 9,
        type: "percentage",
      },
      C01PF: {
        amount: 9,
        type: "percentage",
      },
      C01VF: {
        amount: 9,
        type: "percentage",
      },
      C01DF: {
        amount: 9,
        type: "percentage",
      },
      // alexandria
      C03BM: {
        amount: 20,
        type: "percentage",
      },
      // alexandria+
      C03BF: {
        amount: 20,
        type: "percentage",
      },
      // bahia
      C02BM: {
        amount: 20,
        type: "percentage",
      },
      // bahia+
      C02BF: {
        amount: 20,
        type: "percentage",
      },
      // havena
      C04BF: {
        amount: 19,
        type: "percentage",
      },
      C04CF: {
        amount: 19,
        type: "percentage",
      },
      C04PF: {
        amount: 19,
        type: "percentage",
      },
      C04VF: {
        amount: 19,
        type: "percentage",
      },
      C04DF: {
        amount: 19,
        type: "percentage",
      },
      // copacabana
      C05BM: {
        amount: 10,
        type: "percentage",
      },
      // wakiki
      C06BM: {
        amount: 10,
        type: "percentage",
      },
      C06CM: {
        amount: 10,
        type: "percentage",
      },
      // wash bag
      C08BM: {
        amount: 10,
        type: "percentage",
      },
      // travel bag
      C09BM: {
        amount: 10,
        type: "percentage",
      },
    },
  },
  {
    type: BundleDiscountType.bundle5,
    minimumItems: 5,
    range: ["5+"],
    discountsPerItem: {
      // ipanema
      C01BM: {
        amount: 19,
        type: "percentage",
      },
      C01CM: {
        amount: 19,
        type: "percentage",
      },
      C01PM: {
        amount: 19,
        type: "percentage",
      },
      C01VM: {
        amount: 19,
        type: "percentage",
      },
      C01DM: {
        amount: 19,
        type: "percentage",
      },
      // ipanema+
      C01BF: {
        amount: 20,
        type: "percentage",
      },
      C01CF: {
        amount: 20,
        type: "percentage",
      },
      C01PF: {
        amount: 20,
        type: "percentage",
      },
      C01VF: {
        amount: 20,
        type: "percentage",
      },
      C01DF: {
        amount: 20,
        type: "percentage",
      },
      // alexandria
      C03BM: {
        amount: 30,
        type: "percentage",
      },
      // alexandria+
      C03BF: {
        amount: 30,
        type: "percentage",
      },
      // bahia
      C02BM: {
        amount: 29,
        type: "percentage",
      },
      // bahia+
      C02BF: {
        amount: 30,
        type: "percentage",
      },
      // havena
      C04BF: {
        amount: 30,
        type: "percentage",
      },
      C04CF: {
        amount: 30,
        type: "percentage",
      },
      C04PF: {
        amount: 30,
        type: "percentage",
      },
      C04VF: {
        amount: 30,
        type: "percentage",
      },
      C04DF: {
        amount: 30,
        type: "percentage",
      },
      // copacabana
      C05BM: {
        amount: 20,
        type: "percentage",
      },
      // wakiki
      C06BM: {
        amount: 20,
        type: "percentage",
      },
      C06CM: {
        amount: 20,
        type: "percentage",
      },
      // wash bag
      C08BM: {
        amount: 20,
        type: "percentage",
      },
      // travel bag
      C09BM: {
        amount: 20,
        type: "percentage",
      },
    },
  },
];
