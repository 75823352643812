import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useTranslations } from "next-intl";
import { Fragment, memo, useContext } from "react";
import useRtl from "src/hooks/useRtl";
import { CartContext, CartContextProps } from "src/context/cart";
import Link from "next/link";
import ThemeLink from "src/components/link/ThemeLink";
import ArrowIcon from "src/components/icon/ArrowIcon";
import ThemeButton from "src/components/button/ThemeButton";
import useEnhancedAsync from "src/hooks/useEnhancedAsync";
import useCountryCode from "src/hooks/useCountryCode";
import createCheckout from "src/apis/storefront-latest-version/cart/createCheckout";
import { useRouter } from "next/router";
import BlackSpinIcon from "src/components/icon/BlackSpinIcon";
import useCountryData from "src/hooks/useCountryData";
import SummaryDetails from "src/components/summaryDetails/SummaryDetails";
import CartSideBarItem from "./components/cartSideBarItem";
import { CountryCode } from "src/types/localization/CountryCode";
import { BundleDiscountContext } from "src/context/BundleDiscountContext";

function CartSidebar() {
  const router = useRouter();
  const countryCode = useCountryCode();
  const countryData = useCountryData();
  const bundleCart = useContext(BundleDiscountContext);

  const t = useTranslations("CartSidebar");
  const rtl = useRtl();
  const { cart, sidebarExpanded, setSidebarExpanded } = useContext(
    CartContext
  ) as CartContextProps;

  const shopifyCheckout = async () => {
    if (!cart) return;

    const checkoutUrl = await createCheckout(
      countryCode as CountryCode,
      cart.id
    );
    router.push(checkoutUrl);
  };

  const [enhancedShopifyCheckout, checkoutLoading, checkoutError] =
    useEnhancedAsync(shopifyCheckout, {
      customError: t("something_went_wrong"),
    });

  const items = cart?.lines || [];

  let cartSidebarContent = <p>{t("your_cart_empty")}</p>;

  if (cart && cart.lines.length > 0) {
    cartSidebarContent = (
      <>
        <div className="space-y-6 border-t border-gray-200 py-6">
          {/* Items */}
          <ul className="space-y-4" id="cart-items">
            {items.map((item) => (
              <CartSideBarItem key={item.id} item={item} removeFromCart />
            ))}
          </ul>
        </div>

        <div className="space-y-6 border-t border-gray-200 py-6">
          {/* <Cost cost={cart.cost} cart={cart} /> */}
          {countryData && (
            <SummaryDetails
              cart={cart}
              countryData={countryData}
              bundleCart={bundleCart}
            />
          )}
          {countryData && countryData.checkoutMethod === "standard" ? (
            <ThemeButton
              disabled={checkoutLoading}
              onClick={enhancedShopifyCheckout}
              className="w-full"
            >
              {checkoutLoading && <BlackSpinIcon className="-ms-1 me-3" />}
              {checkoutLoading ? t("checkout_loading") : t("checkout")}
            </ThemeButton>
          ) : (
            <Link href="/checkout/user-information" legacyBehavior>
              {/* #checkout id is added for e2e testing */}
              <ThemeLink
                id="checkout"
                onClick={() => setSidebarExpanded(false)}
              >
                {t("checkout")}
                <ArrowIcon rtl={rtl} className="ms-3 w-4" />
              </ThemeLink>
            </Link>
          )}
          {checkoutError && <p className="error-message">{checkoutError}</p>}
        </div>
      </>
    );
  }

  return (
    <Transition.Root show={sidebarExpanded} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        onClose={(open) => setSidebarExpanded(open)}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 flex justify-end">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom={rtl ? "-translate-x-full" : "translate-x-full"}
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo={rtl ? "-translate-x-full" : "translate-x-full"}
          >
            {/* .cart-sidebar is added for e2e testing */}
            <Dialog.Panel className="cart-sidebar relative flex max-w-sm flex-col overflow-y-auto bg-white px-4 pb-12 shadow-xl">
              {/* Header */}
              <div className="flex justify-between pb-2 pt-5">
                <h2 className="text-xl font-medium">{t("my_cart")}</h2>
                <button
                  id="cartSidebar__close"
                  type="button"
                  className="-m-2 inline-flex items-center justify-center rounded-sm p-2 text-gray-400"
                  onClick={() => setSidebarExpanded(false)}
                >
                  <span className="sr-only">{t("close_menu")}</span>
                  <XMarkIcon
                    className="h-6 w-6 stroke-gray-900"
                    aria-hidden="true"
                  />
                </button>
              </div>
              {cartSidebarContent}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default memo(CartSidebar);
