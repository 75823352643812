import { BundleCart, BundleLine } from "src/types/bundle-discounts/BundleCart";

export default function getBundleItemById(
  bundleCart: BundleCart,
  itemId: string
): BundleLine | null {
  const bundleItem = bundleCart.lines.find((line) => line.id === itemId);

  if (bundleItem) {
    return bundleItem;
  } else {
    return null;
  }
}
