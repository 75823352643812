import gql from "src/helpers/gql";
import withoutEdgesAndNodes from "src/helpers/storefront-helpers/withoutEdgesAndNodes";
import { Collection, StorefrontCollection } from "src/types/storefront";
import storefront from "../storefront";
import { CountryCode } from "src/types/localization/CountryCode";

const query = gql`
  query SingleCollection($collectionId: ID!, $numberOfVariants: Int!) {
    collection(id: $collectionId) {
      id
      title
      handle
      products(first: 8) {
        edges {
          node {
            id
            handle
            title
            tags
            options {
              name
              values
            }
            images(first: 50) {
              edges {
                node {
                  url
                  altText
                }
              }
            }
            variants(first: $numberOfVariants) {
              edges {
                node {
                  id
                  sku
                  image {
                    url
                  }
                  price {
                    amount
                    currencyCode
                  }
                  compareAtPrice {
                    amount
                    currencyCode
                  }
                  selectedOptions {
                    name
                    value
                  }
                  quantityAvailable
                  product {
                    title
                    handle
                  }
                }
              }
            }
            collections(first: 7) {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface CollectionResponse {
  data: {
    collection: StorefrontCollection | null;
  };
}

export default async function getCollectionById(
  countryCode: CountryCode,
  collectionId: number,
  withAllVariants = false
): Promise<Collection> {
  const collectionsResponse: CollectionResponse = await storefront(
    countryCode,
    query,
    {
      collectionId: `gid://shopify/Collection/${collectionId}`,
      numberOfVariants: withAllVariants ? 100 : 1,
    }
  );

  if (!collectionsResponse?.data.collection) {
    throw new Error(
      `Couldn't find collection with id: ${collectionId} in ${countryCode} store`
    );
  }

  const collections = withoutEdgesAndNodes(collectionsResponse.data.collection);

  return collections;
}
