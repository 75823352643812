import { CartDiscountBundle } from "src/types/bundle-discounts/BundleCart";
import { BundleDiscountSystem } from "src/types/bundle-discounts/BundleDiscountSystem";
import { SkuWithoutSize } from "src/types/shopify/SkuWithoutSize";

/**
 * @param sku e.g. C01BMXS
 * @param bundleDiscount (bundle3 or bundle5) discount system that includes a discount amount for each SKU
 * @returns discount for the passed SKU based on the bundle system
 */
export default function getBundleDiscountBySku(
  sku: string,
  bundleDiscount: BundleDiscountSystem
): CartDiscountBundle {
  const skuWithoutSize = sku.slice(0, 5);

  const discount: CartDiscountBundle = {
    ...bundleDiscount.discountsPerItem[skuWithoutSize as SkuWithoutSize],
    bundleType: bundleDiscount.type,
  };

  return discount;
}
