import { useRouter } from "next/router";

export default function useLanguage(defaultLanguage = "en") {
  const { locale } = useRouter();
  const languageValue = locale?.substring(0, 2) || defaultLanguage;

  switch (languageValue) {
    case "ar":
      return {
        label: "العربية",
        value: "ar",
      };
    case "en":
      return {
        label: "English",
        value: "en",
      };
    case "fr":
      return {
        label: "Francais",
        value: "fr",
      };
    case "es":
      return {
        label: "Spain",
        value: "es",
      };
    default:
      return {
        label: "العربية",
        value: "ar",
      };
  }
}
