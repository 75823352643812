import { useEffect } from "react";
import { useStoreActions } from "./storeHooks";
import useCountryCode from "./useCountryCode";
import getLocalStorage from "src/helpers/local-storage/getLocalStorage";
import { useStoreRehydrated } from "easy-peasy";
import { CountryCode } from "src/types/localization/CountryCode";

export default function useStoreInitialData() {
  const countryCode = useCountryCode();
  const isRehydrated = useStoreRehydrated();

  // products
  const fetchProductsThunk = useStoreActions((state) => state.fetchProductsThunk);

  // cart
  const fetchCartThunk = useStoreActions((state) => state.fetchCartThunk);
  const resetCart = useStoreActions((state) => state.resetCart);

  // checkout
  const fetchCheckoutThunk = useStoreActions((state) => state.fetchCheckoutThunk);
  const resetCheckout = useStoreActions((actions) => actions.resetCheckout);

  async function fetchAndSetInitialData(countryCode: CountryCode) {
    // products
    fetchProductsThunk(countryCode);

    // cart
    const cartId = getLocalStorage(`koala_cart_id_${countryCode}`);
    if (cartId) {
      fetchCartThunk(cartId);
    } else {
      resetCart(countryCode);
    }

    // checkout
    const checkoutId = getLocalStorage(`checkout_id_${countryCode}`);
    if (checkoutId) {
      const checkoutResult = await fetchCheckoutThunk(checkoutId);
      if (checkoutResult.isOk() && checkoutResult.value.orderId) {
        resetCheckout(countryCode);
      }
    } else {
      resetCheckout(countryCode);
    }
  }

  useEffect(() => {
    if (!countryCode) return;

    // products
    fetchAndSetInitialData(countryCode);
  }, [
    countryCode,
    /**
     * To make sure the logic in fetchAndSetInitialData runs after easy peasy finish rehydration
     *
     * Why?
     * An issue occurred where:
     * - A user in SA deployment adds item to cart (cart_id_SA is created in storage)
     * - then, re-visit the website from EG deployment
     * - "fetchAndSetInitialData" doesn't find a (cart_id_EG), so it reset the cart
     * - AFTER THAT, REHYDRATION HAPPENS
     * - so the user in EG sees a cart from SA
     */
    isRehydrated,
  ]);
}
