import { useTranslations } from "next-intl";
import preciseDecimal from "src/helpers/preciseDecimal";
import Image from "next/image";
import TabbyModal from "./TabbyModal";

type TabbyPromoProps = {
  price: number;
  currency: string;
};

function TabbyPromo({ price, currency }: TabbyPromoProps) {
  const t = useTranslations("Tabby");
  const splitPrice = preciseDecimal(price / 4);

  return (
    <div className="rounded-md border border-gray-300 p-2">
      <p className="text-sm font-bold">
        {t.rich("tabby_promo", {
          splitPrice,
          currency,
          logo: () => (
            <Image
              src={"/images/logos/tabby.svg"}
              alt="Tabby"
              width={0}
              height={0}
              className="inline-block w-16"
            />
          ),
        })}
      </p>
      <TabbyModal splitPrice={splitPrice} currency={currency} />
    </div>
  );
}

export default TabbyPromo;
