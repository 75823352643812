import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useTranslations } from "next-intl";
import { Fragment } from "react";
import useRtl from "src/hooks/useRtl";
import Link from "next/link";
import ThemeLink from "src/components/link/ThemeLink";
import ArrowIcon from "src/components/icon/ArrowIcon";
import useCountryData from "src/hooks/useCountryData";
import { useStoreActions, useStoreState } from "src/hooks/storeHooks";
import KoalaCartSideBarItem from "./components/KoalaCartSidebarItem";
import KoalaSummaryDetails from "src/components/summaryDetails/KoalaSummaryDetails";

function KoalaCartSidebar() {
  const countryData = useCountryData();
  const cart = useStoreState((state) => state.cart);

  const isCartSidebarExpanded = useStoreState((state) => state.isCartSidebarExpanded);
  const oosItems = useStoreState((state) => state.oosItems);
  const setIsCartSidebarExpanded = useStoreActions((actions) => actions.setIsCartSidebarExpanded);

  const t = useTranslations("CartSidebar");
  const rtl = useRtl();
  const lineItems = cart?.lineItems || [];

  let cartSidebarContent = <p>{t("your_cart_empty")}</p>;

  if (cart && lineItems.length > 0) {
    cartSidebarContent = (
      <>
        <div className="space-y-6 border-t border-gray-200 py-6">
          {/* Items */}
          <ul className="space-y-4" id="cart-items">
            {lineItems.map((lineItem) => (
              <KoalaCartSideBarItem key={lineItem._id} cart={cart} lineItem={lineItem} removeFromCart />
            ))}
          </ul>
        </div>

        <div className="space-y-6 border-t border-gray-200 py-6">
          {countryData && <KoalaSummaryDetails cart={cart} countryData={countryData} />}

          <Link href="/checkout/user-information" legacyBehavior>
            {/* #checkout id is added for e2e testing */}
            <ThemeLink id="checkout" onClick={() => setIsCartSidebarExpanded(false)} disabled={oosItems.length > 0}>
              {t("checkout")}
              <ArrowIcon rtl={rtl} className="ms-3 w-4" />
            </ThemeLink>
          </Link>
        </div>
      </>
    );
  }

  return (
    <Transition.Root show={isCartSidebarExpanded} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={(open) => setIsCartSidebarExpanded(open)}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 flex justify-end">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom={rtl ? "-translate-x-full" : "translate-x-full"}
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo={rtl ? "-translate-x-full" : "translate-x-full"}
          >
            {/* .cart-sidebar is added for e2e testing */}
            <Dialog.Panel className="cart-sidebar relative flex max-w-sm flex-col overflow-y-auto bg-white px-4 pb-12 shadow-xl">
              {/* Header */}
              <div className="flex justify-between pb-2 pt-5">
                <h2 className="text-xl font-medium">{t("my_cart")}</h2>
                <button
                  id="cartSidebar__close"
                  type="button"
                  className="-m-2 inline-flex items-center justify-center rounded-sm p-2 text-gray-400"
                  onClick={() => setIsCartSidebarExpanded(false)}
                >
                  <span className="sr-only">{t("close_menu")}</span>
                  <XMarkIcon className="h-6 w-6 stroke-gray-900" aria-hidden="true" />
                </button>
              </div>
              {cartSidebarContent}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default KoalaCartSidebar;
