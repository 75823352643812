import { captureException } from "@sentry/nextjs";
import { IntlError, IntlErrorCode } from "next-intl";
import highlightError from "../highlight/highlightError";
import { NextRouter } from "next/router";

export default function onError(error: IntlError, router: NextRouter) {
  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    // Missing translations are expected and should only log an error
    highlightError(error, error.message, {
      name: error.name,
      code: error.code,
      originalMessage: error.originalMessage || "",
      pathname: router.pathname,
    });
    captureException(error);
    console.error(error);
  }
}
