import { Result, err, ok } from "neverthrow";
import ApiClient from "src/helpers/api-client/apiClient";
import { APIError } from "src/types/api-client/Error";
import { DBCredit } from "src/types/database/DBCredit";
import { DBUser } from "src/types/database/DBUser";
import { OrderMetadata } from "src/types/database/OrderMetadata";
import { DiscountDetails } from "src/types/discount/DiscountDetails";
import { CountryCode } from "src/types/localization/CountryCode";
import { Locale } from "src/types/localization/Locale";
import {
  CreatePaymentIntentResponse,
  CreatePaymentIntentResponseSuccess,
} from "src/types/server/CreatePaymentIntentResponse";
import {
  RetrievePaymentIntentResponse,
  RetrievePaymentIntentSuccessResponse,
} from "src/types/responses/RetrievePaymentIntentResponse";
import {
  CreatePaymentIntentRequest,
  KoalaCreatePaymentIntentRequest,
} from "src/types/server/CreatePaymentIntentRequest";
import { ApplyReferralDiscountRequest } from "src/types/shemsi-api/ApplyReferralDiscountRequest";
import { ShemsiApiResponse } from "src/types/shemsi-api/ShemsiApiResponse";
import { DraftOrder, Order } from "src/types/shopify-admin";
import getTrackingMetadata from "src/helpers/event-tracking/getTrackingMetadata";
import { BillingAddress } from "src/context/billingAddress";
import Stripe from "stripe";
import {
  UpdateKoalaPaymentIntentRequest,
  UpdatePaymentIntentRequest,
} from "src/types/server/UpdatePaymentIntentRequest";
import { KoalaOrder } from "src/types/koala/KoalaOrder";
import { KoalaCart } from "src/types/koala/KoalaCart";

export class ShemsiApiClient {
  private apiBasUrl = "/api";
  private shemsiApiClient = new ApiClient({});

  async fetchNewlyCreatedOrder(
    draftOrderId: number,
    countryCode: CountryCode
  ): Promise<Result<ShemsiApiResponse & { order: Order }, APIError>> {
    type Request = {
      countryCode: CountryCode;
      draftOrderId: number;
    };
    const result = await this.shemsiApiClient.post<Request, ShemsiApiResponse & { order: Order }>(
      `/api/shopify-admin/fetch-newly-created-order/`,
      {
        countryCode,
        draftOrderId,
      }
    );

    if (result.isErr()) {
      return err(result.error);
    }

    if (!result.value.success) {
      return err({
        errorMessage: result.value.message,
        sessionId: result.value.sessionId,
        errorStatus: result.value.externalErrorStatus,
      });
    }

    return ok(result.value);
  }

  async koalaFetchNewlyCreatedOrder(checkoutId: string) {
    const result = await this.shemsiApiClient.get<ShemsiApiResponse & { order: KoalaOrder }>(
      `/api/payments/fetch-order-id/?checkoutId=${checkoutId}`
    );

    if (result.isErr()) {
      return err(result.error);
    }

    if (!result.value.success) {
      return err({
        errorMessage: result.value.message,
        sessionId: result.value.sessionId,
        errorStatus: result.value.externalErrorStatus,
      });
    }

    return ok(result.value);
  }

  async processCodOrder(
    countryCode: CountryCode,
    draftOrderId: number
  ): Promise<Result<ShemsiApiResponse & { order: Order }, APIError>> {
    const result = await this.shemsiApiClient.post<any, ShemsiApiResponse & { order: Order }>(
      `/api/payments/cod/fetch-order-id?countryCode=${countryCode}`,
      {
        draftOrderId,
        ...getTrackingMetadata(),
      }
    );

    if (result.isErr()) {
      return err(result.error);
    }

    if (!result.value.success) {
      return err({
        errorMessage: result.value.message,
        sessionId: result.value.sessionId,
        errorStatus: result.value.externalErrorStatus,
      });
    }

    return ok(result.value);
  }

  async completeCheckout(countryCode: CountryCode, checkoutId: string) {
    const result = await this.shemsiApiClient.post<{}, ShemsiApiResponse & { order: KoalaOrder }>(
      `/api/payments/cod/complete-checkout?countryCode=${countryCode}&checkoutId=${checkoutId}`,
      { ...getTrackingMetadata() }
    );

    if (result.isErr()) {
      return err(result.error);
    }

    if (!result.value.success) {
      return err({
        errorMessage: result.value.message,
        sessionId: result.value.sessionId,
        errorStatus: result.value.externalErrorStatus,
      });
    }

    return ok(result.value.order);
  }

  async createStripePaymentIntent(
    countryCode: CountryCode,
    draftOrderId: number | string,
    trackingMetadata: object,
    stripeCustomerId?: string
  ): Promise<Result<CreatePaymentIntentResponseSuccess, APIError>> {
    const result = await this.shemsiApiClient.post<CreatePaymentIntentRequest, CreatePaymentIntentResponse>(
      `/api/payments/stripe/create-payment-intent/?countryCode=${countryCode}`,
      {
        draftOrderId: String(draftOrderId),
        trackingMetadata,
        stripeCustomerId,
      }
    );

    if (result.isErr()) {
      return err(result.error);
    }

    if (
      !result.value.success ||
      !result.value.paymentIntentId ||
      !result.value.clientSecret ||
      !result.value.stripeCustomerId
    ) {
      return err({
        errorMessage: "Failed to create payment intent",
      });
    }

    return ok({
      paymentIntentId: result.value.paymentIntentId,
      clientSecret: result.value.clientSecret,
      stripeCustomerId: result.value.stripeCustomerId,
    });
  }

  async createKoalaStripePaymentIntent(
    countryCode: CountryCode,
    checkoutId: string,
    trackingMetadata: object,
    stripeCustomerId?: string
  ): Promise<Result<CreatePaymentIntentResponseSuccess, APIError>> {
    const result = await this.shemsiApiClient.post<KoalaCreatePaymentIntentRequest, CreatePaymentIntentResponse>(
      `/api/payments/stripe/koala-create-payment-intent/?countryCode=${countryCode}`,
      {
        checkoutId,
        trackingMetadata,
        stripeCustomerId,
      }
    );

    if (result.isErr()) {
      return err(result.error);
    }

    if (
      !result.value.success ||
      !result.value.paymentIntentId ||
      !result.value.clientSecret ||
      !result.value.stripeCustomerId
    ) {
      return err({
        errorMessage: "Failed to create payment intent",
      });
    }

    return ok({
      paymentIntentId: result.value.paymentIntentId,
      clientSecret: result.value.clientSecret,
      stripeCustomerId: result.value.stripeCustomerId,
    });
  }

  async updateStripePaymentIntent(
    countryCode: CountryCode,
    paymentIntentId: string,
    draftOrderId: number,
    billingAddress?: BillingAddress
  ): Promise<Result<Stripe.Response<Stripe.PaymentIntent>, APIError>> {
    const result = await this.shemsiApiClient.put<
      UpdatePaymentIntentRequest,
      ShemsiApiResponse & {
        paymentIntent: Stripe.Response<Stripe.PaymentIntent>;
      }
    >(`/api/payments/stripe/update-payment-intent/?countryCode=${countryCode}`, {
      draftOrderId,
      paymentIntentId,
      billingAddress,
    });

    if (result.isErr()) {
      return err(result.error);
    }

    if (!result.value.success || !result.value.paymentIntent) {
      return err({
        errorMessage: "Failed to update payment intent",
      });
    }

    return ok(result.value.paymentIntent);
  }

  async updateKoalaStripePaymentIntent(
    countryCode: CountryCode,
    paymentIntentId: string,
    cartId: string,
    billingAddress?: BillingAddress
  ): Promise<Result<Stripe.Response<Stripe.PaymentIntent>, APIError>> {
    const result = await this.shemsiApiClient.put<
      UpdateKoalaPaymentIntentRequest,
      ShemsiApiResponse & {
        paymentIntent: Stripe.Response<Stripe.PaymentIntent>;
      }
    >(`/api/payments/stripe/koala-update-payment-intent/?countryCode=${countryCode}`, {
      cartId,
      paymentIntentId,
      billingAddress,
    });

    if (result.isErr()) {
      return err(result.error);
    }

    if (!result.value.success || !result.value.paymentIntent) {
      return err({
        errorMessage: "Failed to update payment intent",
      });
    }

    return ok(result.value.paymentIntent);
  }

  async retreiveStripePaymentIntent(
    paymentId: string
  ): Promise<Result<RetrievePaymentIntentSuccessResponse, APIError>> {
    const result = await this.shemsiApiClient.get<RetrievePaymentIntentResponse>(
      `/api/payments/stripe/retreive-payment-intent/?paymentIntentId=${paymentId}`
    );

    if (result.isErr()) {
      return err(result.error);
    }

    if (!result.value.success || !result.value.paymentIntentId || !result.value.clientSecret) {
      return err({
        errorMessage: "Failed to fetch payment intent",
      });
    }

    return ok({
      paymentIntentId: result.value.paymentIntentId,
      clientSecret: result.value.clientSecret,
      completed: Boolean(result.value.completed),
    });
  }

  async applyReferralDiscount(
    countryCode: CountryCode,
    referralCode: string,
    draftOrderId: number
  ): Promise<Result<ShemsiApiResponse & { updatedDraftOrder: DraftOrder }, APIError>> {
    const result = await this.shemsiApiClient.put<
      ApplyReferralDiscountRequest,
      ShemsiApiResponse & { updatedDraftOrder: DraftOrder }
    >(`/api/referral/apply-referral-reward`, {
      countryCode,
      referralCode,
      draftOrderId,
    });

    if (result.isErr()) {
      return err(result.error);
    }

    if (!result.value.success) {
      return err({
        errorMessage: result.value.message,
      });
    }

    return ok(result.value);
  }

  async koalaApplyReferralDiscount(countryCode: CountryCode, referralCode: string, cartId: string) {
    const result = await this.shemsiApiClient.put<
      { countryCode: CountryCode; referralCode: string; cartId: string },
      ShemsiApiResponse & { updatedCart: KoalaCart }
    >(`/api/referral/koala-apply-referral-reward`, {
      countryCode,
      referralCode,
      cartId,
    });

    if (result.isErr()) {
      return err(result.error);
    }

    if (!result.value.success) {
      return err({
        errorMessage: result.value.message,
      });
    }

    return ok(result.value.updatedCart);
  }

  async spendCredits(cart: KoalaCart, creditsAmount: number, creditFactor: number) {
    const result = await this.shemsiApiClient.put<
      { cart: KoalaCart; creditsAmount: number; creditFactor: number },
      ShemsiApiResponse & { updatedCart: KoalaCart }
    >(`/api/referral/koala-spend-credits`, {
      cart,
      creditsAmount,
      creditFactor,
    });

    if (result.isErr()) {
      return err(result.error);
    }

    if (!result.value.success) {
      return err({
        errorMessage: result.value.message,
      });
    }

    return ok(result.value.updatedCart);
  }

  async unspendCredits(email: string, spentCredits: number, creditFactor: number, cart: KoalaCart) {
    const result = await this.shemsiApiClient.put<
      { email: string; spentCredits: number; creditFactor: number; cart: KoalaCart },
      ShemsiApiResponse & { updatedCart: KoalaCart }
    >(`/api/referral/koala-unspend-credits`, {
      email,
      spentCredits,
      creditFactor,
      cart,
    });

    if (result.isErr()) {
      return err(result.error);
    }

    if (!result.value.success) {
      return err({
        errorMessage: result.value.message,
      });
    }

    return ok(result.value.updatedCart);
  }

  async getUserByToken(): Promise<Result<DBUser, APIError>> {
    const result = await this.shemsiApiClient.get<ShemsiApiResponse & { user: DBUser }>(
      `/api/server/get-user-by-token/`
    );

    if (result.isErr()) {
      return err(result.error);
    }

    if (!result.value.success) {
      return err({
        errorMessage: result.value.message,
      });
    }

    return ok(result.value.user);
  }

  async getUserCredits(): Promise<Result<DBCredit | null, APIError>> {
    const result = await this.shemsiApiClient.get<ShemsiApiResponse & { credits: DBCredit | null }>(
      `/api/server/get-user-credits/`
    );

    if (result.isErr()) {
      return err(result.error);
    }

    if (!result.value.success) {
      return err({
        errorMessage: result.value.message,
      });
    }

    return ok(result.value.credits);
  }

  async getUserOrders(): Promise<Result<OrderMetadata[], APIError>> {
    const result = await this.shemsiApiClient.get<ShemsiApiResponse & { orders: OrderMetadata[] }>(
      `/api/server/get-user-orders/`
    );

    if (result.isErr()) {
      return err(result.error);
    }

    if (!result.value.success) {
      return err({
        errorMessage: result.value.message,
      });
    }

    return ok(result.value.orders);
  }

  async createFawryCheckoutLink(
    draftOrderId: number | string,
    locale: Locale,
    returnUrl: string
  ): Promise<Result<string, APIError>> {
    type CreateWalletPaymentRequest = {
      draftOrderId: number | string;
      locale: Locale;
      returnUrl: string;
    };

    const result = await this.shemsiApiClient.post<
      CreateWalletPaymentRequest,
      ShemsiApiResponse & { checkoutLink: string }
    >(`${this.apiBasUrl}/payments/fawry/create-checkout-link`, {
      draftOrderId,
      locale,
      returnUrl,
    });

    if (result.isErr()) {
      return err(result.error);
    }

    if (!result.value.success) {
      return err({
        errorMessage: result.value.message,
        errorStatus: result.value.externalErrorStatus,
      });
    }

    return ok(result.value.checkoutLink);
  }
}
