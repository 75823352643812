import ThemeLink from "src/components/link/ThemeLink";
import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import useRtl from "src/hooks/useRtl";

type StickyFooterButtonProps = {
  showStickyFooterPopUpButton?: boolean;
  stickyFooterButtonTitle?: string;
  StickyFooterPopUpComponent?: JSX.Element;
};
const PopUpButton = (props: StickyFooterButtonProps) => {
  const {
    showStickyFooterPopUpButton,
    stickyFooterButtonTitle,
    StickyFooterPopUpComponent,
  } = props;

  const rtl = useRtl();
  const renderPopUpButton = () => {
    if (showStickyFooterPopUpButton) {
      return (
        <div className="w-full md:hidden ">
          <Popover className="w-full ">
            {({ open }) => (
              /* Use the `open` state to conditionally change the direction of the chevron icon. */
              <>
                <Popover.Overlay className="fixed bottom-20 top-0 left-0 right-0 -z-10 bg-black opacity-30" />
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute left-0 right-0 bottom-0 border-t bg-white  px-8 pb-8 shadow-gray-200">
                    {open ? (
                      <Popover.Button
                        className={`absolute top-1 h-10 w-10 ${
                          rtl ? "left-1" : "right-1"
                        } z-30`}
                      >
                        <XMarkIcon />
                      </Popover.Button>
                    ) : null}
                    {StickyFooterPopUpComponent}
                  </Popover.Panel>
                </Transition>
                <Popover.Button className="w-full">
                  {open ? null : (
                    <ThemeLink className="w-full">
                      {stickyFooterButtonTitle || "Add to bag"}
                    </ThemeLink>
                  )}
                </Popover.Button>
              </>
            )}
          </Popover>
        </div>
      );
    }
  };

  return <section className="w-5/6 flex-1">{renderPopUpButton()}</section>;
};

export default PopUpButton;
