import countries from "src/config/countries/countries";
import { Locale } from "src/types/localization/Locale";

export default function getLocales(): Locale[] {
  return Object.values(countries)
    .map((country) =>
      country.supportedLanguages.map(
        (language) => `${language.value}-${country.code}` as Locale
      )
    )
    .flat();
}
