import Image from "next/image";
import Link from "next/link";
import Logo from "src/components/logo/Logo";
import classNames from "src/helpers/classNames";
import useCountryData from "src/hooks/useCountryData";

interface CheckoutHeaderProps {}

export default function CheckoutHeader({}: CheckoutHeaderProps) {
  const countryData = useCountryData();

  return (
    <header>
      {/* Mobile menu */}

      <nav className="relative z-10" aria-label="Top">
        {/* navigation */}
        <div className="bg-white">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="border-b border-gray-200">
              <div className="flex h-16 justify-between lg:h-24">
                {/* Logo (lg+) */}
                <div className="mx-auto my-auto">
                  <Link href={"/"} id="checkout_layout__logo">
                    <Logo
                      className={classNames(
                        "w-auto",
                        countryData?.christmasTheme
                          ? "h-9 lg:h-12"
                          : "h-6 lg:h-8 "
                      )}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
