import { IntlError, IntlErrorCode } from "next-intl";

interface ErrorInfo {
  error: IntlError;
  key: string;
  namespace?: string | undefined;
}

export default function getMessageFallback({
  namespace,
  key,
  error,
}: ErrorInfo) {
  const path = [namespace, key].filter((part) => part != null).join(".");

  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    return `${path} is not yet translated`;
  } else {
    return `Dear developer, please fix this message: ${path}`;
  }
}
