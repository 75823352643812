import gql from "src/helpers/gql";
import withoutEdgesAndNodes from "src/helpers/storefront-helpers/withoutEdgesAndNodes";
import { Cart, StorefrontCart } from "src/types/storefront/Cart";
import storefront from "../storefront";
import { StorefrontError } from "src/types/storefront";
import generateCartErrorMessage from "src/helpers/cart/generateCartErrorMessage";

const mutation = gql`
  mutation CartUpdate($cartId: ID!, $newLines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $newLines) {
      cart {
        id
        createdAt
        lines(first: 100) {
          edges {
            node {
              id
              attributes {
                key
                value
              }
              quantity
              discountAllocations {
                discountedAmount {
                  amount
                  currencyCode
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  sku
                  image {
                    url
                    altText
                  }
                  product {
                    handle
                    title
                    images(first: 50) {
                      edges {
                        node {
                          altText
                          url
                        }
                      }
                    }
                    variants(first: 1) {
                      edges {
                        node {
                          id
                          sku
                          price {
                            amount
                            currencyCode
                          }
                          compareAtPrice {
                            amount
                            currencyCode
                          }
                          selectedOptions {
                            name
                            value
                          }
                          quantityAvailable
                        }
                      }
                    }
                  }
                  price {
                    amount
                    currencyCode
                  }
                  compareAtPrice {
                    amount
                    currencyCode
                  }
                  selectedOptions {
                    name
                    value
                  }
                  quantityAvailable
                }
              }
            }
          }
        }
        attributes {
          key
          value
        }
        cost {
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalAmount {
            amount
            currencyCode
          }
        }
        discountCodes {
          applicable
          code
        }
        discountAllocations {
          discountedAmount {
            amount
            currencyCode
          }
        }
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`;

interface UpdateResponse {
  data: {
    cartLinesUpdate: {
      cart: StorefrontCart;
      userErrors: StorefrontError[];
    };
  };
}

export interface CartLineUpdateInput {
  id: string;
  quantity: number;
}

export default async function updateCartItemsQuantity(
  countryCode: string,
  cartId: string,
  newLines: CartLineUpdateInput[]
): Promise<Cart | null> {
  console.log("updateCartItemsQuantity");
  const updateResponse: UpdateResponse = await storefront(
    countryCode,
    mutation,
    { cartId, newLines }
  );

  if (updateResponse.data.cartLinesUpdate.userErrors.length > 0) {
    throw new Error(
      generateCartErrorMessage(
        cartId,
        updateResponse.data.cartLinesUpdate.userErrors
      )
    );
  }

  const cart = withoutEdgesAndNodes(updateResponse.data.cartLinesUpdate.cart);

  return cart;
}
