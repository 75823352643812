import { curry, flow, includes, partial, property } from "lodash";

export function isIn(array: (string | number)[]) {
  return partial(includes, array);
}

export function propertyIn(path: string | number, array: (string | number)[]) {
  return flow(property(path), isIn(array));
}

export function someArrayElementIn(target: (string | number)[]) {
  return function predicate(arrayToMatch: (string | number)[]) {
    return arrayToMatch.some(isIn(target));
  };
}
