import variantMatchFilters from "./variantMatchFilters";
import getOptionValueByName from "./getOptionValueByName";
import { VariantFilter, ValueWithStock } from "./getOptionValuesWithStock";
import { Product } from "src/types/storefront";

export default function addStockToValues(
	product: Product,
	values: string[],
	optionName: string,
	filters: VariantFilter[] = [] // [{name: "Color", value: "blue"}, {name: "Size", value: "xl"}]
): ValueWithStock[] {
	// [{value: "black", stock: 0}, {value: "blue", stock: 0}, ...]
	const valuesWithStock = values.map((value) => ({ value, stock: 0 }));

	const availableVariants = product.variants.filter(
		(variant) => variant.quantityAvailable > 0
	);

	availableVariants.forEach((variant) => {
		if (variantMatchFilters(variant, filters)) {
			// Then this variant's value for the current option will be added to stock
			const valueToIncrease = getOptionValueByName(
				optionName,
				variant.selectedOptions
			);
			if (valueToIncrease) {
				const valueIndex = valuesWithStock.findIndex(
					({ value }) => value === valueToIncrease
				);
				if (valueIndex >= 0) valuesWithStock[valueIndex].stock++;
			}
		}
	});

	return valuesWithStock;
}
