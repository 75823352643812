import { useIsClient, useMediaQuery } from "usehooks-ts";
import Image from "next/image";
import { useTranslations } from "next-intl";
import consts from "src/config/consts";
import useRtl from "src/hooks/useRtl";

interface Props {}

function RamadanTopbar({}: Props) {
  const t = useTranslations("RamadanTopBar");

  const breakpointReached = useMediaQuery("(min-width: 620px)");
  const isClient = useIsClient();
  const offerDiscount = consts.FAKE_DISCOUNT_PERCENTAGE_VALUE;
  const isRtl = useRtl();
  if (breakpointReached && isClient)
    return (
      <div
        dir="ltr"
        className="flex h-[45px] justify-center bg-beige-500 px-4 text-beige-800"
      >
        <div className="flex items-center gap-4">
          <Image
            src="/images/themes/ramadan/ramadan-lights-left.png"
            alt="ramadan-lights"
            className="h-full w-32 sm:w-36 md:w-48 lg:w-auto"
            width={0}
            height={0}
            sizes="18rem"
          />
          <Image
            src="/images/themes/ramadan/ramadan-moon.png"
            alt="ramadan-moon"
            className="w-7 md:w-9"
            width={0}
            height={0}
            sizes="3rem"
          />
          <span dir={isRtl ? "rtl" : "ltr"} className="text-xs lg:text-base">
            {t.rich("ramadan_offers", {
              b: (chunks) => <b>{chunks}</b>,
              discount_value: offerDiscount,
            })}
          </span>
          <Image
            src="/images/themes/ramadan/ramadan-hearts.png"
            alt="ramadan-hearts"
            className="w-9 md:w-12"
            width={0}
            height={0}
            sizes="3rem"
          />
          <Image
            src="/images/themes/ramadan/ramadan-lights-right.png"
            alt="ramadan-lights"
            className="h-full w-32 sm:w-36 md:w-48 lg:w-auto"
            width={0}
            height={0}
            sizes="18rem"
          />
        </div>
      </div>
    );
  else if (!breakpointReached && isClient)
    return (
      <div
        dir="ltr"
        className="flex h-[42px] justify-between bg-beige-500 text-beige-800"
      >
        <Image
          src="/images/themes/ramadan/ramadan-lights-left-mob.png"
          alt="ramadan-lights"
          className="2xs:w-18 w-14 xs:w-auto"
          width={0}
          height={0}
          sizes="7rem"
        />
        <div className="flex items-center gap-2">
          <Image
            src="/images/themes/ramadan/ramadan-moon.png"
            alt="ramadan-moon"
            className="w-10"
            width={0}
            height={0}
            sizes="3rem"
          />
          <span dir={isRtl ? "rtl" : "ltr"} className="text-xxs lg:text-base">
            {t.rich("ramadan_offers", {
              b: (chunks) => <b>{chunks}</b>,
              discount_value: offerDiscount,
            })}
          </span>
          <Image
            src="/images/themes/ramadan/ramadan-hearts.png"
            alt="ramadan-hearts"
            className="w-10"
            width={0}
            height={0}
            sizes="3rem"
          />
        </div>
        <Image
          src="/images/themes/ramadan/ramadan-lights-right-mob.png"
          alt="ramadan-lights"
          className="2xs:w-18 w-14 xs:w-auto"
          width={0}
          height={0}
          sizes="7rem"
        />
      </div>
    );
  else return <></>;
}

export default RamadanTopbar;
