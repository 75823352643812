import { Result, err, ok } from "neverthrow";
import ApiClient from "src/helpers/api-client/apiClient";
import { APIError } from "src/types/api-client/Error";
import { DatabasePayment } from "src/types/database/DatabasePayment";
import { AuthResponse } from "src/types/server/AuthResponse";
import { GetDatabasePaymentResponse } from "src/types/responses/GetDatabasePaymentResponse";
import { SigninRequest } from "src/types/server/SigninRequest";
import { DBUser, RegisteringDBUser } from "src/types/database/DBUser";
import { SignoutResponse } from "src/types/server/SignoutResponse";
import { GetUserResponse } from "src/types/server/GetUserResponse";
import { CreditsResponse } from "src/types/server/CreditsResponse";
import { DBCredit } from "src/types/database/DBCredit";
import { Currency } from "src/types/localization/Currency";
import { CreateCreditsRequest } from "src/types/server/CreateCreditsRequest";
import { DraftOrder } from "src/types/shopify-admin";
import { OrderMetadata } from "src/types/database/OrderMetadata";
import { GetUsersResponse } from "src/types/server/GetUsersResponse";
import { GetOrdersResponse } from "src/types/server/GetOrdersResponse";
import { SubscribeToNewsletterRequest } from "src/types/server/SubscribeToNewsletterRequest";
import HttpStatusCode from "src/types/api-client/HttpStatusCode";

export class ServerApiClient {
  private apiBaseUrl: string = process.env.NEXT_PUBLIC_FULFILLMENT_CONNECTOR_URL;
  private serverApiClient = new ApiClient({ withCredentials: true });

  async getPaymentById(paymentId: string): Promise<Result<DatabasePayment, APIError>> {
    const result = await this.serverApiClient.get<GetDatabasePaymentResponse>(
      `${this.apiBaseUrl}/payments/${paymentId}`
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.payment);
  }

  async updatePayment(paymentId: string, fieldsToUpdate: object): Promise<Result<DatabasePayment, APIError>> {
    const result = await this.serverApiClient.put<object, { success: boolean; payment: DatabasePayment }>(
      `${this.apiBaseUrl}/payments/${paymentId}`,
      fieldsToUpdate
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.payment);
  }

  async createPayment(payment: DatabasePayment): Promise<Result<DatabasePayment, APIError>> {
    const result = await this.serverApiClient.post<DatabasePayment, { success: boolean; payment: DatabasePayment }>(
      `${this.apiBaseUrl}/payments`,
      payment
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.payment);
  }

  async handlePaymentNotification(paymentData: DatabasePayment): Promise<Result<DatabasePayment, APIError>> {
    const getPaymentResult = await this.getPaymentById(paymentData.gatewayMetadata.paymentId);

    // Case #1: payment doesn't exist, create
    if (getPaymentResult.isErr()) {
      if (getPaymentResult.error.errorStatus === HttpStatusCode.NOT_FOUND) {
        const createPaymentResult = await this.createPayment(paymentData);
        if (createPaymentResult.isErr()) {
          return err(createPaymentResult.error);
        }
        return ok(createPaymentResult.value);
      } else {
        return err(getPaymentResult.error);
      }
    }

    // Case #2: payment exists, update
    let statusesHistory = getPaymentResult.value.gatewayMetadata.history || [];
    const fieldsToUpdate = {
      status: paymentData.status,
      gatewayMetadata: {
        ...getPaymentResult.value.gatewayMetadata,
        history: [...statusesHistory, paymentData.gatewayMetadata.history?.[0]],
      },
    };
    const updatePaymentResult = await this.updatePayment(paymentData.gatewayMetadata.paymentId, fieldsToUpdate);
    if (updatePaymentResult.isErr()) {
      return err(updatePaymentResult.error);
    }
    return ok(updatePaymentResult.value);
  }

  async signin(credenials: SigninRequest): Promise<Result<AuthResponse, APIError>> {
    const result = await this.serverApiClient.post<SigninRequest, AuthResponse>(
      `${this.apiBaseUrl}/auth/signin`,
      credenials
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value);
  }

  async signup(user: RegisteringDBUser): Promise<Result<AuthResponse, APIError>> {
    const result = await this.serverApiClient.post<RegisteringDBUser, AuthResponse>(
      `${this.apiBaseUrl}/auth/signup`,
      user
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value);
  }

  async signout(): Promise<Result<SignoutResponse, APIError>> {
    const result = await this.serverApiClient.get<SignoutResponse>(`${this.apiBaseUrl}/auth/signout`);

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value);
  }

  async getUserByToken(token: string): Promise<Result<DBUser, APIError>> {
    const result = await this.serverApiClient.get<GetUserResponse>(`${this.apiBaseUrl}/auth/me`, {
      headers: { Cookie: `token=${token}` },
    });

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.data);
  }

  async forgotPassword(email: string): Promise<Result<GetUserResponse, APIError>> {
    const result = await this.serverApiClient.post<{ email: string }, GetUserResponse>(
      `${this.apiBaseUrl}/auth/forgotpassword`,
      { email }
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value);
  }

  async resetPassword(password: string, resetToken: string): Promise<Result<AuthResponse, APIError>> {
    const result = await this.serverApiClient.put<{ password: string }, AuthResponse>(
      `${this.apiBaseUrl}/auth/resetpassword/${resetToken}`,
      { password }
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value);
  }

  async getUserCredits(token: string): Promise<Result<DBCredit | null, APIError>> {
    const result = await this.serverApiClient.get<{
      success: boolean;
      credits: DBCredit | null;
    }>(`${this.apiBaseUrl}/credits`, { headers: { Cookie: `token=${token}` } });

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.credits);
  }

  async createCredits(request: CreateCreditsRequest, token: string): Promise<Result<DBCredit, APIError>> {
    const result = await this.serverApiClient.post<CreateCreditsRequest, CreditsResponse>(
      `${this.apiBaseUrl}/credits`,
      request,
      {
        headers: { Cookie: `token=${token}` },
      }
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.credits);
  }

  async spendCredits(amount: number, token: string): Promise<Result<DBCredit, APIError>> {
    const result = await this.serverApiClient.put<{ amount: number }, CreditsResponse>(
      `${this.apiBaseUrl}/credits/spend-credits`,
      { amount },
      { headers: { Cookie: `token=${token}` } }
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.credits);
  }

  async getCreditFactor(currency: Currency | string): Promise<Result<number, APIError>> {
    if (currency === Currency.$) currency = "USD";

    const result = await this.serverApiClient.get<{ creditFactor: number }>(
      `${this.apiBaseUrl}/credits/credit-factor?currency=${currency}`
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.creditFactor);
  }

  async createOrderMetadata(draftOrder: DraftOrder, orderName: string): Promise<Result<OrderMetadata, APIError>> {
    const orderMetadata: OrderMetadata = {
      deployment: draftOrder.shipping_address.country_code,
      orderName,
      orderDate: new Date(),
      customerMetadata: {
        email: draftOrder.email,
        firstName: draftOrder.shipping_address.first_name || "",
        lastName: draftOrder.shipping_address.last_name || "",
      },
      totalPrice: Number(draftOrder.total_price),
      currency: draftOrder.currency,
      lineItems: draftOrder.line_items.map((item) => ({
        variantId: item.variant_id,
        sku: item.sku,
        price: Number(item.price),
        quantity: item.quantity,
      })),
    };

    const result = await this.serverApiClient.post<OrderMetadata, { success: boolean; order: OrderMetadata }>(
      `${this.apiBaseUrl}/orders-metadata`,
      orderMetadata
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.order);
  }

  async getUserOrders(token: string): Promise<Result<OrderMetadata[], APIError>> {
    const result = await this.serverApiClient.get<{
      success: Boolean;
      orders: OrderMetadata[];
    }>(`${this.apiBaseUrl}/customers/orders`, {
      headers: { Cookie: `token=${token}` },
    });

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.orders);
  }

  async getUsersByIds(ids: string[]): Promise<Result<DBUser[], APIError>> {
    const result = await this.serverApiClient.get<GetUsersResponse>(
      `${this.apiBaseUrl}/customers?_id[in]=${ids.join(",")}`
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.data);
  }

  async getUserByReferralCode(referralCode: string): Promise<Result<DBUser, APIError>> {
    const result = await this.serverApiClient.get<GetUsersResponse>(
      `${this.apiBaseUrl}/customers?referralConfigs.code=${referralCode}`
    );

    if (result.isErr()) {
      return err(result.error);
    }

    if (result.value.data.length === 0) {
      return err({
        errorMessage: `Couldn't find user with referral code: ${referralCode}`,
      });
    }

    return ok(result.value.data[0]);
  }

  async getReferralOrders(referralCode: string): Promise<Result<OrderMetadata[], APIError>> {
    const result = await this.serverApiClient.get<GetOrdersResponse>(
      `${this.apiBaseUrl}/orders?discounts.name=${referralCode}`
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.data);
  }

  async subscribeToNewsletter(data: SubscribeToNewsletterRequest): Promise<Result<{ success: boolean }, APIError>> {
    const result = await this.serverApiClient.post<SubscribeToNewsletterRequest, { success: boolean }>(
      `${this.apiBaseUrl}/newsletter/subscribe`,
      data
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value);
  }

  async deactivateDiscountCode(discountCode: string, omsConnector: string) {
    const result = await this.serverApiClient.put<{ omsConnector: string }, { success: boolean; data: any }>(
      `${this.apiBaseUrl}/discounts/deactivate/${discountCode}`,
      {
        omsConnector,
      }
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.data);
  }

  async getDiscountCode(discountCode: string) {
    const result = await this.serverApiClient.get<{
      success: boolean;
      discount: any;
    }>(`${this.apiBaseUrl}/discounts/${discountCode}`);

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.discount);
  }

  async processOrderGiftCards(orderId: number, shopifyStoreName: string, shopifyAccessToken: string) {
    const result = await this.serverApiClient.post<
      { orderId: number; storeName: string; accessToken: string },
      { success: boolean; data: any }
    >(`${this.apiBaseUrl}/gift-cards/process-order`, {
      orderId,
      storeName: shopifyStoreName,
      accessToken: shopifyAccessToken,
    });

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.data);
  }
}
