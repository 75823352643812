import Image from "next/image";
import { memo } from "react";
import classNames from "src/helpers/classNames";
import { useTranslations } from "next-intl";
import spaceToUnderscore from "src/helpers/strings/spaceToUnderscore";
import spaceToNbsp from "src/helpers/spaceToNbsp";

type AbsorptionsProps = {
  value: "Strong" | "Strong +" | "Ultra" | string;
  className?: string;
  imageClassName?: string;
  withText?: boolean;
  isMobileMenu?: boolean;
};

function Absorptions({
  value,
  className = "",
  imageClassName = "w-4",
  withText = false,
  isMobileMenu = false,
}: AbsorptionsProps) {
  const level = value === "Strong" ? 0 : value === "Strong +" ? 1 : 2;
  const t = useTranslations("Absorptions");

  return (
    <div className={classNames("flex items-center gap-x-2", className)}>
      <ul className="flex">
        {Array(5)
          .fill(null)
          .map((_, index) => {
            const outline = index - level >= 3;
            // (index === 3 && level < 1) || (index === 4 && level < 2);
            return (
              <li key={index}>
                <Image
                  className={imageClassName}
                  src={`/images/drop-${outline ? "outline" : "solid"}.svg`}
                  alt=""
                  sizes="1rem"
                  width={0}
                  height={0}
                />
              </li>
            );
          })}
      </ul>
      {withText && (
        <p
          className={classNames(
            "hidden text-sm font-medium",
            !isMobileMenu ? "sm:block" : ""
          )}
        >
          {spaceToNbsp(t(spaceToUnderscore(value)))}
        </p>
        // <p className="ms-2 text-sm font-medium">{spaceToNbsp(t(value))}</p>
      )}
    </div>
  );
}

export default memo(Absorptions);
