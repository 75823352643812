import NextImage from "next/image";
import { memo } from "react";
import { Image } from "src/types/storefront";
import classNames from "src/helpers/classNames";

type Props = {
  images: Image[];
  imageClassName?: string;
};

function Preview({ images, imageClassName = "" }: Props) {
  return (
    <div
      className={classNames(
        "relative aspect-[6/8] animate-pulse-bg",
        imageClassName
      )}
    >
      {images.map((image, index) => (
        <NextImage
          key={index}
          className={classNames(
            "w-full object-cover object-center transition-opacity",
            index === 1
              ? "opacity-0 transition-opacity group-hover:opacity-100"
              : ""
          )}
          src={image.url}
          alt={typeof image.altText === "string" ? image.altText : ""}
          sizes="
        (min-width: 20em) 12rem,
        (min-width: 27em) 21rem,
        (min-width: 64em) 15rem,
        50vw"
          fill
          width={0}
          height={0}
        />
      ))}
    </div>
  );
}

export default memo(Preview);
