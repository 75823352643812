function isArray(data: any): Boolean {
  return data && data.constructor === Array;
}

function isObject(data: any): Boolean {
  return data && data.constructor === Object;
}

export default function withoutEdgesAndNodes(data: any): any {
  let result = Array.isArray(data) ? [] : {};

  if (!isObject(data) && !isArray(data)) return data;

  for (const key in data) {
    if (typeof key === "string" && key === "edges") {
      result = withoutEdgesAndNodes(data.edges.map((edge: any) => edge.node));
    } else {
      result = Object.assign(result, {
        [key]: withoutEdgesAndNodes(data[key]),
      });
    }
  }

  return result;
}
