import { memo } from "react";
import { useTranslations } from "next-intl";

type BottomBarProps = {};

function BottomBar({}: BottomBarProps) {
  const t = useTranslations("Navigation");
  /*const navigation: { name: string; href: string } = [
		{ name: "Search", href: "/" },
		{ name: "Terms of services", href: "/" },
		{ name: "Refund policy", href: "/" }
	];*/

  return (
    <div className="mt-10 bg-gray-900">
      <div className="mx-auto max-w-7xl px-4 py-5 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center text-white lg:flex lg:max-w-none lg:items-center lg:justify-between">
          <ul className="mb-5 lg:order-1 lg:mb-0 lg:flex">
            {/*{navigation.map((item) => (
							<li key={item.name}>
								<Link href={item.href}>
									<a className="block px-2 py-1.5">{item.name}</a>
								</Link>
							</li>
						))}*/}
          </ul>
          <p className="text-white">{t("copyright_notice_2022")}</p>
        </div>
      </div>
    </div>
  );
}

export default memo(BottomBar);
