import { useTranslations } from "next-intl";
import StickyFooterButton from "./components/NavigateButton";
import SupportButton from "./components/SupportButton";
import PopUpButton from "./components/PopUpButton";
import useCountryCode from "src/hooks/useCountryCode";
import useRtl from "src/hooks/useRtl";
import { useRouter } from "next/router";
import classNames from "src/helpers/classNames";

type StickyFooterProps = {
  showStickyFooterNavigationButton?: boolean;
  showStickyFooterPopUpButton?: boolean;
  stickyFooterButtonTitle?: string;
  stickyFooterButtonURL?: string;
  StickyFooterPopUpComponent?: JSX.Element;
};
const StickyFooter = (props: StickyFooterProps) => {
  const t = useTranslations("StickyFooter");
  const rtl = useRtl();
  const { pathname } = useRouter();

  const {
    showStickyFooterNavigationButton,
    showStickyFooterPopUpButton,
    StickyFooterPopUpComponent,
    stickyFooterButtonTitle = t("shop_now"),
    stickyFooterButtonURL,
  } = props;

  if (pathname.includes("/drshemsi")) return <></>;
  return (
    <>
      <div
        className={classNames(
          "md:border-t-none fixed bottom-0 z-10 flex h-20 w-full justify-between gap-4 border-gray-200 shadow-gray-800 md:bottom-8 md:border-none md:shadow-none",
          showStickyFooterNavigationButton || showStickyFooterPopUpButton
            ? `border-t bg-white p-4 shadow-2xl md:bg-inherit md:p-0  ${
                rtl ? "md:left-2 md:w-40" : "md:right-2 md:w-40"
              }`
            : `${rtl ? "md:left-0 md:w-40 md:p-0" : "md:right-0 md:w-40 md:p-0"}`
        )}
      >
        {showStickyFooterNavigationButton && (
          <StickyFooterButton
            showStickyFooterNavigationButton={showStickyFooterNavigationButton}
            stickyFooterButtonTitle={stickyFooterButtonTitle}
            stickyFooterButtonURL={stickyFooterButtonURL}
          />
        )}
        {showStickyFooterPopUpButton && (
          <PopUpButton
            stickyFooterButtonTitle={stickyFooterButtonTitle}
            showStickyFooterPopUpButton={showStickyFooterPopUpButton}
            StickyFooterPopUpComponent={StickyFooterPopUpComponent}
          />
        )}
        {/* {<SupportButton />} */}
      </div>
    </>
  );
};

export default StickyFooter;
