import { Product } from "src/types/storefront";
import addStockToValues from "./addStockToValues";
import getOptionValues from "./getOptionValues";

export interface ValueWithStock {
	value: string;
	stock: number;
}

export interface VariantFilter {
	name: string;
	value: string;
}

export default function getOptionValuesWithStock(
	product: Product,
	optionName: string,
	filters: VariantFilter[] = []
) {
	// ["black", "blue", ...]
	const values = getOptionValues(product, optionName);

	// if product doesn't have the option
	if (!values) return null;

	// [{value: "black", stock: 7}, {value: "blue", stock: 9}, ...]
	const valuesWithStock = addStockToValues(
		product,
		values,
		optionName,
		filters
	);

	return valuesWithStock;
}
