import sleep from "./sleep";

/**
 * Takes an async function and a retry count
 * Tries to run the function (count) number of times
 * If it succeeds, it return the value of the async function
 * Otherwise, return whatever the async function's value is
 * To be handled by an upper try/catch block
 */
export default async function retryAsync<ReturnType>(
  asyncFunction: () => Promise<ReturnType>,
  count: number = 3,
  timeout: number = 0
): Promise<ReturnType> {
  for (let i = 0; i < count; i++) {
    console.log(`attempt #${i}: ${asyncFunction.name}`);
    try {
      if (i > 0) await sleep(timeout);
      const result = await asyncFunction();
      return result;
    } catch (error) {
      continue;
    }
  }
  /**
   * After finishing all attempts,
   * return the async function so it can
   * be caught by an upper try/catch block
   */
  return await asyncFunction();
}
