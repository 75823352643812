import { Product } from "src/types/storefront";
import useProducts from "./useProducts";
import useCountryData from "./useCountryData";
import getProductsByCollectionIds from "src/helpers/storefront-helpers/getProductsByCollectionIds";

export default function useMainProducts() {
  const allProducts = useProducts();
  const countryData = useCountryData();

  let mainProducts: Product[] = [];
  if (countryData) {
    const defaultCollection = Object.values(countryData.collections).find(
      (collection) => collection.isDefault
    );
    if (defaultCollection) {
      mainProducts = getProductsByCollectionIds(allProducts, [
        defaultCollection.id,
      ]);
    }
  }

  return mainProducts;
}
