import { AnimatePresence, motion } from "framer-motion";
import { memo, ReactNode } from "react";
import classNames from "src/helpers/classNames";
import Spinner from "../spinner/Spinner";
import ErrorMessage from "../alerts/ErrorMessage";

type Props = {
  className?: string;
  onClick?(): void;
  type?: "button" | "submit";
  hover?: boolean;
  reverse?: boolean;
  disabled?: boolean;
  children: ReactNode;
  id?: string;
  loading?: boolean;
  error?: string;
  containerClassname?: string;
  noHover?: boolean;
};

function ThemeButton({
  className = "",
  onClick,
  type = "button",
  hover = true,
  reverse = false,
  disabled = false,
  children,
  id,
  loading = false,
  error,
  containerClassname,
  noHover,
}: Props) {
  return (
    <div
      className={classNames(
        "space-y-1.5",
        containerClassname ? containerClassname : ""
      )}
    >
      <button
        className={classNames(
          "shemsiBtn shemsiBtn-primary disabled:shemsiBtn-reverse flex items-center justify-center gap-2",
          reverse ? "shemsiBtn-reverse" : "",
          noHover ? "" : "shemsiBtn-hover",
          className
        )}
        id={id}
        type={type}
        onMouseDown={(event) => event.preventDefault()}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
        <AnimatePresence>
          {/* Loading state */}
          {loading && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              <Spinner className="h-5 w-5 !fill-white !text-gray-800" />
            </motion.div>
          )}
        </AnimatePresence>
      </button>

      {/* Error state */}
      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            <ErrorMessage message={error} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default memo(ThemeButton);
