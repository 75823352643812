import Link from "next/link";
import { memo, useState } from "react";
import classNames from "src/helpers/classNames";
import Preview from "./components/Preview";
import { useTranslations } from "next-intl";
import AbsorptionPicker from "../absorptions/AbsorptionPicker";
import Colors from "src/components/colors/ColorPicker";
import { KoalaProduct } from "src/types/koala/KoalaProduct";
import koalaUtils from "src/helpers/koala/koalaUtils";
import { flow, matchesProperty, negate, property } from "lodash";
import KoalaPreview from "./components/KoalaPreview";
import usePriceBeforeDiscount from "src/hooks/usePriceBeforeDiscount";

type KoalaProductItemProps = {
  product: KoalaProduct;
  onClick?: Function;
  withHoverButton?: boolean;
  isMobileMenu: boolean;
  imageClassName?: string;
};

function KoalaProductItem({
  product,
  withHoverButton = true,
  onClick = () => {},
  imageClassName = "",
}: KoalaProductItemProps) {
  // Dependencies
  const t = useTranslations("ProductItem");
  const cheapestVariant = koalaUtils.getCheapestVariant(product);
  const initalVariantOptions = cheapestVariant ? cheapestVariant.selectedOptions : [];
  const [variantOptions, setVariantOptions] = useState(initalVariantOptions);
  const selectedVariant = koalaUtils.getVariant(product, variantOptions);
  const priceBeforeDiscount = usePriceBeforeDiscount(selectedVariant?.price);

  function handleOptionChange(optionName: string) {
    return function (value: string) {
      setVariantOptions((prevOptions) =>
        prevOptions.filter((option) => option.name !== optionName).concat({ name: optionName, value })
      );
    };
  }

  const absorptionValuesWithStock = koalaUtils.getOptionValuesWithStock(product, "Absorption");
  const colorValuesWithStock = koalaUtils.getOptionValuesWithStock(
    product,
    "Color",
    variantOptions.filter(matchesProperty("name", "Absorption"))
  );

  const variantImages = selectedVariant ? selectedVariant.images : product.images;

  /**
   * currency
   */
  let currency = selectedVariant?.currency;
  if (currency === "USD") currency = "$";

  return (
    <article className="flex h-full flex-col gap-y-3">
      {/* Image */}
      <Link
        href={{
          pathname: `/product/${product.handle}`,
          query: variantOptions.reduce((acc, { name, value }) => Object.assign(acc, { [name]: value }), {}),
        }}
        className={classNames("product-item group relative block", `products-list__${product.handle}`)}
        aria-label="product_item"
        onClick={() => onClick()}
      >
        <KoalaPreview images={variantImages.slice(0, 2)} imageClassName={imageClassName} />
        {withHoverButton && (
          <div className="absolute bottom-12 left-1/2 z-10 hidden min-w-[70%] -translate-x-1/2 px-2 opacity-0 transition-opacity group-hover:opacity-100 sm:block">
            <div className="shemsiBtn shemsiBtn-primary w-full ">{t("shop_now")}</div>
          </div>
        )}
      </Link>

      {/* Product details */}
      <div className="flex flex-1 flex-col gap-y-2">
        {/* handle and price */}
        <div className="flex items-start justify-between sm:items-center">
          {/* handle */}
          <h3 className="font-semibold">
            <Link
              href={{
                pathname: `/product/${product.handle}`,
                query: variantOptions.reduce((acc, { name, value }) => Object.assign(acc, { [name]: value }), {}),
              }}
              className="block"
              onClick={() => onClick()}
            >
              {product.name}
            </Link>
          </h3>

          {/* Price */}
          <div className="flex flex-col gap-x-2 sm:flex-row sm:items-center">
            {/* compare at price */}
            {priceBeforeDiscount ? (
              <p className="shrink-0 text-xs font-thin text-red-500 line-through">
                {t("price", {
                  amount: priceBeforeDiscount,
                  currency,
                })}
              </p>
            ) : (
              <></>
            )}
            {/* actual price */}
            {selectedVariant?.price && (
              <p className={classNames("shrink-0 font-semibold")}>
                {t("price", {
                  amount: selectedVariant.price,
                  currency,
                })}
              </p>
            )}
          </div>
        </div>

        <div className="">
          {/* Absorptions */}
          {absorptionValuesWithStock && (
            <AbsorptionPicker
              valuesWithStock={absorptionValuesWithStock}
              selectedAbsorption={variantOptions.find(matchesProperty("name", "Absorption"))?.value || null}
              onChange={handleOptionChange("Absorption")}
            />
          )}

          {/* Colors */}
          {colorValuesWithStock && (
            <Colors
              selectedColor={variantOptions.find(matchesProperty("name", "Color"))?.value || ""}
              valuesWithStock={colorValuesWithStock}
              setSelectedColor={handleOptionChange("Color")}
            />
          )}
        </div>
      </div>
    </article>
  );
}

export default memo(KoalaProductItem);
