import Script from "next/script";
import { useEffect } from "react";
import useCountryCode from "src/hooks/useCountryCode";

type GoogleAnalyticsProps = {};

const GoogleAnalytics = ({}: GoogleAnalyticsProps) => {
  const countryCode = useCountryCode();
  const measurementId =
    countryCode === "LB" ? process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID_LB : process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;
  useEffect(() => {
    console.log("countryCode from GA4", countryCode);
    console.log("measurementId from GA4", measurementId);
  }, [countryCode]);
  return (
    <>
      <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`} />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${measurementId}');
            `}
      </Script>
    </>
  );
};

export default GoogleAnalytics;
