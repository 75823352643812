import { DiscountDetails } from "src/types/discount/DiscountDetails";
import { DraftOrder } from "src/types/shopify-admin";

export default function getAllDiscountsFromDraftOrder(
  draftOrder: DraftOrder
): DiscountDetails[] {
  let allDiscountsDetails: DiscountDetails[] = [];
  if (draftOrder.applied_discount?.title) {
    try {
      allDiscountsDetails = JSON.parse(draftOrder.applied_discount.title);
    } catch (error) {}
  }
  return allDiscountsDetails;
}
