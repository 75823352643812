import getCookie from "src/helpers/cookie/getCookie";

const current_timestamp = Math.floor(new Date().valueOf() / 1000);
const uniqueID = "id" + Math.random().toString(16).slice(2);

export const generateFbpFallbackKey = () => {
  const fbclid = getCookie("fbclid");
  if (!fbclid) return;
  const fbpFallback = "fb.1." + current_timestamp + "." + uniqueID;
  return fbpFallback;
};

export const generateFbcFallbackKey = () => {
  const fbclid = getCookie("fbclid");
  if (!fbclid) return;
  const fbcFallback = "fb.1." + current_timestamp + "." + fbclid;

  return fbcFallback;
};
