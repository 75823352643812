import { faker } from "@faker-js/faker";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";

type Props = {};

function SnowBackground({}: Props) {
  const isLargeScreen = useMediaQuery("(min-width: 640px)");
  const [snowflakesCount, setSnowflakesCount] = useState(0);

  useEffect(() => {
    setSnowflakesCount(isLargeScreen ? 100 : 50);
  }, [isLargeScreen]);

  return (
    <div className="relative">
      {/* star */}
      <Image
        className="absolute left-[15%] top-12 w-20 animate-star-pulse opacity-60 lg:top-32"
        src={"/images/christmas/star.svg"}
        alt={""}
        width={0}
        height={0}
      />
      {/* Snowflakes */}
      <div>
        {Array(snowflakesCount)
          .fill(null)
          .map((_, index) => (
            <span
              // animate-snowflake-shake
              key={index}
              className="fixed -top-10 animate-snowflake-shake"
              style={{
                left: faker.datatype.number({ min: 0, max: 100 }) + "%",
              }}
            >
              <Image
                // animate-snowflake-fall
                className="h-auto animate-snowflake-fall"
                style={{
                  width: faker.datatype.number({ min: 10, max: 40 }) + "px",
                  opacity: faker.datatype.number({
                    min: 0.75,
                    max: 1,
                    precision: 0.1,
                  }),
                  animationDelay:
                    faker.datatype.number({
                      min: 0,
                      max: 8,
                      precision: 0.1,
                    }) + "s",
                }}
                src="/images/christmas/snowflake.svg"
                alt=""
                width={0}
                height={0}
              />
            </span>
          ))}
      </div>
    </div>
  );
}

export default SnowBackground;
