import countries from "src/config/countries/countries";
import ApiClient from "src/helpers/api-client/apiClient";

export interface StorefrontError {
  message: string;
}

export default async function storefront<T>(
  countryCode: string,
  query: string,
  variables: { [key: string]: T } = {}
) {
  const countryData = countries[countryCode];
  const { STORE_NAME, STOREFRONT_TOKEN } = countryData.shopifyKeys;

  const data = JSON.stringify({
    query,
    variables,
  });

  const config = {
    method: "post",
    url: `https://${STORE_NAME}.myshopify.com/api/2024-01/graphql.json`,
    headers: {
      "Content-Type": "application/json",
      "X-Shopify-Storefront-Access-Token": STOREFRONT_TOKEN,
    },
    data,
  };

  const apiClient = new ApiClient({});
  const response = await apiClient.request<any>(config);

  if (response.isErr()) {
    throw new Error(JSON.stringify(response.error));
  }

  if (response.value.error) {
    console.log(response.value.error);
    throw new Error(JSON.stringify(response.value.error));
  }

  return response.value;
}
