import { memo } from "react";
import useCountryCode from "src/hooks/useCountryCode";
import useCountryData from "src/hooks/useCountryData";
import LanguageButton from "./LanguageButton";
import LanguageDropDown from "./LanguageDropDown";
import Cookies from "js-cookie";
import { useRouter } from "next/router";

type LanguageSelectorProps = {};

function LanguageSelector({}: LanguageSelectorProps) {
  const { locale } = useRouter();

  const setLanguageToCookies = (selectedLanguage: string) => {
    if (locale === "default") return;
    Cookies.set("selected_language", selectedLanguage);
  };
  const countryCode = useCountryCode();
  const countryData = useCountryData();
  if (countryCode === "US") return <></>;
  if (countryData && countryData.supportedLanguages.length > 2) {
    return <LanguageDropDown setLanguageToCookies={setLanguageToCookies} />;
  }

  return <LanguageButton setLanguageToCookies={setLanguageToCookies} />;
}

export default memo(LanguageSelector);
