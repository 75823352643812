import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";

type Props = {
  message: string;
  id?: string;
};

function SuccessMessage({ message, id = "success_message" }: Props) {
  return (
    <motion.p
      id={id}
      className="flex gap-1 rounded-md bg-green-100 px-2 py-2 text-sm text-green-900"
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{ duration: 0.2 }}
    >
      <CheckCircleIcon className="w-5 shrink-0 text-green-500" />
      {message}
    </motion.p>
  );
}

export default SuccessMessage;
