export default function isProductInCollection(
  productCollections: { id: string }[],
  collection: number | undefined
) {
  const collectionIdsArray = productCollections.map((item) => {
    const collectionId = item.id.split("/").pop();
    return Number(collectionId);
  });
  if (collectionIdsArray.includes(collection ? collection : 0)) {
    return true;
  } else {
    return false;
  }
}
