import consts from "src/config/consts";
import { Line } from "src/types/storefront/Cart";
import generatePriceBeforeDiscount from "../generatePriceBeforeDiscount";

export default function getCartItemsWithFakeDiscountSubtotal(
  lineItems: Line[]
) {
  const cartItemsWithFakeDiscount = lineItems
    // Get array of items without gift cards
    .filter(
      (item) => !item.merchandise.sku.startsWith(consts.GIFT_CARD_SKU_PREFIX)
    )
    // Add fake discount to each item's price
    .map((item) => {
      return generatePriceBeforeDiscount(
        Number(item.merchandise.price.amount) * item.quantity,
        consts.FAKE_DISCOUNT_PERCENTAGE_VALUE
      );
    });

  // Sum the amount of the items
  const itemsWithFakeDiscountSubtotal = cartItemsWithFakeDiscount.reduce(
    (acc, curr) => acc + curr,
    0
  );

  return itemsWithFakeDiscountSubtotal;
}
