import ProductItem from "src/components/product-item/ProductItem";
import classNames from "src/helpers/classNames";
import { Product } from "src/types/storefront";

type Props = {
  products: Product[];
  isMobileMenu?: boolean;
  onProductClick?: Function;
  className?: string;
  imageClassName?: string;
};

const ProductsList = ({
  products,
  isMobileMenu = false,
  onProductClick = () => {},
  className = "",
  imageClassName = "",
}: Props) => {
  return (
    <ul
      className={classNames(
        "grid grid-cols-2 gap-x-3 gap-y-6 lg:grid-cols-4 lg:gap-x-4",
        className
      )}
    >
      {products.map((product) => (
        <li key={product.handle} className="product">
          <ProductItem
            onClick={onProductClick}
            product={product}
            isMobileMenu={isMobileMenu}
            imageClassName={imageClassName}
          />
        </li>
      ))}
    </ul>
  );
};

export default ProductsList;
