import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";

interface NotificationContextProps {
  children: ReactNode;
}

export enum NotificationTypes {
  SUCCESS,
  FAILURE,
}

export interface NotificationProps {
  showNotification: boolean;
  setShowNotification: Dispatch<SetStateAction<boolean>>;
  notificationMessage: string;
  setNotificationMessage: Dispatch<SetStateAction<string>>;
  notificationType: NotificationTypes;
  setNotificationType: Dispatch<SetStateAction<NotificationTypes>>;
}

export const NotificationContext = createContext<NotificationProps>(
  {} as NotificationProps
);

export function NotificationProvider({ children }: NotificationContextProps) {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState<NotificationTypes>(
    NotificationTypes.SUCCESS
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showNotification) {
      timeout = setTimeout(() => setShowNotification(false), 4000);
    }
    return () => clearTimeout(timeout);
  }, [showNotification]);

  return (
    <NotificationContext.Provider
      value={{
        showNotification,
        setShowNotification,
        notificationMessage,
        setNotificationMessage,
        notificationType,
        setNotificationType,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
