import { AnimatePresence } from "framer-motion";
import { ChangeEventHandler, memo, useState } from "react";
import classNames from "src/helpers/classNames";
import useRtl from "src/hooks/useRtl";
import ErrorMessage from "../alerts/ErrorMessage";

type Props = {
  name?: string;
  inputId?: string;
  label: string;
  type?: HTMLInputTypeAttribute;
  value?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  containerClassName?: string;
  labelClassName?: string;
  error?: boolean;
  errorMessage?: string;
};

type HTMLInputTypeAttribute =
  | "button"
  | "checkbox"
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "password"
  | "radio"
  | "range"
  | "reset"
  | "search"
  | "submit"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week";

function InlineInput({
  label,
  onChange,
  name = "",
  inputId = "",
  containerClassName = "",
  type = "text",
  value = "",
  labelClassName = "bg-white",
  error = false,
  errorMessage = "",
}: Props) {
  const [focused, setFocused] = useState(false);

  const rtl = useRtl();

  return (
    <div id={inputId} className={classNames("space-y-1", containerClassName)}>
      <label className="relative block">
        <span
          className={classNames(
            `absolute -top-3 cursor-text px-1 text-gray-500 transition origin-start `,
            focused || value.length > 0
              ? `translate-y-0 scale-75`
              : "translate-y-5",
            rtl ? "right-[.9rem]" : "left-[.9rem]",
            labelClassName
          )}
        >
          {label}
        </span>
        <input
          className={error || errorMessage ? "input-text-error" : "input-text"}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </label>
      <AnimatePresence>
        {errorMessage && <ErrorMessage message={errorMessage} />}
      </AnimatePresence>
    </div>
  );
}

export default memo(InlineInput);
