import { Product, SelectedOption } from "src/types/storefront";
import getOptionValueByName from "./getOptionValueByName";

export default function getVariant(
  product: Product,
  selectedOptions: SelectedOption[]
) {
  const variant =
    product.variants.find((variant) =>
      selectedOptions.every(
        ({ name, value }) =>
          value === getOptionValueByName(name, variant.selectedOptions)
      )
    ) || null;

  return variant;
}
