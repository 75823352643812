/**
 This component is responsible to:

 * Render a fallback UI after an error is thrown
 * Provide a way to reset the Application's state
 * Log error information
 */

import { captureException } from "@sentry/nextjs";
import { NextRouter, withRouter } from "next/router";
import React, { ErrorInfo, ReactNode } from "react";
import Error from "src/sections/error/Error";
import Layout from "src/sections/layout/Layout";
import { HighlightInit, H } from "@highlight-run/next/client";
import highlightError from "src/helpers/highlight/highlightError";

interface Props {
  children: ReactNode;
  router: NextRouter;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  /**
   * To render a fallback UI after an error has been thrown
   */
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  /**
   * To log error information.
   */
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can use your own error logging service here
    console.log(error, errorInfo);
    highlightError(error, error.message, {
      componentStack: errorInfo.componentStack,
    });
    captureException(JSON.stringify({ error, errorInfo }));
  }

  render() {
    const { locale } = this.props.router;
    const isRtl = locale && locale.substring(0, 2) === "ar";

    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Layout
          pageTitle={isRtl ? "خطأ من جانبنا" : "Client side error"}
          isCheckout={true}
        >
          <Error
            title={
              isRtl
                ? "عذراً! حدث خطأ ما من جانبنا. رجاءاً حاولي مرةً أخرى."
                : "Sorry! Something went wrong on our end. Please try again."
            }
            href="/"
            buttonText={isRtl ? "إعادة تحميل الصفحة" : "Reload the page"}
          />
        </Layout>
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
