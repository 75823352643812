import { DiscountType } from "src/types/discount/DiscountType";
import { DraftOrder } from "../../types/shopify-admin";
import { DiscountDetails } from "src/types/discount/DiscountDetails";

export default function getDraftOrderDiscountByType(
  draftOrder: DraftOrder,
  discountType: DiscountType
): DiscountDetails | null {
  if (!draftOrder.applied_discount?.title) return null;

  let allDiscounts: DiscountDetails[] = [];
  try {
    allDiscounts = JSON.parse(draftOrder.applied_discount.title);
  } catch (error) {
    console.error(error);
  }

  const resultDiscount = allDiscounts.find(
    (discount) => discount.type === discountType
  );

  if (!resultDiscount) return null;

  return resultDiscount;
}
