import { useContext } from "react";
import { BundleDiscountContext } from "src/context/BundleDiscountContext";
import { CreditsContext, CreditsContextProps } from "src/context/CreditsContext";
import { ReferralContext, ReferralContextProps } from "src/context/ReferralContext";
import { CartContext, CartContextProps } from "src/context/cart";
import { DraftOrderContext, DraftOrderContextProps } from "src/context/draftOrder";
import useCountryData from "./useCountryData";
import { DiscountDetails } from "src/types/discount/DiscountDetails";
import { Cart, DiscountCode } from "src/types/storefront/Cart";
import getCartAppliedDiscount from "src/helpers/cart/getCartAppliedDiscount";
import getCartDiscountedAmount from "src/helpers/cart/getCartDiscountedAmount";
import { DiscountType } from "src/types/discount/DiscountType";
import calculateRefereeCreditsAmount from "src/helpers/referral/calculateRefereeCreditsAmount";
import preciseDecimal from "src/helpers/preciseDecimal";
import getDraftOrderDiscountByType from "src/helpers/draft-order/getDraftOrderDiscountByType";
import getBundleDicountAmountFromCart from "src/helpers/bundle-discounts/getBundleDicountAmountFromCart";
import getCartSubtotalPrice from "src/helpers/cart/getCartSubtotalPrice";
import { sumBy } from "lodash";
import { BundleCart } from "src/types/bundle-discounts/BundleCart";
import { SummaryDetails } from "src/types/summary-details/SummaryDetails";
import consts from "src/config/consts";
import getCartItemsWithFakeDiscountSubtotal from "src/helpers/cart/getCartItemsWithFakeDiscountSubtotal";
import isAllCartItemsGifts from "src/helpers/cart/isAllCartItemsGifts";
import { DeliveryOption, PaymentContext, PaymentProps } from "src/context/payment";
import { CountryCode } from "src/types/localization/CountryCode";

export default function useSummaryDetails(
  cart: Cart | null = null,
  bundleCart: BundleCart | null = null
): SummaryDetails {
  // dependencies
  const countryData = useCountryData();
  const { cart: contextCart } = useContext(CartContext) as CartContextProps;
  const contextBundleCart = useContext(BundleDiscountContext);
  const { activeReferralConfig } = useContext(ReferralContext) as ReferralContextProps;
  const { creditFactor } = useContext(CreditsContext) as CreditsContextProps;
  const { draftOrder } = useContext(DraftOrderContext) as DraftOrderContextProps;
  const { deliveryOption } = useContext(PaymentContext) as PaymentProps;
  // const isFakeDiscountTestActive = useGrowthbookFeature("fake-discount", false);

  if (!cart) {
    cart = contextCart;
  }
  if (!bundleCart) {
    bundleCart = contextBundleCart;
  }

  if (!cart || !countryData) {
    return {
      subtotalPrice: 0,
      totalPriceBeforeShipping: 0,
      totalPrice: 0,
      discounts: [],
    };
  }

  // 1. discounts
  const discounts: DiscountDetails[] = [];

  /**
   * a. discount code
   */
  const codeDiscount: DiscountCode | null = getCartAppliedDiscount(cart);
  let codeDiscountAmount: number = 0;
  if (codeDiscount) {
    codeDiscountAmount = getCartDiscountedAmount(cart);
    discounts.push({
      type: DiscountType.discount_code,
      name: codeDiscount.code,
      value: codeDiscountAmount,
      valueType: "fixed_amount",
    });
  }

  /**
   * b. referral discount
   */
  if (activeReferralConfig && creditFactor) {
    const creditsAmount = calculateRefereeCreditsAmount(
      activeReferralConfig,
      Number(cart.cost.totalAmount.amount),
      creditFactor
    );
    let creditsToCurrency = preciseDecimal(creditsAmount * creditFactor);
    if (creditFactor > 50 && countryData.code == CountryCode.EG) {
      creditsToCurrency = 50;
    }
    discounts.push({
      type: DiscountType.referral_code,
      name: activeReferralConfig.code,
      value: creditsToCurrency,
      valueType: "fixed_amount",
    });
  }

  /**
   * c. credits discount
   */
  if (draftOrder) {
    const creditsDiscount = getDraftOrderDiscountByType(draftOrder, DiscountType.credits);
    if (creditsDiscount) discounts.push(creditsDiscount);
  }

  /**
   * d. Bundle discount
   */
  if (bundleCart) {
    const discountAmount = getBundleDicountAmountFromCart(bundleCart);
    discounts.push({
      type: DiscountType.bundle,
      name: preciseDecimal((discountAmount / getCartSubtotalPrice(cart)) * 100) + "%",
      value: discountAmount,
      valueType: "fixed_amount",
    });
  }

  /**
   * e. Fake discount
   */
  if (countryData.splitTestDiscount) {
    const lineItems = cart.lines;
    const cartItemsWithFakeDiscount =
      getCartItemsWithFakeDiscountSubtotal(lineItems) * (consts.FAKE_DISCOUNT_PERCENTAGE_VALUE / 100);

    if (cartItemsWithFakeDiscount > 0) {
      discounts.push({
        type: DiscountType.generated_price_discount,
        name: consts.FAKE_DISCOUNT_PERCENTAGE_VALUE + "%",
        value: cartItemsWithFakeDiscount,
        // value:
        //   generatePriceBeforeDiscount(
        //     Number(cart.cost.totalAmount.amount),
        //     consts.FAKE_DISCOUNT_PERCENTAGE_VALUE
        //   ) *
        //   (consts.FAKE_DISCOUNT_PERCENTAGE_VALUE / 100),
        valueType: "fixed_amount",
      });
    }
  }

  // 2. subtotal price
  const subtotalPrice = getCartSubtotalPrice(cart);

  // 3. total price before shipping
  const totalPriceBeforeShipping =
    getCartSubtotalPrice(cart) -
    sumBy(discounts, (discount) => (discount.type === DiscountType.generated_price_discount ? 0 : discount.value));

  // 4. total price after shipping
  let totalPrice = totalPriceBeforeShipping;
  // Check if all items are not gift cards
  const isAllItemsAreGifts = isAllCartItemsGifts(cart.lines);
  if (totalPrice < countryData.shipping.freeLimit && !isAllItemsAreGifts) {
    // Check if delivery option selected is pargo
    if (deliveryOption === DeliveryOption.pickup && countryData.pickupShipping && countryData.pickupShipping.fees) {
      totalPrice += countryData.pickupShipping.fees;
    } else {
      totalPrice += countryData.shipping.fees;
    }
  }

  return {
    discounts,
    subtotalPrice,
    totalPrice,
    totalPriceBeforeShipping,
  };
}
