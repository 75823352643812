/**
 * Product
 */
export interface ShopifyProduct {
  images: {
    src: string;

    /**
     * Other data we don't care about
     */
  }[];

  /**
   * Other data we don't care about
   */
}

/**
 * Order
 */
export interface Order {
  id: number;
  name: string;
  order_number: number;
  total_price: string;
  line_items: LineItemsEntity[];
  shipping_address: ShippingAddress;
  currency: string;
  note_attributes: NoteAttributesEntity[];
  email: string;
}
export interface LineItemsEntity {
  id: number;
  admin_graphql_api_id: string;
  fulfillable_quantity: number;
  fulfillment_service: string;
  fulfillment_status?: any;
  gift_card: boolean;
  grams: number;
  name: string;
  price: string;
  price_set: PriceSetOrAmountSetOrTotalDiscountSet;
  product_exists: boolean;
  product_id: number;
  properties?: (PropertiesEntity | any)[] | any;
  quantity: number;
  requires_shipping: boolean;
  sku: string;
  taxable: boolean;
  title: string;
  total_discount: string;
  total_discount_set: PriceSetOrAmountSetOrTotalDiscountSet;
  variant_id: number;
  variant_inventory_management: string;
  variant_title: string;
  vendor?: any;
  tax_lines?: OrderTaxLinesEntity[] | any;
  duties?: any[] | any;
  discount_allocations?: DiscountAllocationsEntity[] | any;
}
export interface PriceSetOrAmountSetOrTotalDiscountSet {
  shop_money: ShopMoneyOrPresentmentMoney;
  presentment_money: ShopMoneyOrPresentmentMoney;
}
export interface ShopMoneyOrPresentmentMoney {
  amount: string;
  currency_code: string;
}
export interface PropertiesEntity {
  name: string;
  value: string;
}
export interface OrderTaxLinesEntity {
  channel_liable?: any;
  price: string;
  price_set: PriceSetOrAmountSetOrTotalDiscountSet;
  rate: number;
  title: string;
}
export interface DiscountAllocationsEntity {
  amount: string;
  amount_set: PriceSetOrAmountSetOrTotalDiscountSet;
  discount_application_index: number;
}
/**
 * Draft order
 */
export interface DraftOrderFields {
  [key: string]:
    | RegisteringShippingAddress
    | string
    | ShippingLine
    | null
    | RegisteringLineItem[]
    | AppliedDiscount
    | NoteAttributesEntity[];
}
export interface DraftOrder {
  id: number;
  order_id: number;
  name: string;
  customer: Customer;
  shipping_address: ShippingAddress;
  billing_address: BillingAddress;
  note: string | any;
  note_attributes: NoteAttributesEntity[];
  email: string;
  currency: string;
  invoice_sent_at: string;
  invoice_url: string;
  line_items: LineItem[];
  payment_terms: PaymentTerms;
  shipping_line: ShippingLine | null;
  source_name: string;
  tags: string;
  tax_exempt: boolean;
  tax_exemptions: string[] | any;
  tax_lines: OrderTaxLinesEntity[] | any;
  applied_discount: AppliedDiscount | null;
  taxes_included: boolean;
  total_tax: string;
  subtotal_price: number;
  total_price: string;
  completed_at: string;
  created_at: string;
  updated_at: string;
  status: string;
}
export interface RegisteringDraftOrder {
  shipping_address: RegisteringShippingAddress;
  email: string;
  shipping_line?: ShippingLine | null;
  line_items: RegisteringLineItem[];
  applied_discount?: AppliedDiscount | null;
  note_attributes?: NoteAttributesEntity[];
}
export interface OrderId {
  id: number;
}
export interface Customer {
  id: number;
  email: string;
  accepts_marketing: boolean;
  created_at: string;
  updated_at: string;
  first_name: string;
  last_name: string;
  orders_count: string;
  state: string;
  total_spent: string;
  last_order_id: number;
  note: string | any;
  verified_email: boolean;
  multipass_identifier: string | any;
  tax_exempt: boolean;
  tax_exemptions: TaxExemptionsOrAddressesOrDefaultAddress;
  phone: string;
  tags: string;
  last_order_name: string;
  currency: string;
  addresses: TaxExemptionsOrAddressesOrDefaultAddress;
  admin_graphql_api_id: string;
  default_address: TaxExemptionsOrAddressesOrDefaultAddress;
}
export interface TaxExemptionsOrAddressesOrDefaultAddress {}
export interface ShippingAddress {
  address1?: string;
  address2?: string;
  city?: string;
  company?: string | null;
  country?: string;
  first_name?: string;
  last_name?: string;
  latitude?: string;
  longitude?: string;
  phone?: string;
  province?: string;
  zip?: string;
  name: string;
  country_code: string;
  province_code?: string;
}
export interface RegisteringShippingAddress {
  first_name: string;
  last_name: string;
  country_code?: string;
  phone?: string;
  address1?: string;
  address2?: string;
  city?: string;
  province?: string;
  zip?: string;
  country?: string;
}
export interface BillingAddress {
  address1: string;
  address2: string;
  city: string;
  company: string | any;
  country: string;
  first_name: string | any;
  id: number;
  last_name: string | any;
  phone: string;
  province: string;
  zip: string;
  name: string | any;
  province_code: string | any;
  country_code: string | any;
  default: boolean;
}
export interface NoteAttributesEntity {
  name: string;
  value: string;
}
export interface LineItem {
  custom: boolean;
  fulfillable_quantity: number;
  fulfillment_service: string;
  grams: number;
  id: number;
  price: string;
  product_id: number;
  quantity: number;
  requires_shipping: boolean;
  sku: string;
  title: string;
  variant_id: number;
  variant_title: string;
  vendor: string;
  name: string;
  gift_card: boolean;
  properties: NoteAttributesEntity[] | any;
  taxable: boolean;
  tax_lines: TaxLinesEntity1[] | any;
  applied_discount: AppliedDiscount | null;
}
export interface RegisteringLineItem {
  variant_id: number;
  quantity: number;
  applied_discount?: AppliedDiscount;
  properties?: NoteAttributesEntity[];
}
export interface TaxLinesEntity1 {
  title: string;
  rate: string;
  price: string;
}
export enum AppliedDiscountValueType {
  percentage = "percentage",
  fixed_amount = "fixed_amount",
}
export interface AppliedDiscount {
  title: string;
  description?: string;
  value: string;
  value_type?: AppliedDiscountValueType;
  amount?: string;
}
export interface PaymentTerms {
  amount: number;
  currency: string;
  payment_terms_name: string;
  payment_terms_type: string;
  due_in_days: number;
  payment_schedules: PaymentSchedulesEntity[] | any;
}
export interface PaymentSchedulesEntity {
  amount: number;
  currency: string;
  issued_at: string;
  due_at: string;
  completed_at: string;
  expected_payment_method: string;
}
export interface ShippingLine {
  handle?: string;
  price: string;
  title: string;
}
export interface TaxLinesEntity {
  price: number;
  rate: number;
  title: string;
}
