import consts from "src/config/consts";
import { Line } from "src/types/storefront/Cart";

export default function getCartItemsWithoutFakeDiscountSubtotal(
  lineItems: Line[]
) {
  const cartItemsWithFakeDiscount = lineItems
    // Get array of gift cards
    .filter((item) =>
      item.merchandise.sku.startsWith(consts.GIFT_CARD_SKU_PREFIX)
    )
    // Return their prices
    .map((item) => {
      return Number(item.merchandise.price.amount) * item.quantity;
    });

  // Sum the amount of the items
  const itemsWithFakeDiscountSubtotal = cartItemsWithFakeDiscount.reduce(
    (acc, curr) => acc + curr,
    0
  );

  return itemsWithFakeDiscountSubtotal;
}
