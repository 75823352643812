import { useContext } from "react";
import { StoreMetadataContext } from "src/context/StoreMetadataContext";
import useCountryData from "./useCountryData";
import getFilteredProducts from "src/helpers/filtering-helpers/getFilteredProducts";
import { Filter } from "src/types/filters";
import sortProductsByCollection from "src/helpers/storefront-helpers/sortProductsByCollection";
import getProductsByCollectionIds from "src/helpers/storefront-helpers/getProductsByCollectionIds";

export default function useProducts(
  collectionIds?: number[],
  filters?: Filter[]
) {
  const storeMetadata = useContext(StoreMetadataContext);
  const countryData = useCountryData();

  if (!storeMetadata) return [];

  let products = storeMetadata.products;

  // 1. apply filters
  if (filters) {
    products = getFilteredProducts(products, filters);
  }

  // 2. sort products
  if (countryData) {
    products = sortProductsByCollection(products, [
      countryData.collections.COLLECTION_LADIES.id,
      countryData.collections.COLLECTION_TEENS.id,
      countryData.collections.COLLECTION_UNDERWEARS.id,
      countryData.collections.COLLECTION_ALL_SINGLE_PRODUCTS.id,
      countryData.collections.COLLECTION_ALL_PACKS.id,
      countryData.collections.COLLECTION_ACCESSORIES.id,
      countryData.collections.COLLECTION_GIFTS.id,
    ]);
  }

  if (collectionIds) {
    products = getProductsByCollectionIds(products, collectionIds);
  }

  return products;
}
