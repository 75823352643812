import { createContext, ReactNode, useEffect, useState, useContext, SetStateAction, Dispatch } from "react";
import { useRouter } from "next/router";
import EventTracker from "src/helpers/event-tracking/eventTrackingManager";
import { Cart } from "src/types/storefront/Cart";
import { DraftOrderContext, DraftOrderContextProps } from "./draftOrder";
import getLocalStorage from "src/helpers/local-storage/getLocalStorage";
import { TabbyProcessingContext, TabbyProcessingContextProps } from "./TabbyProcessingContext";
import setLocalStorage from "src/helpers/local-storage/setLocalStorage";
import { CreditCardProcessingContext, CreditCardProcessingContextProps } from "./CreditCardProcessingContext";
import { ShopifyCheckoutContext } from "./ShopifyCheckout";
import { ReferralContext, ReferralContextProps } from "./ReferralContext";
import { CartContext, CartContextProps } from "./cart";
import { ServerApiClient } from "src/apis/server-api-client";
import useCountryData from "src/hooks/useCountryData";
import isAllCartItemsGifts from "src/helpers/cart/isAllCartItemsGifts";
import { useStoreActions, useStoreState } from "src/hooks/storeHooks";
import { CountryCode } from "src/types/localization/CountryCode";

interface ContextProps {
  children: ReactNode;
}

export enum PaymentState {
  loading,
  userInformation,
  deliveryOptions,
  paymentInformation,
  successPage,
}

export enum DeliveryOption {
  delivery = "delivery",
  pickup = "pickup",
}

export interface PaymentProps {
  successInPagePayment?: boolean;
  isOrderNameLoading?: boolean;
  successOrderName?: string;
  isOrderPaid: boolean;
  paymentStep?: PaymentState;
  deliveryOption: DeliveryOption;
  setDeliveryOption: Dispatch<SetStateAction<DeliveryOption>>;
  setPaymentStep: Dispatch<SetStateAction<PaymentState>>;
  callbackSuccessInformationProvided?: () => void;
  callbackSuccessDeliveryOptionProvided: () => void;
  callbackSuccessInPagePayment: (orderName: string, paid?: boolean) => void;
}

export const PaymentContext = createContext<PaymentProps>({} as PaymentProps);

const serverApiClient = new ServerApiClient();

function PaymentProvider({ children }: ContextProps) {
  const router = useRouter();

  const { draftOrder, resetDraftOrder, isDraftOrderLoading } = useContext(DraftOrderContext) as DraftOrderContextProps;
  const { cart } = useContext(CartContext) as CartContextProps;
  const checkout = useStoreState((state) => state.checkout);
  const resetCheckout = useStoreActions((actions) => actions.resetCheckout);
  const { resetTabbySession } = useContext(TabbyProcessingContext) as TabbyProcessingContextProps;
  const { paymentIntentId, resetStripeKeys } = useContext(
    CreditCardProcessingContext
  ) as CreditCardProcessingContextProps;
  const shopifyCheckoutOrder = useContext(ShopifyCheckoutContext);
  const { resetReferral } = useContext(ReferralContext) as ReferralContextProps;

  const [paymentStep, setPaymentStep] = useState<PaymentState>(PaymentState.loading);

  const countryData = useCountryData();

  useEffect(
    function savePaymentStepToLocalStorage() {
      if (paymentStep) {
        setLocalStorage(`payment_step`, paymentStep);
      }
    },
    [paymentStep]
  );

  const [successOrderName, setSuccessOrderName] = useState("");
  const [isOrderPaid, setIsOrderPaid] = useState(false);
  const [successInPagePayment, setSuccessInPagePayment] = useState(false);
  const [isOrderNameLoading, setIsOrderNameLoading] = useState(false);
  const [deliveryOption, setDeliveryOption] = useState(DeliveryOption.delivery);
  /**
   * Set payment step to success after the order is loaded from shopify checkout
   */
  useEffect(
    function setSuccessOnShopifyCheckoutOrderLoad() {
      if (shopifyCheckoutOrder) {
        setPaymentStep(PaymentState.successPage);
        setSuccessInPagePayment(true);
      }
    },
    [shopifyCheckoutOrder]
  );

  /**
   * Sets the initial state of payment state
   */
  useEffect(() => {
    if (paymentStep === PaymentState.loading && !isDraftOrderLoading) {
      if (draftOrder) setPaymentStep(PaymentState.deliveryOptions);
      else setPaymentStep(PaymentState.userInformation);
    }
  }, [draftOrder, isDraftOrderLoading, paymentStep]);
  useEffect(() => {
    if (paymentStep === PaymentState.loading) {
      if (checkout) setPaymentStep(PaymentState.deliveryOptions);
      else setPaymentStep(PaymentState.userInformation);
    }
  }, [checkout, paymentStep]);

  /**
   * This is called when the customer information was entered successfully by a child component.
   */
  const paymentType = getLocalStorage("payment_type");

  const trackingPurchaseEvent = (cart: Cart) => {
    cart && cart.id && EventTracker.purchase(cart.cost.totalAmount.amount, cart.cost.totalAmount.currencyCode);
  };

  // Check if all items in cart are gift cards, if yes redirect to payment page
  const isAllItemsGifts = isAllCartItemsGifts(cart?.lines);

  const callbackSuccessInformationProvided = () => {
    if (countryData?.pargoPickupPoints && !isAllItemsGifts) {
      setPaymentStep(PaymentState.deliveryOptions);
      router.push("/checkout/delivery-options");
    } else {
      setPaymentStep(PaymentState.paymentInformation);
      router.push("/checkout/payment");
    }
  };

  const callbackSuccessDeliveryOptionProvided = () => {
    setPaymentStep(PaymentState.paymentInformation);
    router.push("/checkout/payment");
  };

  const callbackSuccessInPagePayment = async (orderName: string, paid = true) => {
    console.log("callbackSuccessInPagePayment");
    setIsOrderPaid(paid);
    if (cart) {
      trackingPurchaseEvent(cart);
      EventTracker.addPaymentInfo(cart, paymentType);
    }
    if (paymentIntentId) {
      resetStripeKeys();
    }
    resetReferral();
    setSuccessOrderName(orderName);
    setPaymentStep(PaymentState.successPage);
    setSuccessInPagePayment(true);
    resetDraftOrder();
    resetCheckout(countryData?.code as CountryCode);
    resetTabbySession();

    router.push("/checkout/thank-you").then(async () => {
      if (draftOrder && paid) {
        await serverApiClient.createOrderMetadata(draftOrder, orderName);
      }
    });
  };

  return (
    <PaymentContext.Provider
      value={{
        successInPagePayment,
        isOrderNameLoading,
        successOrderName,
        isOrderPaid,
        paymentStep,
        deliveryOption,
        setDeliveryOption,
        setPaymentStep,
        callbackSuccessInformationProvided,
        callbackSuccessDeliveryOptionProvided,
        callbackSuccessInPagePayment,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
}

export default PaymentProvider;
