import { Popover } from "@headlessui/react";
import { memo } from "react";
import LanguageSelector from "src/sections/header/components/LanguageComponents/LanguageSelector";
import CountryPopover from "src/components/country-popover/CountryPopover";
import useCountryData from "src/hooks/useCountryData";
import { useTranslations } from "next-intl";
import Link from "next/link";
import spaceToNbsp from "src/helpers/spaceToNbsp";

type LanguageAndCountryBarProps = {};

function LanguageAndCountryBar({}: LanguageAndCountryBarProps) {
  const t = useTranslations("Navigation");
  const countryData = useCountryData();

  return (
    <section
      aria-label="Language and country pickers"
      className="border-b border-gray-200"
    >
      <Popover.Group className="relative flex flex-row-reverse justify-between gap-x-2 xs:gap-x-4">
        <LanguageSelector />
        {/* Create a Set */}

        {countryData?.bundleDiscountSystems && (
          // absolute left-1/2 -translate-x-1/2 self-center
          <div className="flex items-center ms-auto">
            <Link
              className="rounded-full bg-beige-500 px-2 py-1 text-sm xs:text-sm lg:block"
              href="/customize-set"
            >
              {spaceToNbsp(t("create_set"))}
            </Link>
          </div>
        )}
        <CountryPopover />
      </Popover.Group>
    </section>
  );
}

export default memo(LanguageAndCountryBar);
