import axios from "axios";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import getCookie from "src/helpers/cookie/getCookie";
import {
  generateFbcFallbackKey,
  generateFbpFallbackKey,
} from "src/helpers/generateFacebookPixelKeys";
import getGoogleAnalyticsClientId from "src/helpers/getGoogleAnalyticsClientId";
import getLocalStorage from "src/helpers/local-storage/getLocalStorage";
import useCountryCode from "src/hooks/useCountryCode";
import useEnhancedAsync from "src/hooks/useEnhancedAsync";
import { Order } from "src/types/shopify-admin";
import {
  GrowthbookClientContext,
  GrowthbookClientProps,
} from "./GrowthbookClientContext";

export const ShopifyCheckoutContext = createContext<Order | null>(null);

export default function ShopifyCheckoutProvider({
  children,
}: {
  children: ReactNode;
}) {
  const router = useRouter();
  const countryCode = useCountryCode();
  const [order, setOrder] = useState<Order | null>(null);
  const snapchatClickId = getLocalStorage("ScCid") || "";
  const tiktokClickId = getLocalStorage("ttclid") || "";

  // const visitor_id = Cookies.get("visitor_id");

  const { visitorId } = useContext(
    GrowthbookClientContext
  ) as GrowthbookClientProps;

  const fetchOrder = async (orderId: string) => {
    const data = await axios.post<{
      order?: Order;
      clientId?: string;
      stopTrying?: true;
      orderConfirmed: boolean;
      sessionId: string;
    }>(
      `/api/shopify-checkout/callback/`,
      {
        orderId,
        countryCode,
        googleAnalyticsClientId: getGoogleAnalyticsClientId(),
        fbp:
          Cookies.get("_fbp") || Cookies.get("fbp") || generateFbpFallbackKey(),
        fbc:
          Cookies.get("_fbc") || Cookies.get("fbc") || generateFbcFallbackKey(),
        distinct_id: visitorId,
        ScCid: snapchatClickId,
        ttclid: tiktokClickId,
        ttp: getCookie("_ttp") || "",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    const { order, orderConfirmed, sessionId } = data.data;

    if (order) setOrder(order);
    else if (!orderConfirmed) {
      console.error(`Failed to fetch order from Shopify: ${sessionId}`);
    }
  };

  const [handleFetchOrder] = useEnhancedAsync(fetchOrder);

  /**
   * Checks if the website is redirected from Shopify's thank tou page
   * and uses the order_id from the query to fetch the order from Shopify
   */
  useEffect(
    function setOrderFromURL() {
      if (!router.isReady) return;

      const { shopify_checkout_status, order_id } = router.query;

      if (
        shopify_checkout_status === "complete" &&
        typeof order_id === "string"
      ) {
        handleFetchOrder(order_id);
      }
    },
    [router.isReady]
  );

  return (
    <ShopifyCheckoutContext.Provider value={order}>
      {children}
    </ShopifyCheckoutContext.Provider>
  );
}
