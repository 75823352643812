import { Cart } from "src/types/storefront/Cart";

export default function getCartSubtotalPrice(cart: Cart): number {
  const subtotal = cart.lines.reduce(
    (acc, line) =>
      acc + Number(line.merchandise.price.amount) * Number(line.quantity),
    0
  );

  return subtotal;
}
