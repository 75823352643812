import { ArrowRightOnRectangleIcon, UserCircleIcon, UserIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { Popover, Transition } from "@headlessui/react";
import PopoverButton from "../../button/ThemePopoverButton";
import { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from "react";
import useEnhancedAsync from "src/hooks/useEnhancedAsync";
import BlackSpinIcon from "src/components/icon/BlackSpinIcon";
import { useRouter } from "next/router";
import { ServerApiClient } from "src/apis/server-api-client";
import { AuthenticationContext, AuthenticationContextProps } from "src/context/authentication";
import Cookies from "js-cookie";
import { ShemsiApiClient } from "src/apis/shemsi-api-client";
import useCountryData from "src/hooks/useCountryData";

const serverApiClient = new ServerApiClient();
const shemsiApiClient = new ShemsiApiClient();

// async function checkIfOrderIsOver45Days(
//   setIsReferItemVisible: Dispatch<SetStateAction<boolean>>
// ) {
//   const ordersResult = await shemsiApiClient.getUserOrders();

//   if (ordersResult.isErr()) return;

//   for (const order of ordersResult.value) {
//     const deliveryDate = order.workerData?.worker2?.history.find(
//       ({ status }) => status === "delivered"
//     )?.date;

//     if (
//       deliveryDate &&
//       getDateDifferenceInDays(new Date(), new Date(deliveryDate)) >= 45
//     ) {
//       setIsReferItemVisible(true);
//     }
//   }
// }

function ProfilePopover() {
  const t = useTranslations("Profile");
  const router = useRouter();
  const countryData = useCountryData();
  const { user, setUser } = useContext(AuthenticationContext) as AuthenticationContextProps;

  // const [isReferItemVisible, setIsReferItemVisible] = useState(false);
  // useEffect(() => {
  //   if (user) checkIfOrderIsOver45Days(setIsReferItemVisible);
  // }, [user]);

  const [handleLogout, loadingLogout, submitError] = useEnhancedAsync(
    async (e) => {
      e.preventDefault();
      const result = await serverApiClient.signout();

      if (result.isErr()) {
        throw new Error(JSON.stringify(result.error));
      }

      Cookies.remove("token");
      await router.push("/");
      setUser(null);
    },
    { customError: t("something_went_wrong") }
  );

  return (
    <div className="sm:relative">
      <Popover className="flex">
        {({ open, close }) => (
          <>
            <div className="relative flex">
              <PopoverButton id="profile_button" open={open}>
                <UserCircleIcon className="h-7 w-7 text-gray-400 hover:text-gray-500" />
              </PopoverButton>
            </div>

            <Popover.Overlay className="fixed inset-0 -z-10 bg-black opacity-30" />

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Popover.Panel className="absolute inset-x-0 left-0 top-full z-10 w-full transform sm:left-auto sm:right-0 sm:w-48">
                <div className="overflow-hidden rounded bg-white shadow-lg ring-1 ring-beige-700 ring-opacity-5" />
                <div className="relative bg-white">
                  <div className="mx-auto max-w-7xl border-t">
                    <ul className="flex flex-col">
                      <li className="flex cursor-pointer items-center gap-3 border-b px-4 py-4 hover:bg-beige-500">
                        <Link
                          aria-label="login or register"
                          href="/profile"
                          className="flex h-full w-full items-center gap-3"
                          onClick={() => close()}
                        >
                          <UserIcon className="h-5 w-5 shrink-0" />
                          {t("my_profile")}
                        </Link>
                      </li>
                      {countryData?.referralConfig && (
                        <li className="flex cursor-pointer items-center gap-3 border-b px-4 py-4 hover:bg-beige-500">
                          <Link
                            href="/refer-a-friend"
                            className="flex h-full w-full items-center gap-3"
                            onClick={() => close()}
                          >
                            <CurrencyDollarIcon className="h-5 w-5 shrink-0" />
                            {t("refer_and_earn")}
                          </Link>
                        </li>
                      )}
                      <li>
                        <button
                          className="flex w-full cursor-pointer items-center gap-3 px-4 py-4 hover:bg-beige-500"
                          onClick={handleLogout}
                        >
                          <ArrowRightOnRectangleIcon className="h-5 w-5 shrink-0" />
                          <span>{t("logout")}</span>
                          {loadingLogout && <BlackSpinIcon className="-ms-1 me-3" />}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

export default ProfilePopover;
