import Image from "next/image";
import useRtl from "src/hooks/useRtl";
import ThemeButton from "src/components/button/ThemeButton";
import { useRouter } from "next/router";

interface ErrorProps {
  statusCode?: string;
  title: string;
  subtitle?: string;
  href?: string;
  buttonText?: string;
  hasButton?: boolean;
}
const Error = ({
  title,
  subtitle,
  buttonText,
  href,
  hasButton = true,
}: ErrorProps) => {
  const router = useRouter();
  const rtl = useRtl();

  return (
    <div className="py-10 lg:flex lg:items-center lg:justify-center">
      <div className="space-y-3 pb-6 text-center lg:space-y-4 lg:pb-0">
        <h1 className="lg:title-1 text-xl font-bold">{title}</h1>
        <p className="text-md lg:text-xl">{subtitle}</p>
        {hasButton && (
          <ThemeButton
            className="inline-flex"
            onClick={() => router.push(href ?? "/")}
          >
            {buttonText}{" "}
            {rtl ? (
              <span className="ms-2"> &larr;</span>
            ) : (
              <span className="ms-2"> &rarr;</span>
            )}
          </ThemeButton>
        )}
      </div>
      <div className="lg:w-1/2 lg:shrink-0">
        <Image
          className="max-w-24"
          src={"/images/error-background.jpg"}
          alt="Team Searching"
          width={1080}
          height={1080}
        />
      </div>
    </div>
  );
};

export default Error;
