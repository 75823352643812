export default function setLocalStorage<T>(key: string, value: T) {
	if (typeof window === "undefined") return null;

	try {
		window.localStorage.setItem(key, JSON.stringify(value));
	} catch (error) {
		const message =
			error instanceof Error ? error.message : `Error setting ${key}.`;
		console.log({ message });
	}
}
