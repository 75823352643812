import { Cart } from "src/types/storefront/Cart";
import preciseDecimal from "../preciseDecimal";

export default function getCartDiscountedAmount(cart: Cart): number {
  /**
   * Cart discount
   */
  const cartDiscount = cart.discountAllocations.reduce(
    (sum, current) => (sum += Number(current.discountedAmount.amount)),
    0
  );

  /**
   * Items discount
   */
  let itemsDiscount = 0;
  for (const line of cart.lines) {
    for (const discountAllocation of line.discountAllocations) {
      itemsDiscount += Number(discountAllocation.discountedAmount.amount);
    }
  }

  return preciseDecimal(cartDiscount + itemsDiscount);
}
