import { useState } from "react";
import { H } from "@highlight-run/next/client";
import { Result } from "neverthrow";
import { toast } from "react-toastify";

type ErrorObject = {
  errorMessage?: string;
} & object;

type AsyncNeverthrowFunction = (...params: any[]) => Promise<Result<any, ErrorObject>>;

interface Config {
  uiErrorMessage?: string;
  uiSuccessMessage?: string;
  showToast?: boolean;
  showResultError?: boolean;
  report?: boolean;
}

export default function useNeverthrowAsync<T extends AsyncNeverthrowFunction>(
  asyncFunction: T,
  {
    uiErrorMessage = "The operation wasn't successful",
    uiSuccessMessage = "The operation was successful",
    showToast = true,
    showResultError = false,
    report = true,
  }: Config = {}
) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  async function wrapper(...args: Parameters<T>): Promise<Awaited<Result<any, ErrorObject>>> {
    // reset to initial states
    setIsLoading(true);
    setError(null);

    const result = await asyncFunction(...args);
    if (result.isErr()) {
      const { errorMessage = "Something went wrong" } = result.error;
      setError(errorMessage);
      if (showToast) {
        const messageToShow = showResultError ? errorMessage : uiErrorMessage;
        toast.error(messageToShow, { position: "bottom-right", icon: false });
      }
      if (report) {
        H.consumeError(new Error(errorMessage), "", { payload: JSON.stringify(result.error) });
      }
    } else {
      setError(null);
      if (showToast) {
        toast.success(uiSuccessMessage, { position: "bottom-right", icon: false });
      }
    }

    setIsLoading(false);
    return result;
  }

  const result: [(...args: Parameters<T>) => Promise<Awaited<Result<any, ErrorObject>>>, boolean, string | null] = [
    wrapper,
    isLoading,
    error,
  ];
  return result;
}
