import GoogleAnalyticsEventsEnum from "../../types/enums/googleAnalyticsEvents.enums";
import getOptionValueByName from "../inventory-helpers/getOptionValueByName";
import { Product, SelectedOption, Variant } from "src/types/storefront";
import { CheckoutUserInformation } from "src/context/CheckoutUserInformation";
import { Cart } from "src/types/storefront/Cart";
import { KoalaProduct } from "src/types/koala/KoalaProduct";

class GoogleAnalyticsTracking {
  public constructor() {}

  protected googleAnalyticsTrack(name: string, data: object = {}) {
    if (typeof window !== undefined && window.gtag) {
      window.gtag("event", name, data);
    }
  }

  protected productDetails(product: Product) {
    return {
      item_name: product.title,
      SKU: product.variants[0].sku,
      price: `${product.variants[0].price.amount} ${product.variants[0].price.currencyCode}`,
      environment: process.env.NEXT_PUBLIC_ENV,
    };
  }

  protected koalaProductDetails(product: KoalaProduct) {
    return {
      item_name: product.name,
      SKU: product.variants[0].sku,
      price: `${product.variants[0].price} ${product.variants[0].currency}`,
      environment: process.env.NEXT_PUBLIC_ENV,
    };
  }

  protected variantDetails(productName: string, variant: Variant, selectedOptions: SelectedOption[]) {
    return {
      item_name: productName,
      variant_id: variant.id,
      SKU: variant.sku,
      quantity: variant.quantityAvailable,
      absorption: getOptionValueByName("Absorption", selectedOptions),
      size: getOptionValueByName("Size", selectedOptions),
      environment: process.env.NEXT_PUBLIC_ENV,
    };
  }

  protected checkoutDetails(cart: Cart) {
    const cartItemNames: string[] = [];
    cart.lines.map((item) => {
      cartItemNames.push(item.merchandise.product.title);
    });
    const cartItemIDs: string[] = [];
    cart.lines.map((item) => {
      cartItemIDs.push(item.merchandise.id);
    });

    return {
      environment: process.env.NEXT_PUBLIC_ENV,
      cart_size: cart.lines.length,
      cart_item_IDs: cartItemIDs,
      cart_item_names: cartItemNames,
      value: cart.cost.totalAmount.amount,
      currency: cart.cost.totalAmount.currencyCode,
      tax_amount: cart.cost.totalTaxAmount?.amount,
    };
  }

  protected paymentInfoDetails(cart: Cart, paymentType: string) {
    return {
      ...this.checkoutDetails(cart),
      payment_type: paymentType,
    };
  }

  protected shippingInfoDetails(cart: Cart, userInformation: CheckoutUserInformation) {
    return {
      cart: { ...this.checkoutDetails(cart) },
      user_information: { ...userInformation },
    };
  }

  protected pageViewDetails() {
    return {
      environment: process.env.NEXT_PUBLIC_ENV,
    };
  }

  protected subscribeToNewsletterDetails(emailAddress: string, source: string, discountPercentage: number) {
    return {
      subscribed_email: emailAddress,
      source: source,
      discount_percentage: discountPercentage,
    };
  }

  protected sentEmailFromDetails(emailAddress: string) {
    return {
      contact_email: emailAddress,
    };
  }

  public pageViewed() {
    this.googleAnalyticsTrack(GoogleAnalyticsEventsEnum.PAGE_VIEW, this.pageViewDetails());
  }

  public visit() {
    this.googleAnalyticsTrack(GoogleAnalyticsEventsEnum.VISIT, this.pageViewDetails());
  }

  public viewCheckoutPage(cart: Cart) {
    this.googleAnalyticsTrack(GoogleAnalyticsEventsEnum.VIEW_CHECKOUT_PAGE, this.checkoutDetails(cart));
  }

  public subscribeToNewsletter(emailAddress: string, source: string, discountPercentage: number) {
    this.googleAnalyticsTrack(
      GoogleAnalyticsEventsEnum.SUBSCRIBED_NEWSLETTER,
      this.subscribeToNewsletterDetails(emailAddress, source, discountPercentage)
    );
  }

  public sentEmailFrom(emailAddress: string) {
    this.googleAnalyticsTrack(GoogleAnalyticsEventsEnum.SENT_EMAIL_USING_FORM, this.sentEmailFromDetails(emailAddress));
  }

  public productViewed(product: Product) {
    this.googleAnalyticsTrack(GoogleAnalyticsEventsEnum.VIEW_ITEM_DETAIL_PAGE, this.productDetails(product));
  }

  public koalaProductViewed(product: KoalaProduct) {
    this.googleAnalyticsTrack(GoogleAnalyticsEventsEnum.VIEW_ITEM_DETAIL_PAGE, this.koalaProductDetails(product));
  }

  public addedToCart(product: Product) {
    this.googleAnalyticsTrack(GoogleAnalyticsEventsEnum.ADD_ITEM_TO_CART, this.productDetails(product));
  }

  public addedToKoalaCart(product: KoalaProduct) {
    this.googleAnalyticsTrack(GoogleAnalyticsEventsEnum.ADD_ITEM_TO_CART, this.koalaProductDetails(product));
  }

  public removedFromCart(product: Product) {
    this.googleAnalyticsTrack(GoogleAnalyticsEventsEnum.REMOVE_ITEM_FROM_CART, this.productDetails(product));
  }

  public addPaymentInfo(cart: Cart, paymentType: string) {
    this.googleAnalyticsTrack(GoogleAnalyticsEventsEnum.ADD_PAYMENT_INFO, this.paymentInfoDetails(cart, paymentType));
  }

  public addShippingInfo(cart: Cart, userInformation: CheckoutUserInformation) {
    this.googleAnalyticsTrack(
      GoogleAnalyticsEventsEnum.ADD_SHIPPING_INFO,
      this.shippingInfoDetails(cart, userInformation)
    );
  }

  public generateLead(cart: Cart) {
    this.googleAnalyticsTrack(GoogleAnalyticsEventsEnum.GENERATE_LEAD, this.checkoutDetails(cart));
  }

  public clickOnVideo(videoName: string) {
    this.googleAnalyticsTrack(GoogleAnalyticsEventsEnum.VIDEO_PLAY, {
      video_name: videoName,
    });
  }

  public variantChanged(productName: string, variant: Variant, selectedOptions: SelectedOption[]) {
    this.googleAnalyticsTrack(
      GoogleAnalyticsEventsEnum.CHANGE_VARIANT,
      this.variantDetails(productName, variant, selectedOptions)
    );
  }
}

const googleAnalyticsTracker = new GoogleAnalyticsTracking();
export default googleAnalyticsTracker;
