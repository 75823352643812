import gql from "src/helpers/gql";
import storefront from "../storefront";
import { CountryCode } from "src/types/localization/CountryCode";

const query = gql`
  query checkoutURL($cartId: ID!) {
    cart(id: $cartId) {
      checkoutUrl
    }
  }
`;

interface CreateCheckoutResponse {
  data: {
    cart: {
      checkoutUrl: string;
    };
  };
}

export default async function createCheckout(
  countryCode: CountryCode,
  cartId: string
): Promise<string> {
  {
    const createCheckoutResponse: CreateCheckoutResponse = await storefront(
      countryCode,
      query,
      { cartId }
    );

    return createCheckoutResponse.data.cart.checkoutUrl;
  }
}
