import { H } from "@highlight-run/next/client";

export default function highlightError(
  error: Error,
  message?: string | undefined,
  payload?:
    | {
        [key: string]: string;
      }
    | undefined
) {
  H.consumeError(error, message, payload);
  H.track(message || error.message, payload);
}
