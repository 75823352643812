import { useTranslations } from "next-intl";
import Image from "next/image";
import { useEffect, useState } from "react";
import consts from "src/config/consts";
import classNames from "src/helpers/classNames";
import { useMediaQuery } from "usehooks-ts";

type Props = {};

function ChristmaTopBar({}: Props) {
  const isLargeScreen = useMediaQuery("(min-width: 640px)");
  const t = useTranslations("ChristmasTopBar");
  const [animationDuration, setAnimationDuration] = useState(7);

  useEffect(() => {
    setAnimationDuration(isLargeScreen ? 10 : 7);
  }, [isLargeScreen]);

  return (
    // aspect-[375/50] sm:aspect-[1507/50]
    <div
      className="relative flex items-start justify-center overflow-hidden pt-3"
      style={{ height: consts.CHRISTMAS_TOPBAR_HEIGHT_IN_PX + "px" }}
    >
      {/* Wave */}
      <Image
        className="absolute left-0 top-0 h-full w-full object-cover object-bottom"
        src="/images/christmas/wave-lg.svg"
        alt={""}
        width={0}
        height={0}
        priority
      />

      {/* deers */}
      {/* animate-sleigh-travel */}
      <div
        className="absolute right-0 aspect-[254/29] h-7 animate-sleigh-travel"
        style={{ animationDuration: animationDuration + "s" }}
      >
        <Image
          className={classNames("absolute h-full w-auto animate-sleigh-walk")}
          src={`/images/christmas/merry-christmas-1.svg`}
          alt=""
          width={0}
          height={0}
          priority
        />
        <Image
          className={classNames("absolute h-full w-auto animate-sleigh-walk2")}
          src={`/images/christmas/merry-christmas-2.svg`}
          alt=""
          width={0}
          height={0}
          priority
        />
      </div>

      {/* text */}
      <div className="relative flex animate-leafs-appear items-center gap-1 text-sm sm:text-base">
        <Image
          className="w-8"
          src={"/images/christmas/christmas-leafs.svg"}
          alt={""}
          width={0}
          height={0}
        />
        <p>
          {t.rich("christmas_offers", {
            b: (value) => <b>{value}</b>,
          })}
        </p>
        <Image
          className="w-8"
          src={"/images/christmas/christmas-leafs-reversed.svg"}
          alt={""}
          width={0}
          height={0}
        />
      </div>
    </div>
  );
}

export default ChristmaTopBar;
