import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import getLocalStorage from "src/helpers/local-storage/getLocalStorage";
import useCountryCode from "src/hooks/useCountryCode";
import { RegisteringShippingAddress } from "src/types/shopify-admin";
import { CountryCode } from "src/types/localization/CountryCode";
import {
  AuthenticationContext,
  AuthenticationContextProps,
} from "./authentication";
import { DBUser } from "src/types/database/DBUser";

export interface CheckoutUserInformation {
  email: string;
  shipping_address: RegisteringShippingAddress;
}

export interface CheckoutUserInformationContextProps {
  checkoutUserInformation: CheckoutUserInformation | null;
  setCheckoutUserInformation: Dispatch<
    SetStateAction<CheckoutUserInformation | null>
  >;
}

export const CheckoutUserInformationContext =
  createContext<CheckoutUserInformationContextProps | null>(null);

interface CheckoutUserInformationProviderProps {
  children: ReactNode;
}

function setCheckoutUserInformationfromStorage(
  countryCode: CountryCode,
  setCheckoutUserInformation: Dispatch<
    SetStateAction<CheckoutUserInformation | null>
  >
) {
  const storageCheckoutUserInformation: CheckoutUserInformation | null =
    getLocalStorage(`user_information_${countryCode}`);

  if (storageCheckoutUserInformation) {
    setCheckoutUserInformation(storageCheckoutUserInformation);
  } else {
    setCheckoutUserInformation(null);
  }
}

function mapUserInfoToCheckoutUserInformation(
  userInfo: DBUser,
  setCheckoutUserInformation: Dispatch<
    SetStateAction<CheckoutUserInformation | null>
  >
) {
  setCheckoutUserInformation((prev) => {
    if (prev) {
      return prev;
    }

    const newCheckoutUserInfo: CheckoutUserInformation = {
      email: userInfo.email,
      shipping_address: {
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
      },
    };

    return newCheckoutUserInfo;
  });
}

export default function CheckoutUserInformationProvider({
  children,
}: CheckoutUserInformationProviderProps) {
  const countryCode = useCountryCode();

  const { user } = useContext(
    AuthenticationContext
  ) as AuthenticationContextProps;

  const [checkoutUserInformation, setCheckoutUserInformation] =
    useState<CheckoutUserInformation | null>(null);

  /**
   * Effects
   */
  useEffect(() => {
    if (countryCode) {
      setCheckoutUserInformationfromStorage(
        countryCode,
        setCheckoutUserInformation
      );
    }
  }, [countryCode]);

  useEffect(() => {
    if (user) {
      mapUserInfoToCheckoutUserInformation(user, setCheckoutUserInformation);
    }
  }, [user]);

  return (
    <CheckoutUserInformationContext.Provider
      value={{ checkoutUserInformation, setCheckoutUserInformation }}
    >
      {children}
    </CheckoutUserInformationContext.Provider>
  );
}
