import KoalaProductItem from "src/components/product-item/KoalaProductItem";
import classNames from "src/helpers/classNames";
import { KoalaProduct } from "src/types/koala/KoalaProduct";

type Props = {
  products: KoalaProduct[];
  isMobileMenu?: boolean;
  onProductClick?: Function;
  className?: string;
  imageClassName?: string;
};

function KoalaProductsList({
  products,
  isMobileMenu = false,
  onProductClick = () => {},
  className = "",
  imageClassName = "",
}: Props) {
  return (
    <ul className={classNames("grid grid-cols-2 gap-x-3 gap-y-6 lg:grid-cols-4 lg:gap-x-4", className)}>
      {products.map((product) => (
        <li key={product.name} className="product">
          <KoalaProductItem
            onClick={onProductClick}
            product={product}
            isMobileMenu={isMobileMenu}
            imageClassName={imageClassName}
          />
        </li>
      ))}
    </ul>
  );
}

export default KoalaProductsList;
