import { GrowthBook } from "@growthbook/growthbook";
import EventTracker from "../event-tracking/eventTrackingManager";
import getLocalStorage from "../local-storage/getLocalStorage";
import ApiClient from "../api-client/apiClient";
import { GrowthbookResponse } from "src/types/growthbook/growthbook";

const growthbook = new GrowthBook({
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    EventTracker.experimentStarted(
      experiment.key,
      experiment.key,
      result.value,
      "growthbook"
    );
  },
});

const apiClient = new ApiClient();

export default async function initializeGrowthbookClient(
  visitor_id: string
): Promise<GrowthBook> {
  const result = await apiClient.get<GrowthbookResponse>(
    `https://cdn.growthbook.io/api/features/${process.env.NEXT_PUBLIC_GROWTHBOOK}`
  );

  if (result.isErr()) {
    throw new Error(result.error.errorMessage);
  }

  growthbook.setFeatures(result.value.features);

  growthbook.setAttributes({
    id: visitor_id,
    country: getLocalStorage("country_code"),
  });
  return growthbook;
}
