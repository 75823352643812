import useCountryData from "./useCountryData";
import { useStoreState } from "./storeHooks";
import { KoalaOption, KoalaProduct } from "src/types/koala/KoalaProduct";
import koalaUtils from "src/helpers/koala/koalaUtils";

export default function useKoalaProducts(collectionIds?: string[], filters?: KoalaOption[]) {
  const products = useStoreState((state) => state.products);
  const countryData = useCountryData();

  let displayedProducts: KoalaProduct[] = products;
  // 1. apply filters
  if (filters) {
    displayedProducts = koalaUtils.getFilteredProducts(products, filters);
  }

  // 2. sort products
  if (countryData) {
    displayedProducts = koalaUtils.sortProductsByCollection(products, [
      countryData.koalaCollections.COLLECTION_LADIES.id,
      countryData.koalaCollections.COLLECTION_TEENS.id,
      countryData.koalaCollections.COLLECTION_UNDERWEARS.id,
      countryData.koalaCollections.COLLECTION_ACCESSORIES.id,
    ]);
  }

  if (collectionIds) {
    displayedProducts = koalaUtils.getProductsByCollectionIds(products, collectionIds);
  }

  return products;
}
