import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, memo, ReactNode, useRef } from "react";
import classNames from "src/helpers/classNames";

type ModalProps = {
  id?: string;
  isOpen: boolean;
  onClose(): void;
  children: ReactNode;
  panelClassName?: string;
};

function Modal({
  id = "",
  isOpen,
  onClose,
  children,
  panelClassName = "",
}: ModalProps) {
  const buttonRef = useRef<HTMLAnchorElement | null>(null);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        onClose={onClose}
        initialFocus={buttonRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                as="div"
                id={id}
                className={classNames(
                  "relative flex w-full max-w-md transform flex-col overflow-hidden rounded-md bg-white align-middle shadow-xl transition-all",
                  panelClassName
                )}
              >
                <button
                  id="modal__close-button"
                  className="absolute top-2 right-2 p-2"
                  type="button"
                  onClick={onClose}
                >
                  <XMarkIcon className="w-6" />
                </button>

                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default memo(Modal);
