import useCountryData from "./useCountryData";
import { KoalaProduct } from "src/types/koala/KoalaProduct";
import { property } from "lodash";
import koalaUtils from "src/helpers/koala/koalaUtils";
import useKoalaProducts from "./useKoalaProducts";

export default function useMainKoalaProducts() {
  const allProducts = useKoalaProducts();
  const countryData = useCountryData();

  let mainProducts: KoalaProduct[] = [];
  if (countryData) {
    const defaultCollection = Object.values(countryData.koalaCollections).find(property("isDefault"));
    if (defaultCollection) {
      mainProducts = koalaUtils.getProductsByCollectionIds(allProducts, [defaultCollection.id]);
    }
  }

  return mainProducts;
}
