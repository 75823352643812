import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Image from "next/image";
import Link from "next/link";
import { Fragment, memo, ReactNode, useRef } from "react";
import ThemeLink from "src/components/link/ThemeLink";
import classNames from "src/helpers/classNames";

type ModalProps = {
  id?: string;
  isOpen: boolean;
  title: string;
  subtitle?: string;
  buttonLabel: string;
  href: string;
  icon?: string;
  iconClassName?: string;
  onClose(): void;
  onClick?: () => void;
};

function Modal({
  id = "",
  isOpen,
  title,
  buttonLabel,
  subtitle,
  href,
  icon,
  iconClassName = "",
  onClick,
  onClose,
}: ModalProps) {
  const buttonRef = useRef<HTMLAnchorElement | null>(null);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        onClose={onClose}
        initialFocus={buttonRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                as="div"
                id={id}
                className="relative flex w-full max-w-md transform flex-col overflow-hidden rounded-md bg-white p-6 align-middle shadow-xl transition-all"
              >
                <button
                  id="modal__close-button"
                  className="absolute top-3 right-3 p-2"
                  type="button"
                  onClick={onClose}
                >
                  <XMarkIcon className="w-8" />
                </button>
                {icon && (
                  <Image
                    className={classNames(
                      "mb-6 h-20 w-20 self-center",
                      iconClassName
                    )}
                    src={icon}
                    alt=""
                    width={0}
                    height={0}
                    sizes="100px"
                  />
                )}
                <Dialog.Title
                  as="h3"
                  className="text-center text-2xl font-bold leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
                {subtitle && (
                  <div className="mt-2">
                    <p className="text-center text-sm font-semibold text-gray-600">
                      {subtitle}
                    </p>
                  </div>
                )}

                <div className="mt-6">
                  <Link href={href} passHref legacyBehavior>
                    <ThemeLink onClick={onClick} ref={buttonRef}>
                      {buttonLabel}
                    </ThemeLink>
                  </Link>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default memo(Modal);
