import mixpanel from "mixpanel-browser";
import MixpanelEventsEnum from "../../types/enums/mixpanelEvents.enums";
import dayjs from "dayjs";
import getOptionValueByName from "../inventory-helpers/getOptionValueByName";
import { Product, SelectedOption, Variant } from "src/types/storefront";
import { Cart } from "src/types/storefront/Cart";
import { CheckoutUserInformation } from "src/context/CheckoutUserInformation";
import { SurveyData } from "src/types/dr-shemsi/DrShemsiSurvey";
import { ChatLog } from "src/types/dr-shemsi/ChatLog";
import { WaitingListData } from "src/types/dr-shemsi/WaitingListData";
import { DBUser } from "src/types/database/DBUser";
import { BundleSource } from "src/types/bundle-discounts/BundleSource";
import Cookies from "js-cookie";
import flattenCartLineItems from "../cart/flattenCartLineItems";
import { KoalaProduct } from "src/types/koala/KoalaProduct";
import { KoalaCart } from "src/types/koala/KoalaCart";
import koalaUtils from "../koala/koalaUtils";

class MixpanelTracking {
  public constructor() {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_ID || "", {
      debug: true,
      ignore_dnt: true,
    });
  }

  protected mixpanelTrack(name: string, data: object = {}) {
    mixpanel.track(name, data);
  }

  protected experimentDetails(experimentName: string, experiment_id: string, variantName: number, $source: string) {
    return {
      "Experiment name": experimentName,
      experiment_id: experiment_id,
      "Variant name": variantName,
      $source: $source,
    };
  }

  protected productDetails(product: Product) {
    return {
      "Item Name": product.title,
      SKU: product.variants[0].sku,
      "Item Cost": `${product.variants[0].price.amount} ${product.variants[0].price.currencyCode}`,
      Environment: process.env.NEXT_PUBLIC_ENV,
    };
  }

  protected koalaProductDetails(product: KoalaProduct) {
    return {
      "Item Name": product.name,
      SKU: product.variants[0].sku,
      "Item Cost": `${product.variants[0].price} ${product.variants[0].currency}`,
      Environment: process.env.NEXT_PUBLIC_ENV,
    };
  }

  protected variantDetails(productName: string, variant: Variant, selectedOptions: SelectedOption[]) {
    return {
      "Item Name": productName,
      "Variant ID": variant.id,
      SKU: variant.sku,
      Quantity: variant.quantityAvailable,
      Absorption: getOptionValueByName("Absorption", selectedOptions),
      Size: getOptionValueByName("Size", selectedOptions),
      Environment: process.env.NEXT_PUBLIC_ENV,
    };
  }

  protected checkoutDetails(cart: Cart) {
    const lineItems = flattenCartLineItems(cart);
    const cartItemNames = cart.lines.map((item) => item.merchandise.product.title);
    const cartItemIDs = cart.lines.map((item) => {
      return item.merchandise.id;
    });

    return {
      Environment: process.env.NEXT_PUBLIC_ENV,
      "Cart Size": lineItems.length,
      "cart Item IDs": cartItemIDs,
      "cart Item Names": cartItemNames,
      "Estimated Total Amount": cart.cost.totalAmount.amount,
      "Tax Amount": cart.cost.totalTaxAmount?.amount || "0",
    };
  }

  protected koalaCheckoutDetails(cart: KoalaCart) {
    const lineItems = koalaUtils.flattenCartLineItems(cart.lineItems);
    const cartItemNames = cart.lineItems.map((item) => item.name);
    const cartItemIDs = cart.lineItems.map((item) => item._id);

    return {
      Environment: process.env.NEXT_PUBLIC_ENV,
      "Cart Size": lineItems.length,
      "cart Item IDs": cartItemIDs,
      "cart Item Names": cartItemNames,
      "Estimated Total Amount": cart.totalPrice,
      "Tax Amount": "0",
    };
  }

  protected pageViewDetails() {
    ``;
    return {
      Environment: process.env.NEXT_PUBLIC_ENV,
    };
  }

  protected paymentInfoDetails(cart: Cart, paymentType: string) {
    return {
      ...this.checkoutDetails(cart),
      "Payment Type": paymentType,
    };
  }

  protected shippingInfoDetails(cart: Cart, userInformation: CheckoutUserInformation) {
    return {
      cart: { ...this.checkoutDetails(cart) },
      "User Information": { ...userInformation },
    };
  }

  protected errorDetails(sentryEventID: string, errorType: string, errorValue: string, requestUrl: string) {
    return {
      Environment: process.env.NEXT_PUBLIC_ENV,
      "Date And Time": dayjs().format("DD/MM/YYYY HH:mm"),
      "Error Message": errorValue,
      "Error Type": errorType,
      "Sentry Event ID": sentryEventID,
      "Request URL": requestUrl,
    };
  }

  protected subscribeToNewsletterDetails(emailAddress: string, source: string, discountPercentage: number) {
    return {
      "Subscribed E-mail": emailAddress,
      Source: source,
      "Discount Percentage": discountPercentage,
    };
  }

  protected copyReferralLinkDetails(user: DBUser, referralLink: string, page: string) {
    return {
      "E-mail": user.email,
      "Referral Link": referralLink,
      Page: page,
    };
  }

  protected shareReferralLinkDetails(user: DBUser, referralLink: string, socialApp: string, page: string) {
    return {
      "E-mail": user.email,
      "Referral Link": referralLink,
      "Shared via": socialApp,
      Page: page,
    };
  }

  protected bundleDetails(cart: Cart, source: BundleSource) {
    return {
      ...this.checkoutDetails(cart),
      Source: source,
    };
  }

  protected koalaBundleDetails(cart: KoalaCart, source: BundleSource) {
    return {
      ...this.koalaCheckoutDetails(cart),
      Source: source,
    };
  }

  protected sentEmailFromDetails(emailAddress: string) {
    return {
      "Contact E-mail": emailAddress,
    };
  }

  // protected purchaseDetails (cartSize: number, cartItemIDs: string[],cartItemNames: string[],taxAmount:number, totalPrice: number, currency: string) {
  //   return {
  //     "Environment": process.env.NEXT_PUBLIC_ENV,
  //     "Cart Size": cartSize,
  //     "Cart Item IDs": cartItemIDs,
  //     "Cart Item Names": cartItemNames,
  //     "Estimated Total Amount": totalPrice,
  //     "Tax Amount": taxAmount,
  //     "Currency": currency
  //   }
  // }
  protected purchaseDetails(totalPrice: number, currency: string) {
    return {
      Environment: process.env.NEXT_PUBLIC_ENV,
      // "Cart Size": cartSize,
      "Estimated Total Amount": totalPrice,
      // "Tax Amount": taxAmount,
      Currency: currency,
    };
  }

  protected setUserDataDetails(userInformation: CheckoutUserInformation) {
    const visitor_id = Cookies.get("visitor_id");

    const fullName = `${userInformation.shipping_address?.first_name} ${userInformation.shipping_address?.last_name}`;
    let userPhone = "";
    // NOTE: when we need to this code to run in different countries we need to add their international phone configurations
    if (userInformation.shipping_address?.country === "Egypt") {
      if (userInformation.shipping_address?.phone?.substring(0, 1) === "+2") {
        userPhone = userInformation.shipping_address?.phone;
      } else {
        userPhone = "+2" + userInformation.shipping_address?.phone;
      }
    } else {
      userPhone = userInformation.shipping_address?.phone || "";
    }
    if (userInformation.shipping_address?.country === "Morocco") {
      if (userInformation.shipping_address?.phone?.substring(0, 1) === "+212") {
        userPhone = userInformation.shipping_address?.phone;
      } else {
        userPhone = "+212" + userInformation.shipping_address?.phone;
      }
    } else {
      userPhone = userInformation.shipping_address?.phone || "";
    }
    // mixpanel.identify(userPhone);
    // mixpanel.identify(userInformation.email);
    mixpanel.identify(visitor_id);
    mixpanel.people.set({
      $created: dayjs().format("DD/MM/YYYY HH:mm"),
      $email: userInformation.email,
      $name: fullName,
      $phone: userPhone,
      $country_code: userInformation.shipping_address?.country,
      $region: userInformation.shipping_address?.province,
      $city: userInformation.shipping_address?.city,
      $distinct_id: visitor_id,
      Address: userInformation.shipping_address?.address1,
    });
  }

  protected drShemsiSurveyDetails(surveyData: SurveyData) {
    return {
      "AI Age Range": surveyData?.ageRange,
      "AI Period Status": surveyData?.periodStatus,
      "AI Cycle Duration": surveyData?.cycleDuration,
      "AI Period Duration": surveyData?.periodDuration,
      "AI Pregnancy": surveyData?.pregnancy,
      "AI Status": surveyData?.status,
    };
  }

  protected drShemiQuestionDetails(surveyData: SurveyData, chatLog: ChatLog[]) {
    return {
      "AI Age Range": surveyData?.ageRange,
      "AI Period Status": surveyData?.periodStatus,
      "AI Cycle Duration": surveyData?.cycleDuration,
      "AI Period Duration": surveyData?.periodDuration,
      "AI Pregnancy": surveyData?.pregnancy,
      "AI Status": surveyData?.status,
      "AI Chat Log": chatLog,
    };
  }

  protected drShemsiWaitingListDetails(waitingListData: WaitingListData) {
    return {
      "AI Waiting List Id": waitingListData.id,
      "AI Full Name": waitingListData.full_name,
      "AI Email": waitingListData.email,
      "AI Phone": waitingListData.phone,
      "AI Country Code": waitingListData.country_code,
      "AI Age Range": waitingListData.age_range,
      "AI Marital Status": waitingListData.marital_status,
      "AI Period Status": waitingListData.period_status,
      "AI Pregnancy": waitingListData.pregnancy,
      "AI Period Duration": waitingListData.period_duration,
      "AI Cycle Duration": waitingListData.cycle_duration,
      "AI User Questions": waitingListData.user_questions,
    };
  }

  public experimentStarted(experimentName: string, experiment_id: string, variantName: number, $source: string) {
    this.mixpanelTrack(
      MixpanelEventsEnum.EXPERIMENT_START,
      this.experimentDetails(experimentName, experiment_id, variantName, $source)
    );
  }

  public pageViewed() {
    this.mixpanelTrack(MixpanelEventsEnum.PAGE_VIEW, this.pageViewDetails());
  }

  public visit() {
    const visitor_id = Cookies.get("visitor_id");
    mixpanel.identify(visitor_id);
    this.mixpanelTrack(MixpanelEventsEnum.VISIT, this.pageViewDetails());
  }

  public error(sentryEventID: string, errorType: string, errorValue: string, requestUrl: string) {
    this.mixpanelTrack(MixpanelEventsEnum.ERROR, this.errorDetails(sentryEventID, errorType, errorValue, requestUrl));
  }

  public viewCheckoutPage(cart: Cart) {
    this.mixpanelTrack(MixpanelEventsEnum.VIEW_CHECKOUT_PAGE, this.checkoutDetails(cart));
  }

  public subscribeToNewsletter(emailAddress: string, source: string, discountPercentage: number) {
    this.mixpanelTrack(
      MixpanelEventsEnum.SUBSCRIBED_NEWSLETTER,
      this.subscribeToNewsletterDetails(emailAddress, source, discountPercentage)
    );
  }

  public copyReferralLink(user: DBUser, referralLink: string, page: string) {
    this.mixpanelTrack(MixpanelEventsEnum.COPY_REFERRAL_LINK, this.copyReferralLinkDetails(user, referralLink, page));
  }

  public shareReferralLink(user: DBUser, referralLink: string, socialApp: string, page: string) {
    this.mixpanelTrack(
      MixpanelEventsEnum.SHARE_REFERRAL_LINK,
      this.shareReferralLinkDetails(user, referralLink, socialApp, page)
    );
  }

  public sentEmailFrom(emailAddress: string) {
    this.mixpanelTrack(MixpanelEventsEnum.SENT_EMAIL_USING_FORM, this.sentEmailFromDetails(emailAddress));
  }

  public setUserData(userInformation: CheckoutUserInformation) {
    this.setUserDataDetails(userInformation);
  }
  // public completePurchase(cart: Cart, checkout: Checkout) {
  //   this.mixpanelTrack(MixpanelEventsEnum.COMPLETE_PURCHASE, this.checkoutDetails(cart));
  //   this.setUserDataDetails(checkout);
  // }

  // "Order Name": order.order_name || order.name,
  // "Cart Size": order.line_items.length,
  // "Total Price": order.total_price,
  // Currency: order.currency,
  // public purchase(cartSize: number, cartItemIDs: string[],cartItemNames: string[],taxAmount:number, totalPrice: number, currency: string) {
  //   this.mixpanelTrack(MixpanelEventsEnum.COMPLETE_PURCHASE, this.purchaseDetails(cartSize, cartItemIDs, cartItemNames, taxAmount, totalPrice, currency));
  // }
  public purchase(totalPrice: number, currency: string) {
    this.mixpanelTrack(MixpanelEventsEnum.COMPLETE_PURCHASE, this.purchaseDetails(totalPrice, currency));
    console.log(
      "event and paramenters from mixpanel tracking",
      MixpanelEventsEnum.COMPLETE_PURCHASE,
      totalPrice,
      currency
    );
  }

  public productViewed(product: Product) {
    this.mixpanelTrack(MixpanelEventsEnum.VIEW_ITEM_DETAIL_PAGE, this.productDetails(product));
  }

  public koalaProductViewed(product: KoalaProduct) {
    this.mixpanelTrack(MixpanelEventsEnum.VIEW_ITEM_DETAIL_PAGE, this.koalaProductDetails(product));
  }

  public addedToCart(product: Product) {
    this.mixpanelTrack(MixpanelEventsEnum.ADD_ITEM_TO_CART, this.productDetails(product));
  }

  public addedToKoalaCart(product: KoalaProduct) {
    this.mixpanelTrack(MixpanelEventsEnum.ADD_ITEM_TO_CART, this.koalaProductDetails(product));
  }

  public createBundle(cart: Cart, source: BundleSource) {
    this.mixpanelTrack(MixpanelEventsEnum.CREATE_BUNDLE, this.bundleDetails(cart, source));
  }

  public createKoalaBundle(cart: KoalaCart, source: BundleSource) {
    this.mixpanelTrack(MixpanelEventsEnum.CREATE_BUNDLE, this.koalaBundleDetails(cart, source));
  }

  public clickOnVideo(videoName: string) {
    this.mixpanelTrack(MixpanelEventsEnum.VIDEO_PLAY, {
      "Video Name": videoName,
    });
  }

  public addPaymentInfo(cart: Cart, paymentType: string) {
    this.mixpanelTrack(MixpanelEventsEnum.ADD_PAYMENT_INFO, this.paymentInfoDetails(cart, paymentType));
  }

  public addShippingInfo(cart: Cart, userInformation: CheckoutUserInformation) {
    this.mixpanelTrack(MixpanelEventsEnum.ADD_SHIPPING_INFO, this.shippingInfoDetails(cart, userInformation));
  }

  public variantChanged(productName: string, variant: Variant, selectedOptions: SelectedOption[]) {
    this.mixpanelTrack(MixpanelEventsEnum.CHANGE_VARIANT, this.variantDetails(productName, variant, selectedOptions));
  }

  public visitDrShemsi() {
    this.mixpanelTrack(MixpanelEventsEnum.VISIT_DR_SHEMSI, {});
  }

  public drShemsiSurveySubmission(surveyData: SurveyData) {
    this.mixpanelTrack(MixpanelEventsEnum.DR_SHEMSI_SURVEY_SUBMISSION, this.drShemsiSurveyDetails(surveyData));
  }

  public askDrShemsi(surveyData: SurveyData, chatLog: ChatLog[]) {
    this.mixpanelTrack(MixpanelEventsEnum.ASK_DR_SHEMSI, this.drShemiQuestionDetails(surveyData, chatLog));
  }

  public joinDrShemsiWaitingList(waitingListData: WaitingListData) {
    this.mixpanelTrack(
      MixpanelEventsEnum.JOIN_DR_SHEMSI_WAITING_LIST,
      this.drShemsiWaitingListDetails(waitingListData)
    );
  }
}

const mixpanelTracker = new MixpanelTracking();
export default mixpanelTracker;
