import { useEffect, useState } from "react";
import classNames from "src/helpers/classNames";
import useCountryData from "src/hooks/useCountryData";
import { CollectionConfig } from "src/helpers/localization-helpers/countryClass";
import highlightError from "src/helpers/highlight/highlightError";
import useProducts from "src/hooks/useProducts";
import ProductsList from "src/sections/products-list/ProductsList";
import { Product } from "src/types/storefront";
import getProductsByCollectionIds from "src/helpers/storefront-helpers/getProductsByCollectionIds";
import { useTranslations } from "next-intl";
import { Filter } from "src/types/filters";
import getFilteredProducts from "src/helpers/filtering-helpers/getFilteredProducts";

interface MultiCollectionViewerProps {
  passedCollections?: CollectionConfig[];
  passedActiveCollection?: CollectionConfig;
  onCollectionChange?: (collection: CollectionConfig) => void;
  filters?: Filter[];
  singleRow?: boolean;
  imageClassName?: string;
  onProductClick?: Function;
}

export default function MultiCollectionViewer({
  passedCollections,
  passedActiveCollection,
  onCollectionChange,
  filters,
  singleRow = false,
  imageClassName = "",
  onProductClick = () => {},
}: MultiCollectionViewerProps) {
  // Dependencies
  const t = useTranslations("MultiCollectionViewer");
  const countryData = useCountryData();
  const products = useProducts();
  let listedCollections: CollectionConfig[] = [];
  if (passedCollections) {
    listedCollections = passedCollections;
  } else if (countryData) {
    listedCollections = Object.values(countryData.collections).filter(
      (collection) => collection.isActive && !collection.hasSeparateList
    );
  }

  // States
  const [activeCollection, setActiveCollection] =
    useState<CollectionConfig | null>(passedActiveCollection || null);

  // effects
  useEffect(
    function setInitalActiveCollection() {
      if (passedActiveCollection) return;
      if (countryData && listedCollections.length > 0) {
        setActiveCollection(listedCollections[0]);
      } else {
        highlightError({ name: "UI Error", message: "Products list is empty" });
      }
    },
    [countryData, passedActiveCollection]
  );

  useEffect(
    function syncPassedActiveCollection() {
      if (passedActiveCollection) {
        setActiveCollection(passedActiveCollection);
      }
    },
    [passedActiveCollection]
  );

  // Calculations
  let activeCollectionProducts: Product[] = [];
  if (activeCollection) {
    activeCollectionProducts = getProductsByCollectionIds(products, [
      activeCollection.id,
    ]);
  }
  if (filters) {
    activeCollectionProducts = getFilteredProducts(
      activeCollectionProducts,
      filters
    );
  }
  const listHas5OrMore = listedCollections.some(
    (collection) =>
      getProductsByCollectionIds(products, [collection.id]).length > 4
  );

  let menu = <></>;
  if (listedCollections.length > 1) {
    menu = (
      <ul className="flex gap-2">
        {listedCollections.map((collection) => (
          <li key={collection.id}>
            <button
              className={classNames(
                "p-2 font-medium",
                activeCollection?.id === collection.id ? "text-beige-800" : ""
              )}
              type="button"
              onClick={
                onCollectionChange
                  ? () => onCollectionChange(collection)
                  : () => setActiveCollection(collection)
              }
            >
              {t(collection.name)}
            </button>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex items-center gap-8">
        <h2 className="title-1">{t("period_underwear")}</h2>
        {menu}
      </div>
      <ProductsList
        products={activeCollectionProducts}
        className={singleRow && listHas5OrMore ? "lg:grid-cols-5" : ""}
        imageClassName={imageClassName}
        onProductClick={onProductClick}
      />
    </div>
  );
}
