import { createHash } from "crypto";
import SnapchatPixelEventsEnum from "src/types/enums/snapchatPixelEvents.enums";
import { Product } from "src/types/storefront";
import getLocalStorage from "../local-storage/getLocalStorage";
import { KoalaProduct } from "src/types/koala/KoalaProduct";
declare global {
  interface Window {
    snaptr: any;
  }
}
const snapchatClickId = getLocalStorage("ScCid") || "";
const snapchatPixelId = process.env.NEXT_PUBLIC_SNAPCHAT_PIXEL_ID || "";
const countryCode = getLocalStorage("country_code");
let userInformation;
if (countryCode) {
  userInformation = getLocalStorage(`user_information_${countryCode}`);
}

const userEmail = userInformation?.email || "user@shemsi.com";
const trimmedEmail = userEmail.trim().toLowerCase() || "";
const hashedEmail = trimmedEmail ? createHash("sha256").update(trimmedEmail).digest("hex") : "";
const userPhone = userInformation?.shipping_address?.phone || "";
const trimmedPhone = userPhone.trim().toLowerCase() || "";
const hashedPhone = trimmedPhone ? createHash("sha256").update(trimmedPhone).digest("hex") : "";

class SnapchatPixelTracking {
  public constructor() {}

  protected snapchatPixelTrack(name: string, data: object = {}) {
    if (typeof window !== undefined && window.snaptr) {
      window.snaptr("init", snapchatPixelId, {
        user_email: userEmail,
      });
      window.snaptr("track", name, data);
    }
  }

  protected productDetails(product: Product) {
    const variantsIds = product.variants.map((variant) => variant.id);
    const data = {
      item_category: product.id,
      item_ids: variantsIds,
      click_id: snapchatClickId,
      hashed_email: hashedEmail,
      hashed_phone: hashedPhone,
    };
    return data;
  }

  protected koalaProductDetails(product: KoalaProduct) {
    const variantsIds = product.variants.map((variant) => variant._id);
    const data = {
      item_category: product._id,
      item_ids: variantsIds,
      click_id: snapchatClickId,
      hashed_email: hashedEmail,
      hashed_phone: hashedPhone,
    };
    return data;
  }

  protected pageViewDetails() {
    const data = {
      click_id: snapchatClickId,
      hashed_email: hashedEmail,
      hashed_phone: hashedPhone,
    };
    return data;
  }

  public pageViewed() {
    this.snapchatPixelTrack(SnapchatPixelEventsEnum.PAGE_VIEW, this.pageViewDetails());
  }

  public addedToCart(product: Product) {
    this.snapchatPixelTrack(SnapchatPixelEventsEnum.ADD_ITEM_TO_CART, this.productDetails(product));
  }

  public addedToKoalaCart(product: KoalaProduct) {
    this.snapchatPixelTrack(SnapchatPixelEventsEnum.ADD_ITEM_TO_CART, this.koalaProductDetails(product));
  }
}

const snapchatPixelTracker = new SnapchatPixelTracking();
export default snapchatPixelTracker;
