import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import getLocalStorage from "src/helpers/local-storage/getLocalStorage";
import setLocalStorage from "src/helpers/local-storage/setLocalStorage";

export interface SetContextProps {
  isSetBuilderActive: boolean;
  setIsSetBuilderActive: Dispatch<SetStateAction<boolean>>;
}

export const SetContext = createContext<SetContextProps | null>(null);

function saveActivityStatusInStorage(isSetBuilderActive: boolean) {
  if (isSetBuilderActive) {
    setLocalStorage("set_builder_active", true);
  }
}

function setActivityStatusFromStorage(
  setIsSetBuilderActive: Dispatch<SetStateAction<boolean>>
) {
  const isSetBuilderActive = getLocalStorage("set_builder_active");
  if (isSetBuilderActive) {
    setIsSetBuilderActive(true);
  }
}

export default function SetProvider({ children }: { children: ReactNode }) {
  const [isSetBuilderActive, setIsSetBuilderActive] = useState(false);

  // effects

  // /**
  //  * Sets whether set builder is active in the local storage
  //  */
  // useEffect(() => {
  //   saveActivityStatusInStorage(isSetBuilderActive);
  // }, [isSetBuilderActive]);

  // /**
  //  * Gets whether set builder is active from local storage
  //  */
  // useEffect(() => {
  //   setActivityStatusFromStorage(setIsSetBuilderActive);
  // }, []);

  return (
    <SetContext.Provider value={{ isSetBuilderActive, setIsSetBuilderActive }}>
      {children}
    </SetContext.Provider>
  );
}
