enum TiktokPixelEvents {
  PAGE_VIEW = "Pageview",
  ADD_ITEM_TO_CART = "AddToCart",
  INITIATE_CHECKOUT = "InitiateCheckout",
  COMPLETE_REGISTRATION = "CompleteRegistration",
  ADD_PAYMENT_INFO = "AddPaymentInfo",
  CONTACT = "Contact",
}

export default TiktokPixelEvents;
