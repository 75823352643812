import EG from "src/config/countries/EG";
import MA from "src/config/countries/MA";
import LB from "src/config/countries/LB";
import SA from "src/config/countries/SA";
import AE from "src/config/countries/AE";
import KW from "src/config/countries/KW";
import US from "src/config/countries/US";
import Country from "src/helpers/localization-helpers/countryClass";

const countries: { [key: string]: Country } = { EG, LB, SA, KW, US };

export default countries;
