// https://stackoverflow.com/a/62505656/7982963

import {
  createContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";
import getLocalStorage from "../helpers/local-storage/getLocalStorage";
import setLocalStorage from "../helpers/local-storage/setLocalStorage";
import removeFromLocalStorage from "src/helpers/local-storage/removeFromLocalStorage";
import { Cart, Line } from "src/types/storefront/Cart";
import updateItemsQuantity, {
  CartLineUpdateInput,
} from "src/apis/storefront-latest-version/cart/updateItemsQuantity";
import getCart from "src/apis/storefront-latest-version/cart/getCart";
import { useRouter } from "next/router";
import updateCartWithDiscountCodes from "src/apis/storefront-latest-version/cart/updateCartWithDiscountCodes";
import { DraftOrderContext, DraftOrderContextProps } from "./draftOrder";
import useEnhancedAsync from "src/hooks/useEnhancedAsync";
import useCountryCode from "src/hooks/useCountryCode";
import useCountryData from "src/hooks/useCountryData";
import usePrevious from "src/hooks/usePrevious";
import toLower from "lodash/toLower";
import { CountryCode } from "src/types/localization/CountryCode";
import mapCartToDraftOrderFields from "src/helpers/draft-order/mapCartToDraftOrderFields";
import { SetContext, SetContextProps } from "./SetContext";
import highlightError from "src/helpers/highlight/highlightError";

interface Props {
  children: JSX.Element;
}

export interface CartContextProps {
  sidebarExpanded: boolean;
  setSidebarExpanded: Dispatch<SetStateAction<boolean>>;
  cart: Cart | null;
  setCart: Dispatch<SetStateAction<Cart | null>>;
  cartLoading: boolean;
  setCartLoading: Dispatch<SetStateAction<boolean>>;
  outOfStockItems: Line[];
  removeOutOfStockItems(cart: Cart): Promise<void>;
  updateItemsQuantity(newLines: CartLineUpdateInput[]): Promise<Cart | null>;
  resetCart(): void;
}

export const CartContext = createContext<CartContextProps | null>(null);
function CartProvider({ children }: Props) {
  const countryCode = useCountryCode();
  const countryData = useCountryData();

  /**
   * Router
   */
  const router = useRouter();
  const { query, pathname } = router;

  /**
   * Order conext
   */
  const { draftOrder, updateDraftOrder, resetDraftOrder } = useContext(
    DraftOrderContext
  ) as DraftOrderContextProps;

  /**
   * set builder context
   */
  const { isSetBuilderActive } = useContext(SetContext) as SetContextProps;

  /**
   * UI state
   */
  const [sidebarExpanded, setSidebarExpanded] = useState(false);

  /**
   * Cart state
   */
  const [cart, setCart] = useState<null | Cart>(null);
  const [outOfStockItems, setOutOftStockItems] = useState<Line[]>([]);
  const [cartLoading, setCartLoading] = useState(true);
  const [checkedDiscountCodes, setCheckedDiscountCodes] = useState<string[]>(
    []
  );
  console.log({ cart });

  /**
   * Sync item.quantity with item.merchandise.quantityAvailable
   */
  const [handleGetCart] = useEnhancedAsync(getCart, {
    retry: true,
    retryTimeout: 5000,
  });
  const removeOutOfStockItems = async (cart: Cart) => {
    const newLines: CartLineUpdateInput[] = [];
    for (const line of cart.lines) {
      if (line.quantity === 0) {
        newLines.push({
          id: line.id,
          quantity: 0,
        });
      } else if (line.quantity > line.merchandise.quantityAvailable) {
        const newQuantity =
          line.merchandise.quantityAvailable < 0
            ? 0
            : line.merchandise.quantityAvailable;
        newLines.push({
          id: line.id,
          quantity: newQuantity,
        });
      }
    }
    await _updateItemsQuantity(newLines);

    setOutOftStockItems([]);
  };

  /**
   * Runs every time the cart changes or before the user pays to check that the items are available
   */
  const getOutOfStockItems = (cart: Cart) => {
    const outOfStockItems: Line[] = [];

    for (const line of cart.lines) {
      if (
        line.quantity === 0 ||
        line.quantity > line.merchandise.quantityAvailable
      ) {
        outOfStockItems.push(line);
      }
    }

    return outOfStockItems;
  };

  /**
   * Apply discount codes from local storage
   */
  const applyDiscountCodesFromLocalStorage = async () => {
    if (!cart || !cart.id || !router.isReady || !countryData) return;

    const shopifyDiscountCodes = cart.discountCodes.map(({ code }) => code);
    const savedDiscountCodes =
      getLocalStorage(`discount_codes_${countryCode}`) || [];
    const notCheckedDiscountCodes = savedDiscountCodes.filter(
      (code: string) => !checkedDiscountCodes.includes(code)
    );

    if (notCheckedDiscountCodes.length > 0) {
      const codesToApply = shopifyDiscountCodes
        .concat(savedDiscountCodes)
        .filter((code) => code.length > 0);
      if (codesToApply.length > 0) {
        try {
          const newCart = await updateCartWithDiscountCodes(
            countryData,
            cart.id,
            codesToApply
          );
          setCheckedDiscountCodes((prev) => prev.concat(codesToApply));
          setCart(newCart);
        } catch (error: any) {
          highlightError(error);
        }
      }
    }
  };

  /**
   * Once the page loads, checks the local storage for a cart id, and fetches it from Shopify
   */
  const getCartByLocalStorageId = async (countryCode: CountryCode) => {
    let cart: Cart | null = null;
    const cartId = getLocalStorage(`cart_id_${countryCode}`);
    if (cartId) {
      cart = await handleGetCart(countryCode, cartId);
    }
    setCart(cart);
    setCartLoading(false);
  };

  useEffect(() => {
    if (cart) {
      const outOfStockItems = getOutOfStockItems(cart);
      setOutOftStockItems(outOfStockItems);
    }
  }, [cart]);

  /**
   * Checks if the free-shipping discount code exists in the cart
   */
  useEffect(() => {
    if (cart && cart.id) {
      setLocalStorage(
        `free_shipping_discount_exists_${countryCode}`,
        cart.discountCodes.some(({ code }) => code === "free-shipping")
      );
    }
  }, [cart]);

  /**
   * Gets cart by local storage id
   * if the website isn't redirected from shopify checkout
   * as the cart then would be expired
   */
  useEffect(() => {
    if (!countryCode) return;

    const redirectedFromShopify =
      router.query.shopify_checkout_status === "complete";

    if (!redirectedFromShopify) getCartByLocalStorageId(countryCode);
  }, [countryCode]);

  useEffect(() => {
    if (cart) {
      setLocalStorage(`cart_id_${countryCode}`, cart.id);
      /**
       * Saving the cart in the local storage for US & LB
       * to get after a customer completes the checkout process on shopify
       * and get redirected back to our website
       * as the cart gets expired by Shopify
       * so we can't fetch it
       */
      if (countryData?.checkoutMethod === "standard") {
        setLocalStorage(`cart_${countryCode}`, cart);
      }
    }
  }, [
    cart,
    // countryData
  ]);

  /**
   * If a user visits the website with a discount_codes param, save it to local storage
   */
  useEffect(() => {
    if (pathname === "/checkout/thank-you") return;

    const discountCodesParam = Array.isArray(query["discount_codes"])
      ? query["discount_codes"]
      : typeof query["discount_codes"] === "string"
      ? [query["discount_codes"]]
      : [];

    if (discountCodesParam.length > 0)
      setLocalStorage(
        `discount_codes_${countryCode}`,
        discountCodesParam.map(toLower)
      );
  }, [query]);

  useEffect(() => {
    applyDiscountCodesFromLocalStorage();
  }, [cart, router.isReady, checkedDiscountCodes]);

  /**
   * Update the draft order everytime the cart changes
   */
  async function syncDraftOrderWithCart() {
    if (
      !draftOrder ||
      !cart ||
      !prevCart ||
      JSON.stringify(prevCart) === JSON.stringify(cart) ||
      !countryData
    )
      return;

    if (cart.lines.length === 0) {
      resetDraftOrder();
      return;
    }

    const newDraftOrder = await updateDraftOrder(
      countryData.code,
      draftOrder.id,
      mapCartToDraftOrderFields({
        countryData,
        cart,
        isSetBuilderActive,
        draftOrder,
        noteAttributes: draftOrder.note_attributes,
      })
    );
  }
  const prevCart = usePrevious(cart);
  useEffect(() => {
    syncDraftOrderWithCart();
  }, [cart]);

  const _updateItemsQuantity = async (newLines: CartLineUpdateInput[]) => {
    if (!countryCode) {
      throw new Error("countryCode is undefined");
    }

    const newCart = await updateItemsQuantity(
      countryCode,
      cart && cart.id ? cart.id : "",
      newLines
    );

    setCart(newCart);
    return newCart;
  };

  /**
   * Resetters
   */
  const resetCart = () => {
    setCart(null);
    removeFromLocalStorage(
      `cart_id_${countryCode}`,
      `discount_codes_${countryCode}`
    );
  };

  return (
    <CartContext.Provider
      value={{
        sidebarExpanded,
        setSidebarExpanded,
        cart,
        setCart,
        cartLoading,
        setCartLoading,
        outOfStockItems,
        removeOutOfStockItems,
        updateItemsQuantity: _updateItemsQuantity,
        resetCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export default CartProvider;
