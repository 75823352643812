import { Popover } from "@headlessui/react";
import { memo } from "react";
import classNames from "src/helpers/classNames";

type PopoverButtonProps = {
  id?: string;
  open: boolean;
  children: JSX.Element;
};

function PopoverButton({ id, open, children }: PopoverButtonProps) {
  return (
    <Popover.Button
      as="button"
      id={id}
      className={classNames(
        open
          ? "border-beige-900 text-beige-900"
          : "border-transparent text-gray-700 hover:text-gray-800",
        "country_popover__toggler group inline-flex items-center border-b-2 py-2 text-sm font-medium focus:outline-none"
      )}
    >
      {children}
    </Popover.Button>
  );
}

export default memo(PopoverButton);
