import structuredClone from "@ungap/structured-clone";
import { Cart, Line } from "src/types/storefront/Cart";

/**
 * flatten line items
 * @param {Cart} cart
 */
export default function flattenCartLineItems(cart: Cart): Line[] {
  const cartClone = structuredClone(cart);

  const lineItems: Line[] = [];
  for (let lineItem of cartClone.lines) {
    for (let i = 0; i < lineItem.quantity; i++) {
      lineItems.push({ ...lineItem, quantity: 1 });
    }
  }

  return lineItems;
}
