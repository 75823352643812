import { Variant } from "src/types/storefront";
import getOptionValueByName from "./getOptionValueByName";
import { VariantFilter } from "./getOptionValuesWithStock";

export default function variantMatchFilters(
	variant: Variant,
	filters: VariantFilter[]
) {
	return filters.every(
		(filter) =>
			filter.value ===
			getOptionValueByName(filter.name, variant.selectedOptions)
	);
}
