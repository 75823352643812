import { forwardRef, HTMLProps } from "react";
import classNames from "src/helpers/classNames";

type Props = {
  id?: string;
  className?: string;
  children: JSX.Element | string;
  disabled: boolean;
};

const ThemeLink = forwardRef<
  HTMLAnchorElement,
  HTMLProps<HTMLAnchorElement & Props>
>(function Link(
  {
    onClick,
    href,
    id = "",
    className = "",
    target = "_self",
    children,
    disabled = false,
  },
  ref
) {
  return (
    <a
      href={href}
      ref={ref}
      id={id}
      className={classNames(
        "shemsiBtn",
        disabled
          ? "shemsiBtn-reverse pointer-events-none"
          : "shemsiBtn-primary shemsiBtn-hover cursor-pointer",
        className
      )}
      onClick={onClick}
      onMouseDown={(e) => e.preventDefault()}
      target={target}
    >
      {children}
    </a>
  );
});

export default ThemeLink;
