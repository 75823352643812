import { Image } from "src/types/storefront";

export default function getImagesBySku(sku: string, images: Image[]): Image[] {
  let result = images.filter(
    (image) => image.altText && sku.startsWith(image.altText)
  );

  if (result.length === 0) {
    result = images;
  }

  return result;
}
