import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { Fragment, memo } from "react";
import ThemeLink from "src/components/link/ThemeLink";
import classNames from "src/helpers/classNames";
import { useTranslations } from "next-intl";
import useCountryData from "src/hooks/useCountryData";
import MultiCollectionViewer from "src/components/products/MultiCollectionViewer";
import { CollectionConfig } from "src/helpers/localization-helpers/countryClass";
import KoalaMultiCollectionViewer from "src/components/products/KoalaMultiCollectionViewer";

type ShopPopoverProps = {};

function ShopPopover({}: ShopPopoverProps) {
  const t = useTranslations("Navigation");
  const countryData = useCountryData();

  let collections: CollectionConfig[] = [];
  if (countryData) {
    collections = Object.values(countryData.collections).filter(
      (collection) => collection.isActive && collection.isInPopover
    );
  }

  return (
    <Popover className="flex">
      {({ open }) => (
        <>
          <div className="relative flex">
            <Popover.Button
              className={classNames(
                open ? "border-beige-900 text-beige-900" : "border-transparent text-gray-700 hover:text-gray-800",
                "relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out focus:border-beige-900 focus:text-beige-900 focus:outline-none focus:ring-0"
              )}
            >
              {t("shop")}
              {open ? (
                <ChevronUpIcon className="ms-2 w-4" aria-hidden="true" />
              ) : (
                <ChevronDownIcon className="ms-2 w-4" aria-hidden="true" />
              )}
            </Popover.Button>
          </div>

          <Popover.Overlay className="fixed inset-0 z-10 bg-black opacity-30" />

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Panel className="absolute inset-x-0 top-full z-10">
              {({ close }) => (
                <>
                  <div className="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />

                  <div className="relative bg-white">
                    <div className="mx-auto max-w-7xl px-8 py-8">
                      <div className="space-y-4">
                        {countryData &&
                          (countryData.oms == "koala" ? (
                            <KoalaMultiCollectionViewer
                              passedCollections={Object.values(countryData.koalaCollections).filter(
                                (collection) => collection.isActive && collection.isInPopover
                              )}
                              singleRow
                              imageClassName="!aspect-[1/1] [@media(min-width:1520px)]:!aspect-[6/8]"
                              onProductClick={close}
                            />
                          ) : (
                            <MultiCollectionViewer
                              passedCollections={Object.values(countryData.collections).filter(
                                (collection) => collection.isActive && collection.isInPopover
                              )}
                              singleRow
                              imageClassName="!aspect-[1/1] [@media(min-width:1520px)]:!aspect-[6/8]"
                              onProductClick={close}
                            />
                          ))}
                      </div>

                      <div className="flex justify-end pt-10">
                        <div className="flex shrink-0">
                          <Link href={`/shop-all`} passHref legacyBehavior>
                            <ThemeLink onClick={() => close()}>{t("shop_all")}</ThemeLink>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default memo(ShopPopover);
