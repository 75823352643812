import countries from "src/config/countries/countries";
import Country from "src/helpers/localization-helpers/countryClass";
import useCountryCode from "./useCountryCode";

export default function useCountryData(): null | Country {
  const countryCode = useCountryCode();

  if (!countryCode) return null;

  return countries[countryCode];
}
