import { Dialog, Transition } from "@headlessui/react";
import Link from "next/link";
import { Fragment, memo } from "react";
import ThemeLink from "src/components/link/ThemeLink";
import {
  DocumentTextIcon,
  QuestionMarkCircleIcon,
  GlobeEuropeAfricaIcon,
  UserGroupIcon,
  FaceSmileIcon,
  AtSymbolIcon,
  InformationCircleIcon,
  ShieldCheckIcon,
  CurrencyDollarIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useTranslations } from "next-intl";
import useRtl from "src/hooks/useRtl";
import useCountryData from "src/hooks/useCountryData";
import ProductsList from "src/sections/products-list/ProductsList";
import useMainProducts from "src/hooks/useMainProducts";
import useMainKoalaProducts from "src/hooks/useMainKoalaProducts";
import KoalaProductsList from "src/sections/products-list/KoalaProductsList";

type MobileMenuProps = {
  open: boolean;
  setOpen(open: boolean): void;
};

function MobileMenu({ open, setOpen }: MobileMenuProps) {
  const rtl = useRtl();
  const t = useTranslations("Navigation");
  const countryData = useCountryData();
  const products = useMainProducts();
  const koalaProducts = useMainKoalaProducts();

  const discover = [
    {
      name: t("create_set"),
      href: "/customize-set",
      current: false,
      icon: CurrencyDollarIcon,
    },
    {
      name: t("mission"),
      href: "/mission",
      current: false,
      icon: DocumentTextIcon,
    },
    {
      name: t("faqs"),
      href: "/faq",
      current: false,
      icon: QuestionMarkCircleIcon,
    },
    {
      name: t("our_ambassadors"),
      href: "/our-ambassadors",
      current: false,
      icon: UserGroupIcon,
    },
    {
      name: t("why_trust_us"),
      href: "/they-trust-us",
      current: false,
      icon: FaceSmileIcon,
    },
    {
      name: t("contact"),
      href: "/contact",
      current: false,
      icon: AtSymbolIcon,
    },
  ];

  const learn = [
    {
      name: t("how_it_works"),
      href: "/learn",
      current: false,
      icon: InformationCircleIcon,
    },
    {
      name: t("how_to_wash"),
      href: "/how-to-wash",
      current: false,
      icon: ShieldCheckIcon,
    },
    {
      name: t("size_guide"),
      href: "/sizing-grid",
      current: false,
      icon: GlobeEuropeAfricaIcon,
    },
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom={rtl ? "translate-x-full" : "-translate-x-full"}
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo={rtl ? "translate-x-full" : "-translate-x-full"}
          >
            <Dialog.Panel className="relative flex w-full max-w-sm flex-col overflow-y-auto bg-white pb-12 shadow-xl">
              <div>
                <div className="flex px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-sm p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">{t("close_menu")}</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                {/* The 4 products */}
                <div className="px-4 py-2">
                  {countryData?.oms == "shopify" ? (
                    <ProductsList products={products} isMobileMenu onProductClick={() => setOpen(false)} />
                  ) : (
                    <KoalaProductsList products={koalaProducts} isMobileMenu onProductClick={() => setOpen(false)} />
                  )}
                </div>
                {/* Shop All Button */}
                <div className="space-y-2.5 border-t border-gray-200 px-4 py-5">
                  <Link href={`/shop-all`} passHref legacyBehavior>
                    <ThemeLink onClick={() => setOpen(false)}>
                      <>{t("shop_all")}</>
                    </ThemeLink>
                  </Link>
                </div>
                {/* Navigation Items */}
                <div className="relative mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                  <nav
                    className="grid gap-y-10 bg-white px-4 py-8 sm:grid-cols-2 sm:gap-x-8 sm:px-6 sm:py-12 lg:px-8 xl:pe-12"
                    aria-labelledby="solutions-heading"
                  >
                    <div>
                      <h3 className="text-base font-medium text-gray-500">{t("discover")}</h3>
                      <ul role="list" className="mt-5 space-y-6">
                        {discover.map((item) => (
                          <li key={item.name} className="flow-root">
                            <Link
                              href={item.href}
                              className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-50"
                            >
                              <item.icon className="h-6 w-6 flex-shrink-0 text-gray-800" aria-hidden="true" />
                              <span className="ms-4">{item.name}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h3 className="text-base font-medium text-gray-500">{t("learn")}</h3>
                      <ul role="list" className="mt-5 space-y-6">
                        {learn.map((item) => (
                          <li key={item.name} className="flow-root">
                            <Link
                              href={item.href}
                              className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-50"
                            >
                              <>
                                <item.icon className="h-6 w-6 flex-shrink-0 text-gray-800" aria-hidden="true" />
                                <span className="ms-4">{item.name}</span>
                              </>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default memo(MobileMenu);
