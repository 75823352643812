import { useContext } from "react";
import { CreditsContext, CreditsContextProps } from "src/context/CreditsContext";
import { ReferralContext, ReferralContextProps } from "src/context/ReferralContext";
import useCountryData from "./useCountryData";
import { DiscountDetails } from "src/types/discount/DiscountDetails";
import { DiscountType } from "src/types/discount/DiscountType";
import calculateRefereeCreditsAmount from "src/helpers/referral/calculateRefereeCreditsAmount";
import preciseDecimal from "src/helpers/preciseDecimal";
import { SummaryDetails } from "src/types/summary-details/SummaryDetails";
import consts from "src/config/consts";
import { CountryCode } from "src/types/localization/CountryCode";
import { KoalaCart } from "src/types/koala/KoalaCart";
import koalaUtils from "src/helpers/koala/koalaUtils";
import generatePriceBeforeDiscount from "src/helpers/generatePriceBeforeDiscount";
import { AppliedDiscountValueType } from "src/types/shopify-admin";

export default function useKoalaSummaryDetails(cart: KoalaCart | null): SummaryDetails {
  console.log("🛒", { cart });

  // dependencies
  const countryData = useCountryData();
  const { activeReferralConfig } = useContext(ReferralContext) as ReferralContextProps;
  const { creditFactor } = useContext(CreditsContext) as CreditsContextProps;

  if (!cart || !countryData) {
    return {
      subtotalPrice: 0,
      totalPriceBeforeShipping: 0,
      totalPrice: 0,
      discounts: [],
    };
  }

  // 1. subtotal price
  const subtotalPrice = koalaUtils.calculateSubtotalPrice(cart.lineItems);

  // 2. discounts
  const discounts: DiscountDetails[] = [];

  /**
   * a. cart discounts
   */
  const cartDiscounts = cart.discounts.map(function convertPercenttageToFixed(discount) {
    return {
      ...discount,
      value: koalaUtils.calculateSingleDiscount(subtotalPrice, discount),
      valueType: AppliedDiscountValueType.fixed_amount,
    };
  });
  discounts.push(...cartDiscounts);

  /**
   * b. referral discount
   */
  if (activeReferralConfig && creditFactor) {
    const creditsAmount = calculateRefereeCreditsAmount(activeReferralConfig, cart.totalPrice, creditFactor);
    let creditsToCurrency = preciseDecimal(creditsAmount * creditFactor);
    if (creditFactor > 50 && countryData.code == CountryCode.EG) {
      creditsToCurrency = 50;
    }
    discounts.push({
      type: DiscountType.referral_code,
      name: activeReferralConfig.code,
      value: creditsToCurrency,
      valueType: AppliedDiscountValueType.fixed_amount,
    });
  }

  /**
   * c. Bundle discount
   */
  const itemsDiscount = koalaUtils.calculateLineItemsDiscount(cart.lineItems);
  if (itemsDiscount > 0) {
    discounts.push({
      type: DiscountType.bundle,
      name: preciseDecimal((itemsDiscount / subtotalPrice) * 100) + "%",
      value: itemsDiscount,
      valueType: AppliedDiscountValueType.fixed_amount,
    });
  }

  /**
   * d. Fake discount
   */
  if (countryData.splitTestDiscount) {
    const fakeDiscount =
      generatePriceBeforeDiscount(subtotalPrice, consts.FAKE_DISCOUNT_PERCENTAGE_VALUE) *
      (consts.FAKE_DISCOUNT_PERCENTAGE_VALUE / 100);

    discounts.push({
      type: DiscountType.generated_price_discount,
      name: consts.FAKE_DISCOUNT_PERCENTAGE_VALUE + "%",
      value: fakeDiscount,
      valueType: AppliedDiscountValueType.fixed_amount,
    });
  }

  // // 3. total price before shipping
  // const totalPriceBeforeShipping =
  //   subtotalPrice -
  //   sumBy(discounts, (discount) => (discount.type === DiscountType.generated_price_discount ? 0 : discount.value));

  // // 4. total price after shipping
  // let totalPrice = totalPriceBeforeShipping;
  // if (totalPrice < countryData.shipping.freeLimit) {
  //   // Check if delivery option selected is pargo
  //   if (deliveryOption === DeliveryOption.pickup && countryData.pickupShipping && countryData.pickupShipping.fees) {
  //     totalPrice += countryData.pickupShipping.fees;
  //   } else {
  //     totalPrice += countryData.shipping.fees;
  //   }
  // }
  const shippingFees = cart.shippingFees ? cart.shippingFees.amount : 0;

  return {
    discounts,
    subtotalPrice,
    totalPrice: cart.totalPrice,
    totalPriceBeforeShipping: cart.totalPrice - shippingFees,
  };
}
