import countries from "src/config/countries/countries";
import { CountryCode } from "src/types/localization/CountryCode";

export default function getDeployments(
  currentDeployment?: CountryCode
): CountryCode[] {
  let result = Object.keys(countries) as CountryCode[];

  if (currentDeployment && currentDeployment in CountryCode) {
    result.sort((country1, country2) => {
      if (country1 === currentDeployment) return -1;
      return 0;
    });
  }

  return result;
}
