export enum ProductType {
  Underwear = "underwear",
  WashBag = "wash-bag",
  TravelBag = "travel-bag",
  GiftCard = "gift-card",
}

export enum ProductTags {
  Gifts = "gifts",
  Teens = "teens",
}
