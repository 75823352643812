import Country, { KoalaCollectionConfigs } from "src/helpers/localization-helpers/countryClass";
import { SupportedPaymentMethod } from "src/types/enums/paymentMethods.enum";
import { CountryName } from "src/types/localization/CountryName";
import { CountryCode } from "src/types/localization/CountryCode";
import { Currency } from "src/types/localization/Currency";
import { LanguageName } from "src/types/localization/LanguageName";
import { LanguageCode } from "src/types/localization/LanguageCode";
import { bundleDiscountSystems } from "../bundle/defaultBundleDiscountSystems";
import consts from "../consts";

const env = process.env.NEXT_PUBLIC_ENV;

const shopifyKeys = {
  STORE_NAME: process.env.NEXT_PUBLIC_SHOPIFY_STORE_NAME_KW ?? "",
  STOREFRONT_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_TOKEN_KW ?? "",
  ADMIN_TOKEN: process.env.SHOPIFY_ADMIN_TOKEN_KW ?? "",
};

const collections = {
  COLLECTION_ALL_ACTIVE_PRODUCTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431060636 : 461811417372,
    name: "all",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ALL_SINGLE_PRODUCTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431158940 : 461811417372,
    name: "all_single",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ALL_PACKS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431060636 : 461811417372,
    name: "packs",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_UNDERWEARS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 298026959004 : 461811417372,
    name: "underwears",
    isActive: true,
    hasSeparateList: false,
    isCategory: false,
    isDefault: true,
    isInPopover: true,
    hasBundleDiscount: true,
  },
  COLLECTION_LADIES: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297751806108 : 461811417372,
    name: "ladies",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_TEENS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297688531100 : 461811417372,
    name: "teens",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_GIFTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 298701947036 : 0,
    name: "gifts",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ACCESSORIES: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297751740572 : 461811417372,
    name: "accessories",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
};

const koalaCollections: KoalaCollectionConfigs = {
  COLLECTION_ALL_ACTIVE_PRODUCTS: {
    id: ["production", "preproduction"].includes(env) ? "66614ba8d9470fe2c0ea8a69" : "665da953be6499ad54787e04",
    name: "all",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_UNDERWEARS: {
    id: ["production", "preproduction"].includes(env) ? "66614bf9d9470fe2c0ea8a7a" : "665da949be6499ad54787e02",
    name: "underwears",
    isActive: true,
    hasSeparateList: false,
    isCategory: false,
    isDefault: true,
    isInPopover: true,
    hasBundleDiscount: true,
  },
  COLLECTION_LADIES: {
    id: "",
    name: "ladies",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_TEENS: {
    id: "",
    name: "teens",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ACCESSORIES: {
    id: "",
    name: "accessories",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
};

const shipping = {
  line: {
    title: "Standard",
    price: "1.00",
  },
  fees: 1,
  freeLimit: 18,
};

const socialInfo = {
  WHATSAPP_SUPPORT_NUMBER: "+971585096963",
  FACEBOOK_PAGE_HANDLE: "myshemsi",
  INSTAGRAM_HANDLE: "myshemsi",
  YOUTUBE_CHANNEL: "UCS0B5xRuLR8KZTsQTmDmrHg",
  TIKTOK_CHANNEL: "myshemsi",
  SUPPORT_START_HOUR: 8,
  SUPPORT_END_HOUR: 1,
};

const productsData = [
  {
    internalName: "ipanema",
    shopifyIds: [
      // Dev
      7060457160840, 7075133456520,

      // Prod
      8773455380764,

      // Prod packs
      8773455380764,

      // staging single
      7481930645660,

      // staging packs
      7481929564316,
    ],
    ratings: 12500,
  },
  {
    internalName: "bahia",
    shopifyIds: [
      // Dev
      7060425605256, 7075128737928,

      // Prod
      8773455773980,

      // Prod packs
      8773455773980,

      // staging single
      7481930383516,

      // staging packs
      7481929891996,
    ],
    ratings: 5600,
  },
  {
    internalName: "alexandria",
    shopifyIds: [
      // Dev
      7060417052808, 7075132670088,

      // Prod
      8773455577372,

      // Prod pack alexandria
      8773455577372,

      // staging single
      7481930481820,

      // staging packs
      7481930121372,
    ],
    ratings: 7520,
  },
  {
    internalName: "havana",
    shopifyIds: [
      // Dev
      7075118612616, 7060449493128,

      // Prod
      8773456003356,

      // Prod pack
      8773456003356,

      // staging single
      7481930285212,

      // staging packs
      7481929760924,
    ],
    ratings: 9500,
  },
];

// Label field should be localized
const provinces = [
  { label: "al_ahmadi", value: "Al Ahmadi" },
  { label: "al_asimah", value: "Al Asimah" },
  { label: "al_farwaniyah", value: "Al Farwaniyah" },
  { label: "al_jahra", value: "Al Jahra" },
  { label: "hawalli", value: "Hawalli" },
  { label: "mubarak_al_kabeer", value: "Mubarak Al Kabeer" },
];

// const setDiscountSystems: SetDiscountSystem[] = [
//   {
//     type: SetDiscountType.set3,
//     minimumItems: 3,
//     discount: {
//       amount: 10,
//       amountType: "percentage",
//     },
//   },
//   {
//     type: SetDiscountType.set5,
//     minimumItems: 5,
//     discount: {
//       amount: 20,
//       amountType: "percentage",
//     },
//   },
// ];

const KW = new Country({
  name: CountryName.Kuwait,
  code: CountryCode.KW,
  currency: Currency.KWD,
  numberOfCents: 1000,
  region: "MENA",
  phoneExample: "94742595",
  provinces,
  provincyRequiredInCheckout: true,
  zipRequiredInCheckout: false,
  supportedLanguages: [
    {
      label: LanguageName.العربية,
      value: LanguageCode.ar,
    },
    {
      label: LanguageName.English,
      value: LanguageCode.en,
      default: true,
    },
  ],
  shopifyKeys,
  omsConnector: "shopify-kuwait",
  collections,
  koalaCollections,
  checkoutMethod: "in-house",
  shipping,
  productsData,
  socialInfo,
  referralConfig: {
    newRefereeOnly: true,
    referralCredits: {
      amount: 50,
      amountType: "fixed_amount",
    },
    refereeCredits: {
      amount: 50,
      amountType: "fixed_amount",
    },
  },
  paymentMethods: [
    // SupportedPaymentMethod.cashOnDelivery,
    SupportedPaymentMethod.creditCard,
    SupportedPaymentMethod.tabby,
  ],
  bundleDiscountSystems,
  splitTestDiscount: {
    discountAmount: consts.FAKE_DISCOUNT_PERCENTAGE_VALUE,
    discountType: "percentage",
  },
  freeShipping: true,
  hasPricesAbTest: false,
  christmasTheme: false,
  multiCategory: false,
  multiColors: false,
  pargoPickupPoints: false,
  hasGiftCards: false,
  hasAccessories: false,
  ramadanTheme: false,
  areDiscountsManagedFromShopify: false,
  isDiscountCodeAllowed: true,
  homeHeroImage: ["production", "preproduction"].includes(env)
    ? 8773455773980
    : ["staging", "ci"].includes(env)
    ? 7481930481820
    : 7060417052808,
  // oms: ["production", "preproduction", "ci"].includes(env) ? "shopify" : "koala",
  oms: "koala",
  orderScopes: ["fulfil", "nps_free", "referral"],
  pl3Connector: "farfill-dubai",
});

export default KW;
