import {
  AppliedDiscountValueType,
  DraftOrder,
  NoteAttributesEntity,
  RegisteringLineItem,
} from "src/types/shopify-admin";
import { Cart } from "src/types/storefront/Cart";
import sliceShopifyIdNumber from "../storefront-helpers/sliceShopifyIdNumber";
import { DiscountType } from "src/types/discount/DiscountType";
import { DiscountDetails } from "src/types/discount/DiscountDetails";
import getCartDiscountedAmount from "../cart/getCartDiscountedAmount";
import getAllDiscountsFromDraftOrder from "./getAllDiscountsFromDraftOrder";
import Country from "../localization-helpers/countryClass";
import { sumBy } from "lodash";
import mapBundleDiscountsToCart from "../bundle-discounts/mapBundleDiscountsToCart";

export default function mapCartToDraftOrderFields({
  countryData,
  cart,
  isSetBuilderActive,
  draftOrder,
  noteAttributes = [],
}: {
  countryData: Country;
  cart: Cart;
  isSetBuilderActive: boolean;
  draftOrder?: DraftOrder;
  noteAttributes?: NoteAttributesEntity[];
}) {
  /**
   * 1. line items
   */
  let lineItems: RegisteringLineItem[] = cart.lines.map((item) => ({
    variant_id: sliceShopifyIdNumber(item.merchandise.id),
    quantity: item.quantity,
    properties: item.attributes.map((attribute) => ({
      name: attribute.key,
      value: attribute.value,
    })),
  }));

  /**
   * 2. discounts
   */
  let draftOrderAppliedDiscounts: DiscountDetails[] = [];
  if (draftOrder) {
    draftOrderAppliedDiscounts = getAllDiscountsFromDraftOrder(draftOrder);
  }
  // a. discount code
  const cartAppliedDiscount = cart.discountCodes.find(
    ({ applicable }) => applicable
  );
  if (cartAppliedDiscount) {
    // 1. filter out code discount from draft order (if any) to apply the new one
    draftOrderAppliedDiscounts = draftOrderAppliedDiscounts.filter(
      (discount) => discount.type !== DiscountType.discount_code
    );
    // 2. transform code discount to draft order format
    const discountDetails: DiscountDetails = {
      type: DiscountType.discount_code,
      name: cartAppliedDiscount.code || DiscountType.discount_code,
      value: getCartDiscountedAmount(cart),
      valueType: "fixed_amount",
    };
    // 3. PUSH!
    draftOrderAppliedDiscounts.push(discountDetails);
  }

  // b. bundle discount
  if (countryData.bundleDiscountSystems) {
    const cartWithBundleDiscount = mapBundleDiscountsToCart(
      cart,
      countryData.bundleDiscountSystems
    );
    if (cartWithBundleDiscount) {
      /**
       * Bundle discount is the only one applied to the items directly
       * as it's calculated per item
       */
      lineItems = cart.lines.map((item) => {
        const bundleItem = cartWithBundleDiscount.lines.find(
          (bundleItem) => bundleItem.id === item.id
        );
        if (bundleItem) {
          return {
            variant_id: sliceShopifyIdNumber(item.merchandise.id),
            quantity: item.quantity,
            applied_discount: {
              title: DiscountType.bundle,
              description: bundleItem.bundleDiscount.bundleType,
              value: String(bundleItem.bundleDiscount.amount),
              value_type: AppliedDiscountValueType.percentage,
            },
            properties: [
              {
                name: "bundle_discount",
                value: JSON.stringify({
                  type: DiscountType.bundle,
                  name: bundleItem.bundleDiscount.bundleType,
                  value: bundleItem.bundleDiscount.amount,
                  valueType: AppliedDiscountValueType.percentage,
                }),
              },
              ...item.attributes.map((attribute) => ({
                name: attribute.key,
                value: attribute.value,
              })),
            ],
          };
        } else {
          return {
            variant_id: sliceShopifyIdNumber(item.merchandise.id),
            quantity: item.quantity,
            properties: item.attributes.map((attribute) => ({
              name: attribute.key,
              value: attribute.value,
            })),
          };
        }
      });

      // cartWithBundleDiscount.lines.map((item) => ({
      //   variant_id: sliceShopifyIdNumber(item.merchandise.id),
      //   quantity: item.quantity,
      //   applied_discount: {
      //     title: DiscountType.bundle,
      //     description: item.bundleDiscount.bundleType,
      //     value: String(item.bundleDiscount.amount),
      //     value_type: AppliedDiscountValueType.percentage,
      //   },
      //   properties: [
      //     {
      //       name: "bundle_discount",
      //       value: JSON.stringify({
      //         type: DiscountType.bundle,
      //         name: item.bundleDiscount.bundleType,
      //         value: item.bundleDiscount.amount,
      //         valueType: AppliedDiscountValueType.percentage,
      //       }),
      //     },
      //     ...item.attributes.map((attribute) => ({
      //       name: attribute.key,
      //       value: attribute.value,
      //     })),
      //   ],
      // }));
    }
  }
  const discountsSum = sumBy(
    draftOrderAppliedDiscounts,
    (discount) => discount.value
  );

  /**
   * 2. note attributes
   */
  if (isSetBuilderActive) {
    noteAttributes.push({
      name: "set_builder",
      value: "true",
    });
  }

  // Finally, combine line items, discount, and note attributes into draft order fields
  const draftOrderFields = {
    line_items: lineItems,
    applied_discount: {
      title: JSON.stringify(draftOrderAppliedDiscounts),
      value: String(discountsSum),
      value_type: AppliedDiscountValueType.fixed_amount,
    },
    note_attributes: noteAttributes,
  };

  return draftOrderFields;
}
