import { Dialog, Transition } from "@headlessui/react";
import { useTranslations } from "next-intl";
import { Fragment, memo, useContext } from "react";
import ThemeButton from "src/components/button/ThemeButton";
import { PaymentContext } from "src/context/payment";
import Country from "src/helpers/localization-helpers/countryClass";
import { useStoreActions, useStoreState } from "src/hooks/storeHooks";
import useCountryData from "src/hooks/useCountryData";

type CartUpdateModalProps = {
  isOpen: boolean;
};

function KoalaCartUpdateModal({ isOpen }: CartUpdateModalProps) {
  const t = useTranslations("CartSidebar");
  const countryData = useCountryData();
  const { deliveryOption } = useContext(PaymentContext);

  const oosItems = useStoreState((state) => state.oosItems);
  const removeOOSItems = useStoreActions((actions) => actions.removeOOSItems);

  function remove() {
    removeOOSItems({ countryData: countryData as Country, deliveryMethod: deliveryOption });
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={remove}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform space-y-2 overflow-hidden rounded-md bg-white p-6 align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-center text-lg font-bold leading-6 text-gray-900">
                  {t("update_needed")}
                </Dialog.Title>

                <p className="text-sm">{t("one_or_more_out_of_stock")}</p>

                <ul className="text-sm">
                  {oosItems.map((item) => (
                    <li key={item._id}>- {item.name}</li>
                  ))}
                </ul>

                <ThemeButton className="w-full" onClick={remove}>
                  {t("update_cart")}
                </ThemeButton>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default memo(KoalaCartUpdateModal);
