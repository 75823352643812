import { Product } from "src/types/storefront";
import { Filter } from "src/types/filters";
import productMatchFilters from "./productMatchFilters";

export default function getFilteredProducts(
  products: Product[],
  filters: Filter[]
): Product[] {
  const filteredProducts = products.filter((product) =>
    productMatchFilters(product, filters)
  );

  return filteredProducts;
}
