import Image from "next/image";
import Link from "next/link";
import React from "react";
import { useTranslations } from "next-intl";
import classNames from "src/helpers/classNames";
import useRtl from "src/hooks/useRtl";
import ThemeLink from "src/components/link/ThemeLink";
import ArrowIcon from "src/components/icon/ArrowIcon";
import useCountryData from "src/hooks/useCountryData";
import Logo from "src/components/logo/Logo";

type CheckoutFooterProps = {};

const CheckoutFooter = (props: CheckoutFooterProps) => {
  const rtl = useRtl();
  const t = useTranslations("Navigation");

  const countryData = useCountryData();

  const footerNavigation = {
    informations: [
      { name: t("legal"), href: "/legal" },
      { name: t("privacy_policy"), href: "/privacy-policy" },
    ],
  };

  /**
   * Calculates whether the support team in Cairo is supposed to be online or not.
   * This is hard coded in the config for [8am,1am[.
   */
  /*const [isSupportHours, setIsSupportHours] = useState(true);
	useEffect(() => {
		let hour: number = parseInt(dayjs().tz("Africa/Cairo").format("HH"));
		console.log(`Hour is ${hour}`);
		if (hour >= consts.SUPPORT_START_HOUR || hour < consts.SUPPORT_END_HOUR)
			setIsSupportHours(true);
		else setIsSupportHours(false);
	}, [dayjs().tz("Africa/Cairo").format("HH:mm")]);*/

  const isSupportHours = true;

  return (
    <footer>
      <div className="mx-auto max-w-7xl border-t border-gray-200 px-4 pt-2 sm:px-6 lg:px-8">
        <div className="border-b border-gray-200 pt-1 lg:mb-10 lg:pb-6">
          <div>
            <p className="text-center font-medium">{t("checkout_footer_title")}</p>

            {countryData && (
              <div className="mb-5 mt-3 flex flex-col gap-x-0 space-y-2 md:mb-0 md:flex-row md:gap-x-2 md:space-y-0">
                <div className="w-full">
                  <Link href={"http://m.me/" + countryData.socialInfo.FACEBOOK_PAGE_HANDLE} passHref legacyBehavior>
                    <ThemeLink target="_blank" className="shemsiBtn-reverse">
                      {t("facebook_messenger")}
                      <ArrowIcon rtl={rtl} className="ms-3 w-5 transition-all group-hover:ms-6" />
                    </ThemeLink>
                  </Link>
                </div>
                {/* <div className="w-full">
                  <Link
                    href={"https://wa.me/" + countryData.socialInfo.WHATSAPP_SUPPORT_NUMBER}
                    passHref
                    legacyBehavior
                  >
                    <ThemeLink target="_blank" className="shemsiBtn-reverse">
                      {t("whatsapp")}
                      <ArrowIcon rtl={rtl} className="ms-3 w-5 transition-all group-hover:ms-6" />
                    </ThemeLink>
                  </Link>
                </div> */}
                <div className="w-full">
                  <Link href={"https://ig.me/m/" + countryData.socialInfo.INSTAGRAM_HANDLE} passHref legacyBehavior>
                    <ThemeLink target="_blank" className="shemsiBtn-reverse">
                      {t("instagram")}
                      <ArrowIcon rtl={rtl} className="ms-3 w-5 transition-all group-hover:ms-6" />
                    </ThemeLink>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mx-auto mt-5 lg:mt-0 lg:max-w-none">
          {/* About Shemsi sections */}
          <div className="mb-10">
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/2">
                <Logo className={classNames("mb-5 w-auto", countryData?.christmasTheme ? "h-9" : "h-6")} />

                <p className="max-w-xl font-medium">{t("shemsi_brand_explanation")}</p>
              </div>

              <div
                className={classNames(
                  "mt-5 w-full text-center md:mt-0 md:w-1/2",
                  rtl ? "md:text-left" : "md:text-right"
                )}
              >
                {/* <div className="mt-5 w-full text-center md:mt-0 md:w-1/2 md:text-right"> */}
                <ul className="flex justify-end gap-x-6">
                  {/* <ul className="space-x-6"> */}
                  {footerNavigation.informations.map((item) => (
                    <li key={item.name} className="text-sm">
                      <Link href={item.href} className="hover:text-gray-600">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <p className="mt-5 text-center text-sm md:text-start">{t("copyright_notice_2022")}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default CheckoutFooter;
