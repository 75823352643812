import { useTranslations } from "next-intl";
import { memo, useContext } from "react";
import { CreditsContext, CreditsContextProps } from "src/context/CreditsContext";
import { DraftOrderContext, DraftOrderContextProps } from "src/context/draftOrder";
import { DeliveryOption, PaymentContext, PaymentProps, PaymentState } from "src/context/payment";
import getCartCurrency from "src/helpers/cart/getCartCurrency";
import Country from "src/helpers/localization-helpers/countryClass";
import useEnhancedAsync from "src/hooks/useEnhancedAsync";
import { Cart } from "src/types/storefront/Cart";
import SpinIcon from "../icon/BlackSpinIcon";
import DiscountItem from "./components/DiscountItem";
import TabbyPromo from "../tabby/TabbyPromo";
import { SupportedPaymentMethod } from "src/types/enums/paymentMethods.enum";
import { AuthenticationContext, AuthenticationContextProps } from "src/context/authentication";
import useSummaryDetails from "src/hooks/useSummaryDetails";
import { BundleCart } from "src/types/bundle-discounts/BundleCart";
import getCartItemsWithFakeDiscountSubtotal from "src/helpers/cart/getCartItemsWithFakeDiscountSubtotal";
import getCartItemsWithoutFakeDiscountSubtotal from "src/helpers/cart/getCartItemsWithoutFakeDiscountSubtotal";
import isAllCartItemsGifts from "src/helpers/cart/isAllCartItemsGifts";

type SummaryDetailsProps = {
  cart: Cart;
  bundleCart: BundleCart | null;
  countryData: Country;
  inCheckoutPage?: boolean;
};

function SummaryDetails({ cart, bundleCart, countryData, inCheckoutPage = false }: SummaryDetailsProps) {
  const t = useTranslations("SummaryDetails");

  // dependencies
  const { draftOrder } = useContext(DraftOrderContext) as DraftOrderContextProps;
  const { paymentStep, deliveryOption } = useContext(PaymentContext) as PaymentProps;
  const { user } = useContext(AuthenticationContext) as AuthenticationContextProps;
  const { creditFactor, spendCredits, unspendCredits, netCredits, creditsToSpend, creditsAreUsed, spentCredits } =
    useContext(CreditsContext) as CreditsContextProps;

  const [enhancedSpendCredits, loadingSpendCredits, errorSpendCredits] = useEnhancedAsync(spendCredits, {
    customError: t("something_went_wrong"),
  });
  const [enhancedUnspendCredits, loadingUnspendCredits, errorUnspendCredits] = useEnhancedAsync(unspendCredits, {
    customError: t("something_went_wrong"),
  });

  const summaryDetails = useSummaryDetails(cart, bundleCart);
  // const isFakeDiscountTestActive = useGrowthbookFeature("fake-discount", false);
  const lineItems = cart.lines;

  // Check if all cart items are gifts
  const isAllItemsAreGifts = isAllCartItemsGifts(lineItems);

  /**
   * Fake subtotal
   */
  let subtotalPrice = summaryDetails.subtotalPrice;
  if (countryData.splitTestDiscount) {
    // Get subtotal of items with fake discount (not gift cards)
    const itemsWithFakeDiscountSubtotal = getCartItemsWithFakeDiscountSubtotal(lineItems);

    // Get subtotal of items without fake discount (gift cards)
    const itemsWithoutFakeDiscountSubtotal = getCartItemsWithoutFakeDiscountSubtotal(lineItems);

    subtotalPrice = itemsWithFakeDiscountSubtotal + itemsWithoutFakeDiscountSubtotal;

    // subtotalPrice = generatePriceBeforeDiscount(
    //   subtotalPrice,
    //   consts.FAKE_DISCOUNT_PERCENTAGE_VALUE
    // );
  }
  // Calculate shipping fees based on delivery option selected
  const shippingFees =
    deliveryOption === DeliveryOption.pickup && countryData.pickupShipping && countryData.pickupShipping.fees
      ? countryData.pickupShipping.fees
      : countryData.shipping.fees;

  return (
    <div>
      <ul className="flex flex-col justify-between gap-1 border-b border-gray-300 pb-4">
        {/* Subtotal */}
        <li className="flex justify-between">
          <p>{t("subtotal")}</p>
          <p id="subtotal">
            {t("price", {
              amount: subtotalPrice,
              currency: getCartCurrency(cart),
            })}
          </p>
        </li>

        {/* tax (if any) */}
        {cart.cost.totalTaxAmount?.amount && (
          <li className="flex justify-between">
            <p>{t("tax")}</p>
            <p>
              {t("price", {
                amount: Math.round(Number(cart.cost.totalTaxAmount.amount)),
                currency: getCartCurrency(cart),
              })}
            </p>
          </li>
        )}

        {summaryDetails.discounts.map((discount, index) => (
          <li key={index}>
            <DiscountItem
              currency={getCartCurrency(cart)}
              discountType={discount.type}
              discountName={discount.name}
              discountedAmount={Math.round(discount.value)}
            />
          </li>
        ))}

        {/* Shipping */}
        {!isAllItemsAreGifts && (
          <li>
            <div className="flex justify-between">
              <p>{t("vip_shipping")}</p>
              {countryData.checkoutMethod === "in-house" &&
                (summaryDetails.totalPriceBeforeShipping >= countryData.shipping.freeLimit ? (
                  <p>
                    <span className="line-through">
                      {t("price", {
                        amount: shippingFees,
                        currency: getCartCurrency(cart),
                      })}
                    </span>
                    <span className="ms-2 font-semibold">{t("free")}</span>
                  </p>
                ) : (
                  <p>
                    {t("price", {
                      amount: shippingFees,
                      currency: getCartCurrency(cart),
                    })}
                  </p>
                ))}
              {countryData.checkoutMethod === "standard" && <p>{t("calculated_at_checkout")}</p>}
            </div>
            {inCheckoutPage && (
              <div>
                <p className="mt-1 text-xs md:mt-0 md:w-2/3">{t("shipping_subtext")}</p>
              </div>
            )}
          </li>
        )}
      </ul>

      {/* Credits */}
      {(creditsToSpend || spentCredits) &&
      creditFactor &&
      draftOrder &&
      user &&
      paymentStep !== PaymentState.successPage ? (
        <div>
          <div className="flex justify-between border-b border-gray-300 py-4">
            <p>{t("credits_amount", { credits: netCredits })}</p>
            <label className="flex items-center gap-2">
              {(loadingSpendCredits || loadingUnspendCredits) && <SpinIcon />}
              <input
                id="summary-details__credits-input"
                type="checkbox"
                className="h-4 w-4 rounded border-black text-black focus:ring-2 focus:ring-black"
                checked={creditsAreUsed}
                onChange={(event) => {
                  if (event.target.checked && creditsToSpend) {
                    enhancedSpendCredits(draftOrder, creditsToSpend, creditFactor);
                  } else if (!event.target.checked && spentCredits) {
                    enhancedUnspendCredits(user.email, draftOrder, spentCredits, creditFactor);
                  }
                }}
              />
              <p>
                {creditsAreUsed
                  ? t("used_credits", { credits: spentCredits })
                  : t("use_credits", { credits: creditsToSpend })}
              </p>
            </label>
          </div>

          {(errorSpendCredits || errorUnspendCredits) && (
            <p className="error-message">{errorSpendCredits ?? errorUnspendCredits}</p>
          )}
        </div>
      ) : (
        <></>
      )}

      {/* total */}
      <div className="flex justify-between pt-4">
        <p className="text-xl font-semibold">{t("total")}</p>
        <p className="font-bold" id="total">
          {t("price", {
            amount: Math.round(summaryDetails.totalPrice),
            currency: getCartCurrency(cart),
          })}
        </p>
      </div>

      {/* Tabby Promo */}
      {countryData?.paymentMethods?.includes(SupportedPaymentMethod.tabby) && (
        <TabbyPromo price={Math.round(summaryDetails.totalPrice)} currency={countryData.currency} />
      )}

      {countryData.checkoutMethod === "in-house" && (
        <div>
          <p className="text-sm">{t("incl_everything")}</p>
        </div>
      )}
    </div>
  );
}

export default memo(SummaryDetails);
