import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import getLocalStorage from "src/helpers/local-storage/getLocalStorage";
import removeFromLocalStorage from "src/helpers/local-storage/removeFromLocalStorage";
import setLocalStorage from "src/helpers/local-storage/setLocalStorage";
import useCountryCode from "src/hooks/useCountryCode";
import {
  DraftOrder,
  DraftOrderFields,
  RegisteringDraftOrder,
  RegisteringShippingAddress,
} from "src/types/shopify-admin";
import { CountryCode } from "src/types/localization/CountryCode";
import { captureException } from "@sentry/nextjs";
import { err, ok, Result } from "neverthrow";
import { APIError } from "src/types/api-client/Error";
import ApiClient from "src/helpers/api-client/apiClient";

const apiClient = new ApiClient();

interface Props {
  children: ReactNode;
}

export interface DraftOrderContextProps {
  draftOrder: DraftOrder | null;
  setDraftOrder: Dispatch<SetStateAction<DraftOrder | null>>;
  isDraftOrderLoading: boolean;
  createDraftOrder(
    countryCode: CountryCode,
    fields: RegisteringDraftOrder
  ): Promise<Result<DraftOrder, APIError>>;
  updateDraftOrder: (
    countryCode: CountryCode,
    draftOrderId: number,
    draftOrderFields: DraftOrderFields
  ) => Promise<Result<DraftOrder, APIError>>;
  resetDraftOrder(): void;
  updateDraftOrderShippingAddress(
    countryCode: string,
    draftOrderId: number,
    shippingAddress: RegisteringShippingAddress,
    email: string
  ): Promise<Result<DraftOrder, APIError>>;
}

export const DraftOrderContext = createContext<DraftOrderContextProps | null>(
  null
);

function DraftOrderProvider({ children }: Props) {
  const countryCode = useCountryCode();

  const [draftOrder, setDraftOrder] = useState<DraftOrder | null>(null);
  const [isDraftOrderLoading, setIsDraftOrderLoading] = useState(true);
  console.log({ draftOrder });

  /**
   * Sets draft order total price and discount title
   * in the local storage for e2e tests
   */
  useEffect(
    function setDraftOrderTotalPriceInStorage() {
      if (draftOrder) {
        setLocalStorage(
          `draft_order_total_price`,
          Number(draftOrder.total_price)
        );
      }
    },
    [draftOrder]
  );

  /**
   * Remove draft order if it's completed
   */
  useEffect(
    function resetCompletedDraftOrder() {
      if (draftOrder && draftOrder.completed_at) {
        resetDraftOrder();
      }
    },
    [draftOrder]
  );

  /**
   * Once the page loads, checks the local storage for a draft order id, and fetches it from Shopify
   */
  const getDraftOrderByLocalStorageId = async (countryCode: CountryCode) => {
    const draftOrderId = getLocalStorage(`draft_order_id_${countryCode}`);
    if (draftOrderId) {
      const draftOrderResult = await getDraftOrder(draftOrderId, countryCode);
      if (draftOrderResult.isOk()) {
        setDraftOrder(draftOrderResult.value);
        setIsDraftOrderLoading(false);
      } else {
        resetDraftOrder();
        setIsDraftOrderLoading(false);
      }
    } else {
      setDraftOrder(null);
      setIsDraftOrderLoading(false);
    }
  };

  useEffect(() => {
    if (countryCode) getDraftOrderByLocalStorageId(countryCode);
  }, [countryCode]);

  const getDraftOrder = async (
    draftOrderId: number,
    countryCode: CountryCode
  ) => {
    const response = await apiClient.get<{
      success: boolean;
      draftOrder?: DraftOrder;
      message: string;
    }>(
      `/api/shopify-admin/get-draft-order/?countryCode=${countryCode}&draftOrderId=${draftOrderId}`
    );

    if (response.isErr()) {
      const errorMsg = `${response.error.errorMessage}. Draft order ID: ${draftOrderId}`;
      captureException(errorMsg);
      console.error(errorMsg);
      return err(response.error);
    }

    if (!response.value.draftOrder) {
      const errorMsg = `${response.value.message}. Draft order ID: ${draftOrderId}`;
      captureException(errorMsg);
      console.error(errorMsg);
      return err({ errorMessage: errorMsg });
    }

    console.log({ getDraftOrder: response.value.draftOrder });
    setDraftOrder(response.value.draftOrder);
    return ok(response.value.draftOrder);
  };

  const createDraftOrder = async (
    countryCode: CountryCode,
    draftOrderFields: RegisteringDraftOrder
  ): Promise<Result<DraftOrder, APIError>> => {
    const response = await apiClient.post<
      { draftOrderFields: RegisteringDraftOrder },
      {
        success: boolean;
        draftOrder?: DraftOrder;
        message: string;
      }
    >(`/api/shopify-admin/create-draft-order/?countryCode=${countryCode}`, {
      draftOrderFields,
    });

    if (response.isErr()) {
      const errorMsg = `${
        response.error.errorMessage
      }. Draft order fields: ${JSON.stringify(draftOrderFields)}`;
      captureException(errorMsg);
      console.error(errorMsg);
      return err(response.error);
    }

    if (!response.value.draftOrder) {
      const errorMsg = `${
        response.value.message
      }. Draft order fields: ${JSON.stringify(draftOrderFields)}`;
      captureException(errorMsg);
      console.error(errorMsg);
      return err({ errorMessage: errorMsg });
    }

    console.log({ createDraftOrder: response.value.draftOrder });
    setLocalStorage(
      `draft_order_id_${countryCode}`,
      response.value.draftOrder.id
    );
    setDraftOrder(response.value.draftOrder);
    return ok(response.value.draftOrder);
  };

  const updateDraftOrder = async (
    countryCode: CountryCode,
    draftOrderId: number,
    draftOrderFields: DraftOrderFields
  ): Promise<Result<DraftOrder, APIError>> => {
    const response = await apiClient.put<
      { fieldsToUpdate: DraftOrderFields },
      {
        success: boolean;
        updatedDraftOrder?: DraftOrder;
        message: string;
      }
    >(
      `/api/shopify-admin/update-draft-order/?countryCode=${countryCode}&draftOrderId=${draftOrderId}`,
      { fieldsToUpdate: draftOrderFields }
    );

    if (response.isErr()) {
      const errorMsg = `${
        response.error.errorMessage
      }. ID: ${draftOrderId}. Fields: ${JSON.stringify(draftOrderFields)}`;
      captureException(errorMsg);
      console.error(errorMsg);
      return err(response.error);
    }

    if (!response.value.updatedDraftOrder) {
      const errorMsg = `${
        response.value.message
      }. ID: ${draftOrderId}. Fields: ${JSON.stringify(draftOrderFields)}`;
      captureException(errorMsg);
      console.error(errorMsg);
      return err({ errorMessage: errorMsg });
    }

    console.log({ updateDraftOrder: response.value.updatedDraftOrder });
    setDraftOrder(response.value.updatedDraftOrder);
    return ok(response.value.updatedDraftOrder);
  };

  const updateDraftOrderShippingAddress = async (
    countryCode: CountryCode,
    draftOrderId: number,
    shippingAddress: RegisteringShippingAddress,
    email: string
  ): Promise<Result<DraftOrder, APIError>> => {
    const response = await apiClient.put<
      { fieldsToUpdate: DraftOrderFields },
      {
        success: boolean;
        updatedDraftOrder?: DraftOrder;
        message: string;
      }
    >(
      `/api/shopify-admin/update-draft-order/?countryCode=${countryCode}&draftOrderId=${draftOrderId}`,
      {
        fieldsToUpdate: {
          shipping_address: shippingAddress,
          email,
        },
      }
    );

    if (response.isErr()) {
      const errorMsg = `${
        response.error.errorMessage
      }. ID: ${draftOrderId}. shipping_address: ${JSON.stringify(
        shippingAddress
      )}, email: ${email}`;
      captureException(errorMsg);
      console.error(errorMsg);
      return err(response.error);
    }

    if (!response.value.updatedDraftOrder) {
      const errorMsg = `${
        response.value.message
      }. ID: ${draftOrderId}. shipping_address: ${JSON.stringify(
        shippingAddress
      )}, email: ${email}`;
      captureException(errorMsg);
      console.error(errorMsg);
      return err({ errorMessage: errorMsg });
    }

    console.log({ updateDraftOrder: response.value.updatedDraftOrder });
    setDraftOrder(response.value.updatedDraftOrder);
    return ok(response.value.updatedDraftOrder);
  };

  const resetDraftOrder = () => {
    setDraftOrder(null);
    removeFromLocalStorage(`draft_order_id_${countryCode}`);
    removeFromLocalStorage("draft_order_total_price");
  };

  return (
    <DraftOrderContext.Provider
      value={{
        draftOrder,
        setDraftOrder,
        isDraftOrderLoading,
        createDraftOrder,
        updateDraftOrder,
        updateDraftOrderShippingAddress,
        resetDraftOrder,
      }}
    >
      {children}
    </DraftOrderContext.Provider>
  );
}

export default DraftOrderProvider;
