import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";
import React, { Fragment } from "react";
import classNames from "src/helpers/classNames";
import useRtl from "src/hooks/useRtl";
import Image from "next/image";

interface ThemeSelectProps {
  options: Option[];
  value: Option;
  buttonClasses?: string;
  iconsClasses?: string;
  containerClasses?: string;
  listOptionsClasses?: string;
  iconDown?: boolean;
  icon?: JSX.Element;
  onChange(option: Option): void;
  error?: boolean;
}

interface Option {
  label: string;
  value: string;
  icon?: string;
}

function ThemeSelect({
  options,
  value,
  buttonClasses = "",
  containerClasses,
  iconsClasses,
  listOptionsClasses,
  icon,
  iconDown,
  onChange,
  error,
}: ThemeSelectProps) {
  const rtl = useRtl();

  return (
    <Listbox value={value} onChange={onChange}>
      <div className={`relative  ${containerClasses}`}>
        <Listbox.Button
          className={classNames(
            "relative w-full cursor-default rounded-sm border border-gray-300 px-4 py-2 text-start focus:outline-none focus-visible:border-gray-700 focus-visible:ring-1 focus-visible:ring-gray-700",
            buttonClasses,
            error
              ? "appearance-none border border-red-400 text-base text-gray-900 shadow-sm"
              : ""
          )}
        >
          <div className="flex items-center gap-2">
            {icon}
            <span
              className={`block truncate ${
                value.value ? "text-gray-900" : "text-gray-500"
              }`}
              id="select_span"
            >
              {value.label}
            </span>
          </div>

          <span
            className={classNames(
              rtl ? "left-0" : "right-0",
              `pointer-events-none absolute inset-y-0 flex items-center pe-2 ${iconsClasses}`
            )}
          >
            {iconDown ? (
              <ChevronDownIcon
                className="h-4 w-4 text-gray-700"
                aria-hidden="true"
              />
            ) : (
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            )}
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={`absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${listOptionsClasses}`}
          >
            {options.map((option) => (
              <Listbox.Option
                key={option.value}
                value={option}
                className={({ active }) =>
                  classNames(
                    "relative cursor-pointer select-none py-2 pe-4 ps-7",
                    active ? "bg-beige-100 text-beige-900" : "text-gray-900",
                    `theme_select__${option}`
                  )
                }
              >
                {({ selected }) => (
                  <div className="flex items-center gap-2">
                    {option.icon && (
                      <Image
                        className="w-6 ps-1"
                        src={option.icon}
                        alt="option icon"
                        width={0}
                        height={0}
                        sizes="2rem"
                        priority
                      />
                    )}
                    <span
                      className={`ms-2 block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {option.label}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center ps-3 text-beige-800">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

export default ThemeSelect;
