import { DraftOrder } from "src/types/shopify-admin";

export default function isDiscountNameAppliedToDraftOrder(
  draftOrder: DraftOrder,
  discountName: string
): boolean {
  const { applied_discount } = draftOrder;
  if (applied_discount?.title?.includes(discountName)) {
    return true;
  } else {
    return false;
  }
}
