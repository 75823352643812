import { useEffect, useState } from "react";
import koalaApiClient from "src/apis/koala-api-client";
import { KoalaVariant } from "src/types/koala/KoalaVariant";

export default function useLineItemVariant(variantId: string) {
  const [variant, setVariant] = useState<KoalaVariant | null>(null);

  useEffect(function getAndSetVariant() {
    koalaApiClient.getVariantById(variantId).then(function cb(result) {
      if (result.isOk()) {
        setVariant(result.value);
      }
    });
  }, []);

  return variant;
}
