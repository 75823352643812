import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import getLocalStorage from "src/helpers/local-storage/getLocalStorage";
import removeFromLocalStorage from "src/helpers/local-storage/removeFromLocalStorage";
import useCountryCode from "src/hooks/useCountryCode";
import usePrevious from "src/hooks/usePrevious";
import { BillingAddress } from "./billingAddress";
import { DraftOrderContext, DraftOrderContextProps } from "./draftOrder";
import useEnhancedAsync from "src/hooks/useEnhancedAsync";
import { CountryCode } from "src/types/localization/CountryCode";
import { ShemsiApiClient } from "src/apis/shemsi-api-client";
import { captureException } from "@sentry/nextjs";
import { DraftOrder } from "src/types/shopify-admin";
import useCountryData from "src/hooks/useCountryData";
import Country from "src/helpers/localization-helpers/countryClass";
import { useStoreState } from "src/hooks/storeHooks";

export interface CreditCardProcessingContextProps {
  stripePaymentIntent?: string;
  setPaymentIntentId(s: string): void;
  paymentIntentId: string;
  setClientSecret(secret: string): void;
  updatePaymentIntentWithBillingAddress(address: BillingAddress): Promise<void>;
  clientSecret: string;
  isLoadingPaymentIntent: boolean;
  resetStripeKeys(): void;
}

export const CreditCardProcessingContext = createContext<CreditCardProcessingContextProps | null>(null);

interface CreditCardProcessingProvider {
  children: ReactNode;
}

const shemsiApiClient = new ShemsiApiClient();

async function retreivePaymentIntentFromLocalStorage(
  countryCode: CountryCode,
  setPaymentIntentId: Dispatch<SetStateAction<string>>,
  setClientSecret: Dispatch<SetStateAction<string>>,
  setIsLoadingPaymentIntent: Dispatch<SetStateAction<boolean>>
) {
  const storagePaymentId = getLocalStorage(`stripe_payment_id_${countryCode}`);

  if (storagePaymentId) {
    setIsLoadingPaymentIntent(true);
    const result = await shemsiApiClient.retreiveStripePaymentIntent(storagePaymentId);

    if (result.isErr()) {
      captureException(result.error.errorMessage);
      setIsLoadingPaymentIntent(false);
      return;
    }

    const { paymentIntentId, clientSecret, completed } = result.value;
    if (completed) {
      removeFromLocalStorage(`stripe_payment_id_${countryCode}`);
    } else {
      setPaymentIntentId(paymentIntentId);
      setClientSecret(clientSecret);
    }
    setIsLoadingPaymentIntent(false);
  }
}

export default function CreditCardProcessingProvider({ children }: CreditCardProcessingProvider) {
  const countryCode = useCountryCode();
  const countryData = useCountryData();
  const { draftOrder } = useContext(DraftOrderContext) as DraftOrderContextProps;
  const cart = useStoreState((state) => state.cart);

  const [paymentIntentId, setPaymentIntentId] = useState<string>("");
  const [clientSecret, setClientSecret] = useState<string>("");
  const [isLoadingPaymentIntent, setIsLoadingPaymentIntent] = useState(false);

  /**
   * Checks if stripe_payment_id_{countryCode} exists in the local storage
   * and retreives the associated payment intent
   */
  useEffect(() => {
    if (!countryCode || !draftOrder) return;

    retreivePaymentIntentFromLocalStorage(countryCode, setPaymentIntentId, setClientSecret, setIsLoadingPaymentIntent);
  }, [countryCode, draftOrder]);

  /**
   * This method returns nothing and runs asynchronously to keep the payment intent on credit card platform's updated with the intended amount from the cart.
   */
  const updatePaymentIntentWithBillingAddress = async (address: BillingAddress) => {
    const draftOrderId = draftOrder?.id;
    const oms = countryData?.oms;
    if (paymentIntentId && paymentIntentId.length > 0 && countryCode) {
      if (oms === "shopify" && draftOrderId) {
        await shemsiApiClient.updateStripePaymentIntent(countryCode, paymentIntentId, draftOrderId, address);
      }
      if (oms === "koala" && cart) {
        await shemsiApiClient.updateKoalaStripePaymentIntent(countryCode, paymentIntentId, cart._id, address);
      }
    }
  };

  const [updateInventory] = useEnhancedAsync(
    async (countryData: Country, paymentIntentId: string, draftOrder: DraftOrder) => {
      const updatePaymentResult = await shemsiApiClient.updateStripePaymentIntent(
        countryData.code,
        paymentIntentId,
        draftOrder.id
      );

      if (updatePaymentResult.isErr()) {
        throw new Error(updatePaymentResult.error.errorMessage);
      }
    },
    { retry: true }
  );

  const [updateKoalaInventory] = useEnhancedAsync(
    async (countryData: Country, paymentIntentId: string, cartId: string) => {
      const updatePaymentResult = await shemsiApiClient.updateKoalaStripePaymentIntent(
        countryData.code,
        paymentIntentId,
        cartId
      );

      if (updatePaymentResult.isErr()) {
        throw new Error(updatePaymentResult.error.errorMessage);
      }
    },
    { retry: true }
  );

  /**
   * Update Stripe inventory if the total amount of the draft order changes
   */
  const previousDraftOrder = usePrevious(draftOrder);
  useEffect(() => {
    if (
      paymentIntentId &&
      draftOrder &&
      countryData &&
      previousDraftOrder &&
      previousDraftOrder.total_price !== draftOrder.total_price
    ) {
      updateInventory(countryData, paymentIntentId, draftOrder);
    }
  }, [draftOrder]);

  const previousCart = usePrevious(cart);
  useEffect(() => {
    if (paymentIntentId && cart && countryData && previousCart && previousCart.totalPrice !== cart.totalPrice) {
      updateKoalaInventory(countryData, paymentIntentId, cart._id);
    }
  }, [cart]);

  const resetStripeKeys = () => {
    setPaymentIntentId("");
    setClientSecret("");
    removeFromLocalStorage(`stripe_payment_id_${countryCode}`);
  };

  return (
    <CreditCardProcessingContext.Provider
      value={{
        updatePaymentIntentWithBillingAddress,
        setPaymentIntentId,
        paymentIntentId,
        setClientSecret,
        clientSecret,
        isLoadingPaymentIntent,
        resetStripeKeys,
      }}
    >
      {children}
    </CreditCardProcessingContext.Provider>
  );
}
