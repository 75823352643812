import Image from "next/image";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useTranslations } from "next-intl";
import classNames from "src/helpers/classNames";
import useRtl from "src/hooks/useRtl";
import { XMarkIcon } from "@heroicons/react/24/outline";

type TabbyModalProps = {
  splitPrice: number;
  currency: string;
};

const tabbyGraphItems = [
  {
    translationKey: "today",
    iconSrc: "/images/icons/pie-chart-1.svg",
  },
  {
    translationKey: "in_1_month",
    iconSrc: "/images/icons/pie-chart-2.svg",
  },
  {
    translationKey: "in_2_months",
    iconSrc: "/images/icons/pie-chart-3.svg",
  },
  {
    translationKey: "in_3_months",
    iconSrc: "/images/icons/pie-chart-4.svg",
  },
];

const tabbyPaymentSteps = [
  {
    translationKey: "step_1",
    hasLogo: true,
  },
  {
    translationKey: "step_2",
  },
  {
    translationKey: "step_3",
  },
  {
    translationKey: "step_4",
  },
];

const tabbyPaymentMethods = [
  { label: "Mastercard", iconSrc: "/images/icons/mastercard.svg" },
  { label: "Visa", iconSrc: "/images/icons/visa.svg" },
  { label: "Apple Pay", iconSrc: "/images/icons/apple-pay.svg" },
];

export default function TabbyModal({ splitPrice, currency }: TabbyModalProps) {
  const t = useTranslations("Tabby");
  const isRtl = useRtl();

  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <button className="underline" type="button" onClick={openModal}>
        Learn more
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex w-full max-w-xl transform flex-col gap-y-7 overflow-hidden rounded-lg bg-white p-5 text-left align-middle shadow-xl transition-all sm:p-6">
                  {/* Close button */}
                  <button
                    className={classNames(
                      "absolute top-4 flex h-10 w-10 rounded-full bg-gray-300 transition hover:bg-gray-400 focus:bg-gray-400",
                      isRtl ? "left-6" : "right-5"
                    )}
                    type="button"
                    onClick={closeModal}
                  >
                    <XMarkIcon className="m-auto w-6" />
                  </button>

                  {/* Logo */}
                  <Image
                    src={"/images/logos/tabby.svg"}
                    alt="Tabby"
                    width={0}
                    height={0}
                    className="w-20"
                  />

                  {/* Titles */}
                  <hgroup className="text-start">
                    <h3 className="title-2 mb-1">
                      {t("tabby_modal_title", {
                        splitPrice,
                        currency,
                      })}
                    </h3>
                    <h4>{t("tabby_modal_subtitle")}</h4>
                  </hgroup>

                  {/* Payments graph */}
                  <ul className="flex gap-x-4 sm:gap-x-7">
                    {tabbyGraphItems.map(
                      ({ translationKey, iconSrc }, index, array) => (
                        <li
                          key={translationKey}
                          className="flex flex-col items-center"
                        >
                          <div
                            className={classNames(
                              "relative mb-1 flex items-center",
                              index < array.length - 1
                                ? `after:absolute ${
                                    isRtl ? "after:right-9" : "after:left-9"
                                  } after:h-[1px] after:w-20 after:bg-gray-400 after:content-['']`
                                : ""
                            )}
                          >
                            <Image
                              src={iconSrc}
                              alt=""
                              className="relative z-10 w-12"
                              width={0}
                              height={0}
                            />
                          </div>
                          <p className="text-sm font-semibold">
                            {splitPrice} {currency}
                          </p>
                          <p className="text-sm">{t(translationKey)}</p>
                        </li>
                      )
                    )}
                  </ul>

                  {/* How it works */}
                  <div className="space-y-4">
                    <h4 className="title-3 text-start">{t("how_it_works")}:</h4>
                    <ul className="space-y-3">
                      {tabbyPaymentSteps.map(
                        ({ translationKey, hasLogo }, index) => (
                          <li
                            key={translationKey}
                            className="flex items-center"
                          >
                            {/* index */}
                            <span className="flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-gray-300 text-sm font-semibold me-4">
                              {index + 1}
                            </span>
                            {/* text */}
                            <p>
                              {t.rich(
                                translationKey,
                                hasLogo
                                  ? {
                                      logo: () => (
                                        <Image
                                          src={"/images/logos/tabby.svg"}
                                          alt="Tabby"
                                          width={0}
                                          height={0}
                                          className="inline-block w-12"
                                        />
                                      ),
                                    }
                                  : undefined
                              )}
                            </p>
                          </li>
                        )
                      )}
                    </ul>
                  </div>

                  {/* divider */}
                  <hr />

                  {/* Supported payment methods */}
                  <div className="flex items-center justify-between">
                    <p>{t("supported_payment_methods")}</p>

                    <ul className="flex gap-2.5">
                      {tabbyPaymentMethods.map(({ label, iconSrc }) => (
                        <li key={label}>
                          <Image
                            src={iconSrc}
                            alt={label}
                            width={0}
                            height={0}
                            className="w-8"
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
