import { TrashIcon } from "@heroicons/react/24/outline";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import SpinIcon from "src/components/icon/SpinIcon";
import QuantityControl from "src/components/quantity-control/QuantityControl";
import { CartContext, CartContextProps } from "src/context/cart";
import classNames from "src/helpers/classNames";
import EventTracker from "src/helpers/event-tracking/eventTrackingManager";
import spaceToUnderscore from "src/helpers/strings/spaceToUnderscore";
import useEnhancedAsync from "src/hooks/useEnhancedAsync";
import useRtl from "src/hooks/useRtl";
import { Line } from "src/types/storefront/Cart";
import { TagIcon } from "@heroicons/react/24/solid";
import useCountryData from "src/hooks/useCountryData";
import generatePriceBeforeDiscount from "src/helpers/generatePriceBeforeDiscount";
import { BundleLine } from "src/types/bundle-discounts/BundleCart";
import { BundleDiscountContext } from "src/context/BundleDiscountContext";
import getBundleItemById from "src/helpers/bundle-discounts/getBundleItemById";
import { StoreMetadataContext } from "src/context/StoreMetadataContext";
import getVariantBySkuFromCollection from "src/helpers/storefront-helpers/getVariantBySkuFromCollection";
import Cookies from "js-cookie";
import consts from "src/config/consts";
import useGrowthbookFeature from "src/hooks/useGrowthbookFeature";
import getImagesBySku from "src/helpers/storefront-helpers/getVariantImages";
import { ProductType } from "src/types/product-types/ProductType";

type CartSideBarItemProps = {
  item: Line;
  removeFromCart?: boolean;
};

function CartSideBarItem({
  item,
  removeFromCart = false,
}: CartSideBarItemProps) {
  const t = useTranslations("CartSidebar");
  const countryData = useCountryData();
  const rtl = useRtl();

  const { cart, updateItemsQuantity } = useContext(
    CartContext
  ) as CartContextProps;
  const storeMetdata = useContext(StoreMetadataContext);
  const isNewPriceTestActive = Cookies.get("is_price_test_active") === "true";

  const [handleUpdateItemQuantity, updateQuantityLoading, updateQuantityError] =
    useEnhancedAsync(
      (newQuantity: number) =>
        updateItemsQuantity([{ id: item.id, quantity: newQuantity }]),
      { customError: t("something_went_wrong") }
    );

  const variant = item.merchandise;

  const [quantityInput, setQuantityInput] = useState<"" | number>(
    item.quantity
  );
  const [quantityError, setQuantityError] = useState<string | null>(null);
  const removeItemFromCart = () => {
    handleUpdateItemQuantity(0);
    EventTracker.removeFromCart(variant.product);
  };
  /**
   * Sync item.quantity with item.merchandise.quantityAvailable
   */
  useEffect(
    function correctItemQuantity() {
      // if (quantity > item.quantityAvailable)
      setQuantityInput(item.quantity);
    },
    [cart]
  );

  const handleQuantityInputChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    const inputToInteger = parseInt(value);
    const isInteger = Number.isInteger(inputToInteger);
    if (isInteger) {
      if (inputToInteger > variant.quantityAvailable) {
        setQuantityError(t("max_quantity_message"));
      } else if (inputToInteger < 1) {
        handleUpdateItemQuantity(0);
      } else {
        await handleUpdateItemQuantity(inputToInteger);
        setQuantityInput(inputToInteger);
      }
    } else if (value === "") {
      setQuantityInput(value);
    }
  };

  const handleQuantityPlusClick = async () => {
    if (quantityInput === "") {
      await handleUpdateItemQuantity(1);
      setQuantityInput(1);
    } else if (Number.isInteger(quantityInput)) {
      if (item.quantity >= variant.quantityAvailable)
        setQuantityError(t("max_quantity_message"));
      else {
        await handleUpdateItemQuantity(Number(quantityInput) + 1);
        setQuantityInput((oldQuantity) => Number(oldQuantity) + 1);
      }
    }
  };

  const handleQuantityMinusClick = async () => {
    if (quantityInput === "") {
      setQuantityError(t("min_quantity_message"));
    } else if (Number.isInteger(quantityInput)) {
      if (quantityInput === 1) {
        removeItemFromCart();
      } else {
        await handleUpdateItemQuantity(Number(quantityInput) - 1);
        setQuantityInput((oldQuantity) => Number(oldQuantity) - 1);
      }
    }
  };

  // If bundle discount applied, get item with bundle discount data
  const bundleCart = useContext(BundleDiscountContext);
  let bundleCartItem: BundleLine | null = null;
  if (bundleCart) {
    bundleCartItem = getBundleItemById(bundleCart, item.id);
  }

  let currency = variant.price.currencyCode;
  if (currency === "USD") {
    currency = "$";
  }

  /**
   * price, priceBeforeDiscount
   */
  let price: number = bundleCartItem
    ? bundleCartItem.bundlePrice
    : Number(variant.price.amount);
  let priceBeforeDiscount: number | null = null;

  // If country supports the fake discount, update price before discount

  // const isFakeDiscountTestActive = useGrowthbookFeature("fake-discount", false);

  if (
    countryData?.splitTestDiscount &&
    item.merchandise.product.handle !== ProductType.GiftCard
  ) {
    priceBeforeDiscount = generatePriceBeforeDiscount(
      Number(variant.price.amount),
      consts.FAKE_DISCOUNT_PERCENTAGE_VALUE
    );
  }

  /**
   * Product title
   */
  let productTitle = variant.product.title;

  if (isNewPriceTestActive && storeMetdata) {
    const originalVariant = getVariantBySkuFromCollection(
      storeMetdata,
      variant.sku
    );
    if (originalVariant) {
      productTitle = originalVariant.product.title;
    }
  }

  /**
   * images
   */
  const variantImages = getImagesBySku(variant.sku, variant.product.images);

  return (
    <li>
      <div className="flex items-start">
        <Image
          className="w-1/3 shrink-0 me-5"
          src={variantImages[0].url}
          alt={""}
          sizes={"150px"}
          width={0}
          height={0}
        />
        <div className="flex flex-col space-y-2">
          <p className="font-semibold">{productTitle}</p>

          <p className="flex">
            {variant.selectedOptions
              .filter((option) =>
                consts.TRANSLATED_VARIANT_OPTIONS.includes(option.name)
              )
              .map((option) => (
                <span
                  key={option.name}
                  className={classNames(
                    "text-sm pe-2 me-2 last:border-none last:pe-0",
                    rtl ? "border-l" : "border-r"
                  )}
                >
                  {t(spaceToUnderscore(option.value))}
                </span>
              ))}
          </p>
          {item.merchandise.sku.startsWith(consts.GIFT_CARD_SKU_PREFIX) ? (
            item.attributes.find(
              (attribute) => attribute.key === "is_self_sent"
            )?.value === "true" ? (
              <span>{t("gift_card_sent_to_you")}</span>
            ) : (
              <span className="text-xs lg:text-sm">
                {t("gift_card_sent_to_receiver", {
                  receiverEmail: item.attributes.find(
                    (attribute) => attribute.key === "gift_card_receiver_email"
                  )?.value,
                })}
              </span>
            )
          ) : (
            <></>
          )}
          <div className="flex items-center gap-2">
            {/* Generated Price */}

            {priceBeforeDiscount ? (
              <p className="text-sm font-semibold text-red-500 line-through">
                {t("price", {
                  amount: Math.round(priceBeforeDiscount),
                  currency,
                })}
              </p>
            ) : (
              bundleCartItem && (
                <p className="text-sm font-semibold text-gray-500 line-through">
                  {t("price", {
                    amount: Math.round(Number(variant.price.amount)),
                    currency,
                  })}
                </p>
              )
            )}

            <p className="font-semibold">
              {t("price", {
                amount: Math.round(price),
                currency,
              })}
            </p>
          </div>
          <div className="flex gap-2">
            {bundleCartItem && (
              <span className={classNames(`discount-tag`)}>
                <TagIcon className="h-4 w-4 fill-green-100 stroke-green-900" />-
                {bundleCartItem.bundleDiscount.amount}%
              </span>
            )}
          </div>
          <div className="flex items-center">
            {/* Quantity control */}
            <QuantityControl
              quantity={quantityInput}
              quantityError={quantityError}
              setQuantityError={setQuantityError}
              onInputChange={handleQuantityInputChange}
              onPlusClick={handleQuantityPlusClick}
              onMinusClick={handleQuantityMinusClick}
              disabled={
                updateQuantityLoading ||
                item.quantity > variant.quantityAvailable
              }
              outOfStock={item.quantity > variant.quantityAvailable}
            />
            {/* Remove from cart button */}
            {removeFromCart && (
              <button
                type="button"
                disabled={
                  updateQuantityLoading ||
                  item.quantity > variant.quantityAvailable
                }
                className="rounded-sm ms-6"
                onClick={() => removeItemFromCart()}
              >
                <span className="sr-only">Remove item</span>
                <TrashIcon
                  className={classNames(
                    "h-6 w-6",
                    item.quantity > variant.quantityAvailable
                      ? "stroke-red-700"
                      : "stroke-gray-900"
                  )}
                  aria-hidden="true"
                />
              </button>
            )}

            {/* Spinner */}
            {updateQuantityLoading && (
              <SpinIcon className="!text-gray-900 ms-4" />
            )}
          </div>
          {/* Out of stock message */}
          {variant.quantityAvailable === 0 && (
            <p className="text-sm text-red-600">{t("variant_out_of_stock")}</p>
          )}
          {variant.quantityAvailable > 0 &&
            item.quantity > variant.quantityAvailable && (
              <p className="text-sm text-red-600">
                {t("variant_not_enough_quantity", {
                  quantityAvailable: variant.quantityAvailable,
                })}
              </p>
            )}
        </div>
      </div>

      {updateQuantityError && (
        <p className="error-message">{updateQuantityError}</p>
      )}
      {quantityError && <p className="error-message">{quantityError}</p>}
    </li>
  );
}

export default CartSideBarItem;
