import { CountryCode } from "src/types/localization/CountryCode";
import { Locale } from "src/types/localization/Locale";
import getDeployments from "./getDeployments";

export default function getCountryCodeFromLocale(locale: Locale): CountryCode {
  const countryCode = locale.substring(3) as CountryCode;
  if (getDeployments().includes(countryCode)) {
    return countryCode as CountryCode;
  } else {
    throw new Error(
      `${countryCode} is not one of the country codes defined in the config.`
    );
  }
}
