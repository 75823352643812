import { ReactNode, createContext } from "react";
import { Collection } from "src/types/storefront";

interface Props {
  children: ReactNode;
  newPriceCollection: Collection;
}

export const NewPriceCollectionContext = createContext<Collection | null>(null);

export default function NewPriceCollectionProvider({
  children,
  newPriceCollection,
}: Props) {
  return (
    <NewPriceCollectionContext.Provider value={newPriceCollection}>
      {children}
    </NewPriceCollectionContext.Provider>
  );
}
