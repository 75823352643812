import { GrowthBook } from "@growthbook/growthbook";
import Cookies from "js-cookie";
import { createContext, ReactNode, useEffect, useState } from "react";
import initializeGrowthbookClient from "src/helpers/growthbook/initializeGrowthbookClient";
import useCountryCode from "src/hooks/useCountryCode";
import useEnhancedAsync from "src/hooks/useEnhancedAsync";

interface GrowthbookClientContextProps {
  children: ReactNode;
}

export interface GrowthbookClientProps {
  growthbook: GrowthBook | null;
  isGrowthbookLoading: boolean;
  visitorId: string;
}

export const GrowthbookClientContext = createContext<GrowthbookClientProps>(
  {} as GrowthbookClientProps
);

export function GrowthbookClientProvider({
  children,
}: GrowthbookClientContextProps) {
  const env = process.env.NEXT_PUBLIC_ENV;

  const countryCode = useCountryCode();
  const visitor_id = Cookies.get("visitor_id");

  const [growthbook, setGrowthbook] = useState<GrowthBook | null>(null);
  const [isGrowthbookLoading, setIsGrowthbookLoading] = useState(
    env === "ci" ? false : true
  );
  const [visitorId, setVisitorId] = useState("");

  const [handleInitializeGrowthbook] = useEnhancedAsync(
    async (visitor_id: string) => {
      const growthbookInstance = await initializeGrowthbookClient(visitor_id);
      setIsGrowthbookLoading(false);
      return growthbookInstance;
    },
    { retry: true, retryCount: 10, retryTimeout: 5000 }
  );

  useEffect(() => {
    if (visitor_id) {
      setVisitorId(visitor_id);
    }
  }, [visitor_id]);

  useEffect(() => {
    if (env !== "ci" && visitor_id) {
      handleInitializeGrowthbook(visitor_id).then(setGrowthbook);
    }
  }, [countryCode, visitor_id]);

  return (
    <GrowthbookClientContext.Provider
      value={{
        growthbook,
        isGrowthbookLoading,
        visitorId,
      }}
    >
      {children}
    </GrowthbookClientContext.Provider>
  );
}
