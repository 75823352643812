import { Product, Variant } from "src/types/storefront";

export default function getCheapestVariant(product: Product): Variant {
  let cheapestVariant: Variant = product.variants[0];

  for (const variant of product.variants) {
    if (variant.price < cheapestVariant.price) {
      cheapestVariant = variant;
    }
  }

  return cheapestVariant;
}
