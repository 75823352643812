import { RadioGroup } from "@headlessui/react";
import classNames from "src/helpers/classNames";
import { ValueWithStock } from "src/helpers/inventory-helpers/getOptionValuesWithStock";

type Props = {
  selectedColor: string;
  setSelectedColor(color: string): void;
  valuesWithStock: ValueWithStock[];
  circleClassName?: string;
  optionClassName?: string;
};

type ProductsColors = {
  [key: string]: {
    bgClassName: string;
    ringClassName: string;
  };
};

export const colors: ProductsColors = {
  Black: {
    bgClassName: "bg-gray-800",
    ringClassName: "bg-gray-800",
  },
  Beige: {
    bgClassName: "bg-beige-450",
    ringClassName: "bg-beige-450",
  },
  Pink: {
    bgClassName: "bg-pink-400",
    ringClassName: "bg-pink-400",
  },
  Purple: {
    bgClassName: "bg-purple-700",
    ringClassName: "bg-purple-700",
  },
  Maroon: {
    bgClassName: "bg-maroon-800",
    ringClassName: "bg-maroon-800",
  },
};

function ColorPicker({
  selectedColor,
  setSelectedColor,
  valuesWithStock,
  circleClassName = "",
  optionClassName = "",
}: Props) {
  return (
    <RadioGroup value={selectedColor} onChange={setSelectedColor} className="mt-2">
      <RadioGroup.Label className="sr-only">Choose a color</RadioGroup.Label>
      <div className="flex items-center">
        {valuesWithStock.length > 0 &&
          valuesWithStock.map(({ value, stock }, index) => {
            return (
              <RadioGroup.Option
                key={value}
                value={value}
                className={({ active, checked, disabled }) =>
                  classNames(
                    "ring-pruple-900 relative flex items-center justify-center rounded-full border p-0.5 focus:outline-none sm:p-1",
                    (active || checked) && !disabled ? "border-gray-900" : "border-transparent",
                    disabled
                      ? "cursor-default before:absolute before:left-1 before:right-1 before:h-[1px] before:-rotate-45 before:bg-gray-800 before:content-['']"
                      : "cursor-pointer",
                    optionClassName
                  )
                }
                disabled={stock === 0}
              >
                <RadioGroup.Label as="span" className="sr-only">
                  {value}
                </RadioGroup.Label>
                <span
                  aria-hidden="true"
                  className={classNames(
                    "h-5 w-5 rounded-full border border-black border-opacity-10 sm:h-6 sm:w-6",
                    stock === 0 ? "opacity-50" : "",
                    colors[value].bgClassName,
                    circleClassName
                  )}
                />
              </RadioGroup.Option>
            );
          })}
      </div>
    </RadioGroup>
  );
}

export default ColorPicker;
