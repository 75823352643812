enum MixpanelEventsEnum {
  VIEW_ITEM_DETAIL_PAGE = "View Item Detail Page",
  PAGE_VIEW = "Page View",
  ADD_ITEM_TO_CART = "Add Item To Cart",
  VISIT = "Visit",
  CHANGE_VARIANT = "Variant Change",
  VIEW_CHECKOUT_PAGE = "View Checkout Page",
  ADD_SHIPPING_INFO = "Add Shipping Info",
  ADD_PAYMENT_INFO = "Add Payment Info",
  COMPLETE_PURCHASE = "Complete Purchase Fallback",
  SUBSCRIBED_NEWSLETTER = "Subscribed To Newsletter",
  SENT_EMAIL_USING_FORM = "Sent Contact E-mail",
  VISIT_DR_SHEMSI = "Visit Dr Shemsi",
  DR_SHEMSI_SURVEY_SUBMISSION = "Dr Shemsi Survey Submission",
  ASK_DR_SHEMSI = "Ask Dr Shemsi",
  JOIN_DR_SHEMSI_WAITING_LIST = "Join Dr Shemsi Waiting List",
  ERROR = "Error",
  VIDEO_PLAY = "Video Play",
  EXPERIMENT_START = "$experiment_started",
  COPY_REFERRAL_LINK = "Copy Referral Link",
  SHARE_REFERRAL_LINK = "Share Referral Link",
  CREATE_BUNDLE = "Create Bundle",
}

export default MixpanelEventsEnum;
