import {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useEffect,
	useState,
} from "react";
import getLocalStorage from "src/helpers/local-storage/getLocalStorage";
import setLocalStorage from "src/helpers/local-storage/setLocalStorage";

export interface country {
  label: string;
  value: string;
}

export interface BillingAddress {
  shipping: {
    line1: string;
    line2?: string;
    country: country;
    postal_code: string;
    city: string;
    state: string;
  }
  name: string;

}

export interface BillingAddressContextProps {
	billingAddress: BillingAddress | null;
	setBillingAddress: Dispatch<SetStateAction<BillingAddress | null>>;
}

export const BillingAddressContext =
	createContext<BillingAddressContextProps | null>(null);

interface BillingAddressProviderProps {
	children: ReactNode;
}

export default function BillingAddressProvider({
	children,
}: BillingAddressProviderProps) {
	const [billingAddress, setBillingAddress] =
		useState<BillingAddress | null>(getLocalStorage("billing-address"));

	useEffect(() => {
		if (billingAddress) setLocalStorage("billing-address", billingAddress);
	}, [billingAddress]);

	return (
		<BillingAddressContext.Provider
			value={{ billingAddress, setBillingAddress }}
		>
			{children}
		</BillingAddressContext.Provider>
	);
}
