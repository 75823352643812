import gql from "src/helpers/gql";
import withoutEdgesAndNodes from "src/helpers/storefront-helpers/withoutEdgesAndNodes";
import { Cart, StorefrontCart } from "src/types/storefront/Cart";
import storefront from "../storefront";
import { CountryCode } from "src/types/localization/CountryCode";

const query = gql`
  query ($cartId: ID!) {
    cart(id: $cartId) {
      id
      createdAt
      updatedAt
      lines(first: 100) {
        edges {
          node {
            id
            attributes {
              key
              value
            }
            quantity
            discountAllocations {
              discountedAmount {
                amount
                currencyCode
              }
            }
            merchandise {
              ... on ProductVariant {
                id
                sku
                image {
                  url
                  altText
                }
                product {
                  handle
                  title
                  images(first: 50) {
                    edges {
                      node {
                        altText
                        url
                      }
                    }
                  }
                  variants(first: 1) {
                    edges {
                      node {
                        id
                        sku
                        price {
                          amount
                          currencyCode
                        }
                        compareAtPrice {
                          amount
                          currencyCode
                        }
                        selectedOptions {
                          name
                          value
                        }
                        quantityAvailable
                      }
                    }
                  }
                }
                price {
                  amount
                  currencyCode
                }
                compareAtPrice {
                  amount
                  currencyCode
                }
                selectedOptions {
                  name
                  value
                }
                quantityAvailable
              }
            }
          }
        }
      }
      attributes {
        key
        value
      }
      cost {
        subtotalAmount {
          amount
          currencyCode
        }
        totalTaxAmount {
          amount
          currencyCode
        }
        totalAmount {
          amount
          currencyCode
        }
      }
      discountCodes {
        applicable
        code
      }
      discountAllocations {
        discountedAmount {
          amount
          currencyCode
        }
      }
    }
  }
`;

interface GetCartResponse {
  data: {
    cart: StorefrontCart | null;
  };
}

export default async function getCart(
  countryCode: CountryCode,
  cartId: string
): Promise<Cart | null> {
  const getCartResponse: GetCartResponse = await storefront(
    countryCode,
    query,
    { cartId }
  );
  if (!getCartResponse.data.cart) {
    return null;
  }
  const cart = withoutEdgesAndNodes(getCartResponse.data.cart);

  return cart;
}
