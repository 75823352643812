import { ReferralConfig } from "src/types/localization/ReferralConfig";
import preciseDecimal from "../preciseDecimal";

export default function calculateRefereeCreditsAmount(
  referralConfig: ReferralConfig,
  totalPrice?: number,
  creditFactor?: number
) {
  let result = 0;
  switch (referralConfig.refereeCredits.amountType) {
    case "fixed_amount": {
      result = referralConfig.refereeCredits.amount;
      break;
    }
    case "percentage": {
      if (totalPrice && creditFactor) {
        const totalPriceInCredits = totalPrice / creditFactor;
        result =
          (referralConfig.refereeCredits.amount / 100) * totalPriceInCredits;
      }
      break;
    }
  }
  return preciseDecimal(result);
}
