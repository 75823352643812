import getLocalStorage from "../local-storage/getLocalStorage";
import { Product } from "src/types/storefront";
import { Cart } from "src/types/storefront/Cart";
import TiktokPixelEvents from "src/types/enums/tiktokPixelEvents.enums";
import { CheckoutUserInformation } from "src/context/CheckoutUserInformation";
import Cookies from "js-cookie";
import { KoalaProduct } from "src/types/koala/KoalaProduct";

declare global {
  interface Window {
    ttq: any;
  }
}

// get visitor id from cookies
const visitor_id = Cookies.get("visitor_id");

// const tiktokPixelClickId = getLocalStorage("ttclid") || "";
const tiktokPixelId = process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID || "";

class TiktokPixelTracking {
  public constructor() {}

  protected tiktokPixelTrack(name?: string, data: object = {}) {
    if (typeof window !== undefined && window.ttq && name) {
      window.ttq.track(name, data);
    }
  }

  protected checkoutDetails(cart: Cart) {
    const cartlineItems: any[] = [];
    cart.lines.map((item) => {
      cartlineItems.push({
        price: item.merchandise.price.amount,
        quantity: item.quantity,
        content_id: item.merchandise.sku,
        content_name: item.merchandise.product.title,
        content_category: "Period Underwear",
        brand: "myshemsi",
      });
    });
    const data = {
      contents: cartlineItems,
      content_type: "product",
      value: cart.cost.totalAmount.amount,
      currency: cart.cost.totalAmount.currencyCode,
    };
    return data;
  }

  protected productDetails(product: Product, quantity: number) {
    const value = Number(product.variants[0].price.amount) * quantity;
    const data = {
      content_id: product.variants[0].sku,
      content_type: "product",
      content_name: product.title,
      price: product.variants[0].price.amount,
      currency: product.variants[0].price.currencyCode,
      quantity: quantity,
      value,
    };
    return data;
  }

  protected koalaProductDetails(product: KoalaProduct, quantity: number) {
    const value = Number(product.variants[0].price) * quantity;
    const data = {
      content_id: product.variants[0].sku,
      content_type: "product",
      content_name: product.name,
      price: product.variants[0].price,
      currency: product.variants[0].currency,
      quantity: quantity,
      value,
    };
    return data;
  }

  public visit() {
    if (typeof window !== undefined && window.ttq) {
      window.ttq.load(tiktokPixelId);
    }
  }

  public pageViewed() {
    if (typeof window !== undefined && window.ttq) {
      window.ttq.page();
    }
  }

  public contact(email: string, source: string, discountPercentage: number) {
    this.tiktokPixelTrack(TiktokPixelEvents.CONTACT, {
      email,
      source,
      discountPercentage,
    });
  }

  public addedToCart(product: Product, quantity: number) {
    this.tiktokPixelTrack(TiktokPixelEvents.ADD_ITEM_TO_CART, this.productDetails(product, quantity));
  }

  public addedToKoalaCart(product: KoalaProduct, quantity: number) {
    this.tiktokPixelTrack(TiktokPixelEvents.ADD_ITEM_TO_CART, this.koalaProductDetails(product, quantity));
  }

  public initiateCheckout(cart: Cart) {
    this.tiktokPixelTrack(TiktokPixelEvents.INITIATE_CHECKOUT, this.checkoutDetails(cart));
  }

  public completeRegistration(cart: Cart, userInformation: CheckoutUserInformation) {
    this.tiktokPixelTrack(TiktokPixelEvents.COMPLETE_REGISTRATION, this.checkoutDetails(cart));
    if (typeof window !== undefined && window.ttq) {
      window.ttq.identify({
        email: userInformation.email,
        phone_number: userInformation.shipping_address.phone,
        external_id: visitor_id,
      });
    }
  }

  public addPaymentInfo(cart: Cart) {
    this.tiktokPixelTrack(TiktokPixelEvents.ADD_PAYMENT_INFO, this.checkoutDetails(cart));
  }
}

const tiktokPixelTracker = new TiktokPixelTracking();
export default tiktokPixelTracker;
