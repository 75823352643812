import Country, { KoalaCollectionConfigs } from "src/helpers/localization-helpers/countryClass";
import { BundleDiscountSystem } from "src/types/bundle-discounts/BundleDiscountSystem";
import { BundleDiscountType } from "src/types/bundle-discounts/BundleDiscountType";
import { SupportedPaymentMethod } from "src/types/enums/paymentMethods.enum";
import { StoreType } from "src/types/enums/stores.enum";
import { CountryCode } from "src/types/localization/CountryCode";
import { CountryName } from "src/types/localization/CountryName";
import { Currency } from "src/types/localization/Currency";
import { LanguageCode } from "src/types/localization/LanguageCode";
import { LanguageName } from "src/types/localization/LanguageName";
import { StoreLocation } from "src/types/store-locations";

const env = process.env.NEXT_PUBLIC_ENV;

const shopifyKeys = {
  STORE_NAME: process.env.NEXT_PUBLIC_SHOPIFY_STORE_NAME_LB ?? "",
  STOREFRONT_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_TOKEN_LB ?? "",
  ADMIN_TOKEN: process.env.SHOPIFY_ADMIN_TOKEN_LB ?? "",
};

const collections = {
  COLLECTION_ALL_ACTIVE_PRODUCTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431060636 : 291651584197,
    name: "all",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ALL_SINGLE_PRODUCTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431158940 : 291651518661,
    name: "all_single",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ALL_PACKS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431060636 : 291651616965,
    name: "packs",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_UNDERWEARS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 298026959004 : 291651518661,
    name: "underwears",
    isActive: true,
    hasSeparateList: false,
    isCategory: false,
    isDefault: true,
    isInPopover: true,
    hasBundleDiscount: true,
  },
  COLLECTION_LADIES: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297751806108 : 291651584197,
    name: "ladies",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_TEENS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297688531100 : 291651584197,
    name: "teens",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_GIFTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 298701947036 : 0,
    name: "gifts",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ACCESSORIES: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297751740572 : 291651584197,
    name: "accessories",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
};

const koalaCollections: KoalaCollectionConfigs = {
  COLLECTION_ALL_ACTIVE_PRODUCTS: {
    id: ["production", "preproduction"].includes(env) ? "66614bbad9470fe2c0ea8a6f" : "6659ee257d16c96d87f57e6f",
    name: "all",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_UNDERWEARS: {
    id: ["production", "preproduction"].includes(env) ? "666186825e55f7f77c3b1460" : "6659ee1f7d16c96d87f57e6d",
    name: "underwears",
    isActive: true,
    hasSeparateList: false,
    isCategory: false,
    isDefault: true,
    isInPopover: false,
    hasBundleDiscount: true,
  },
  COLLECTION_LADIES: {
    id: ["production", "preproduction"].includes(env) ? "666186895e55f7f77c3b1462" : "6659ee257d16c96d87f57e6f",
    name: "ladies",
    isActive: true,
    hasSeparateList: false,
    isCategory: true,
    isDefault: false,
    isInPopover: true,
    hasBundleDiscount: true,
  },
  COLLECTION_TEENS: {
    id: ["production", "preproduction"].includes(env) ? "666186995e55f7f77c3b1467" : "6659ee257d16c96d87f57e6f",
    name: "teens",
    isActive: true,
    hasSeparateList: false,
    isCategory: true,
    isDefault: false,
    isInPopover: true,
    hasBundleDiscount: true,
  },
  COLLECTION_ACCESSORIES: {
    id: ["production", "preproduction"].includes(env) ? "666199575e55f7f77c3b2a33" : "6659ee257d16c96d87f57e6f",
    name: "accessories",
    isActive: true,
    hasSeparateList: true,
    isCategory: true,
    isDefault: false,
    isInPopover: true,
    hasBundleDiscount: true,
  },
};

const shipping = {
  line: {
    title: "Standard",
    price: "5",
  },
  fees: 5,
  freeLimit: Infinity,
};

const socialInfo = {
  WHATSAPP_SUPPORT_NUMBER: "+96176046426",
  FACEBOOK_PAGE_HANDLE: "myshemsi",
  INSTAGRAM_HANDLE: "myshemsi.lebanon",
  YOUTUBE_CHANNEL: "UCS0B5xRuLR8KZTsQTmDmrHg",
  TIKTOK_CHANNEL: "myshemsi",
  SUPPORT_START_HOUR: 8,
  SUPPORT_END_HOUR: 1,
};

const productsData = [
  {
    internalName: "ipanema",
    shopifyIds: [
      // Dev
      7060457160840, 7075133456520,

      // Prod
      7933561700579,

      // Prod packs
      7937883013347,

      // staging single
      7481930645660,

      // staging packs
      7481929564316,

      // shemsi lebanon
      7343475589317, 7343476015301,
    ],
    ratings: 12500,
  },
  {
    internalName: "bahia",
    shopifyIds: [
      // Dev
      7060425605256, 7075128737928,

      // Prod
      7937881014499,

      // Prod packs
      7937882947811,

      // staging single
      7481930383516,

      // staging packs
      7481929891996,

      // shemsi lebanon
      7343475785925, 7343475949765,
    ],
    ratings: 5600,
  },
  {
    internalName: "alexandria",
    shopifyIds: [
      // Dev
      7060417052808, 7075132670088,

      // Prod
      7937880948963,

      // Prod pack alexandria
      7937882915043,

      // staging single
      7481930481820,

      // staging packs
      7481930121372,

      // shemsi lebanon
      7343475654853, 7343475884229,
    ],
    ratings: 7520,
  },
  {
    internalName: "havana",
    shopifyIds: [
      // Dev
      7075118612616, 7060449493128,

      // Prod
      7937881112803,

      // Prod pack
      7937882980579,

      // staging single
      7481930285212,

      // staging packs
      7481929760924,

      // shemsi lebanon
      7343475851461, 7343475982533,
    ],
    ratings: 9500,
  },
];

export const storeLocations: StoreLocation[] = [
  {
    storeName: "carrefour_city_mall",
    address: "carrefour_city_mall_address",
    open: false,
    storeTiming: "9 AM - 11 PM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 23, minutes: 0 },
    storeType: StoreType.Hypermarket,
    coordinates: { lng: 35.55486434099734, lat: 33.88889042887968 },
    placeId: "ChIJXWhheB0WHxURPwhvJR4UDBAVHQ3+FXM",
    googleMapsLink: "https://maps.app.goo.gl/3eZTE6UKn537uwuW6",
  },
  {
    storeName: "carrefour_city_center",
    address: "carrefour_city_center_address",
    open: false,
    storeTiming: "9 AM - 10 PM",
    openingHours: { hours: 9, minutes: 0 },
    closingHours: { hours: 22, minutes: 0 },
    storeType: StoreType.Hypermarket,
    coordinates: { lng: 35.529873097707856, lat: 33.862710171829605 },
    placeId: "ChIJUcyOrFYXHxUR5xP6mBgXAXg",
    googleMapsLink: "https://maps.app.goo.gl/23EndtzJtE6yuEtx5",
  },
  {
    storeName: "carrefour_town_center",
    address: "carrefour_town_center_address",
    open: false,
    storeTiming: "8 AM - 10 PM",
    openingHours: { hours: 8, minutes: 0 },
    closingHours: { hours: 22, minutes: 0 },
    storeType: StoreType.Hypermarket,
    coordinates: { lng: 35.6215475004266, lat: 33.950711548644655 },
    placeId: "ChIJff5G3NM_HxURKa61utN9T4QXJ2C+7J7",
    googleMapsLink: "https://maps.app.goo.gl/oqC7zUKiG2dwNWS16",
  },
];

// In case user doesn't have their location services on
export const storeLocatorMapCenter = {
  lng: 35.50156544324051,
  lat: 33.89434557111863,
};

const bundleDiscountSystems: BundleDiscountSystem[] = [
  {
    type: BundleDiscountType.bundle2,
    minimumItems: 2,
    range: ["2"],
    discountsPerItem: {
      // ipanema
      C01BM: {
        amount: 10,
        type: "percentage",
      },
      C01CM: {
        amount: 10,
        type: "percentage",
      },
      C01PM: {
        amount: 10,
        type: "percentage",
      },
      C01VM: {
        amount: 10,
        type: "percentage",
      },
      C01DM: {
        amount: 10,
        type: "percentage",
      },
      // ipanema+
      C01BF: {
        amount: 10,
        type: "percentage",
      },
      C01CF: {
        amount: 10,
        type: "percentage",
      },
      C01PF: {
        amount: 10,
        type: "percentage",
      },
      C01VF: {
        amount: 10,
        type: "percentage",
      },
      C01DF: {
        amount: 10,
        type: "percentage",
      },
      // alexandria
      C03BM: {
        amount: 10,
        type: "percentage",
      },
      // alexandria+
      C03BF: {
        amount: 10,
        type: "percentage",
      },
      // bahia
      C02BM: {
        amount: 10,
        type: "percentage",
      },
      // bahia+
      C02BF: {
        amount: 10,
        type: "percentage",
      },
      // havena
      C04BF: {
        amount: 10,
        type: "percentage",
      },
      C04CF: {
        amount: 10,
        type: "percentage",
      },
      C04PF: {
        amount: 10,
        type: "percentage",
      },
      C04VF: {
        amount: 10,
        type: "percentage",
      },
      C04DF: {
        amount: 10,
        type: "percentage",
      },
      // copacabana
      C05BM: {
        amount: 10,
        type: "percentage",
      },
      // wakiki
      C06BM: {
        amount: 10,
        type: "percentage",
      },
      C06CM: {
        amount: 10,
        type: "percentage",
      },
      // wash bag
      C08BM: {
        amount: 10,
        type: "percentage",
      },
      // travel bag
      C09BM: {
        amount: 10,
        type: "percentage",
      },
    },
  },
  {
    type: BundleDiscountType.bundle3,
    minimumItems: 3,
    range: ["3+"],
    discountsPerItem: {
      // ipanema
      C01BM: {
        amount: 15,
        type: "percentage",
      },
      C01CM: {
        amount: 15,
        type: "percentage",
      },
      C01PM: {
        amount: 15,
        type: "percentage",
      },
      C01VM: {
        amount: 15,
        type: "percentage",
      },
      C01DM: {
        amount: 15,
        type: "percentage",
      },
      // ipanema+
      C01BF: {
        amount: 15,
        type: "percentage",
      },
      C01CF: {
        amount: 15,
        type: "percentage",
      },
      C01PF: {
        amount: 15,
        type: "percentage",
      },
      C01VF: {
        amount: 15,
        type: "percentage",
      },
      C01DF: {
        amount: 15,
        type: "percentage",
      },
      // alexandria
      C03BM: {
        amount: 15,
        type: "percentage",
      },
      // alexandria+
      C03BF: {
        amount: 15,
        type: "percentage",
      },
      // bahia
      C02BM: {
        amount: 15,
        type: "percentage",
      },
      // bahia+
      C02BF: {
        amount: 15,
        type: "percentage",
      },
      // havana
      C04BF: {
        amount: 15,
        type: "percentage",
      },
      C04CF: {
        amount: 15,
        type: "percentage",
      },
      C04PF: {
        amount: 15,
        type: "percentage",
      },
      C04VF: {
        amount: 15,
        type: "percentage",
      },
      C04DF: {
        amount: 15,
        type: "percentage",
      },
      // copacabana
      C05BM: {
        amount: 15,
        type: "percentage",
      },
      // wakiki
      C06BM: {
        amount: 15,
        type: "percentage",
      },
      C06CM: {
        amount: 15,
        type: "percentage",
      },
      // wash bag
      C08BM: {
        amount: 15,
        type: "percentage",
      },
      // travel bag
      C09BM: {
        amount: 15,
        type: "percentage",
      },
    },
  },
];

const LB = new Country({
  name: CountryName.Lebanon,
  code: CountryCode.LB,
  currency: Currency.$,
  numberOfCents: 100,
  region: "MENA",
  phoneExample: "03950225",
  provinces: [],
  provincyRequiredInCheckout: false,
  zipRequiredInCheckout: true,
  supportedLanguages: [
    {
      label: LanguageName.العربية,
      value: LanguageCode.ar,
    },
    {
      label: LanguageName.English,
      value: LanguageCode.en,
      default: true,
    },
  ],
  shopifyKeys,
  omsConnector: "shopify-lebanon",
  collections,
  koalaCollections,
  checkoutMethod: "in-house",
  shipping,
  productsData,
  freeShipping: false,
  topbar: false,
  socialInfo,
  referralConfig: {
    newRefereeOnly: true,
    referralCredits: {
      amount: 50,
      amountType: "fixed_amount",
    },
    refereeCredits: {
      amount: 50,
      amountType: "fixed_amount",
    },
  },
  paymentMethods: [SupportedPaymentMethod.cashOnDelivery],
  storeLocations: storeLocations,
  storeLocatorMapCenter: storeLocatorMapCenter,
  hasPricesAbTest: false,
  // splitTestDiscount: { discountAmount: 40, discountType: "percentage" },
  bundleDiscountSystems,
  christmasTheme: false,
  multiCategory: false,
  multiColors: false,
  pargoPickupPoints: false,
  hasGiftCards: false,
  hasAccessories: false,
  ramadanTheme: false,
  areDiscountsManagedFromShopify: true,
  isDiscountCodeAllowed: true,
  homeHeroImage: ["production", "preproduction"].includes(env)
    ? 7343475654853
    : ["staging", "ci"].includes(env)
    ? 7481930481820
    : 7060417052808,
  oms: "koala",
  orderScopes: ["nps_free"],
  pl3Connector: "external-LB",
});

export default LB;
