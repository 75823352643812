import { RadioGroup } from "@headlessui/react";
import { useTranslations } from "next-intl";
import classNames from "src/helpers/classNames";
import { ValueWithStock } from "src/helpers/inventory-helpers/getOptionValuesWithStock";

interface GiftCardAmountProps {
  selectedAmount: string | null;
  setSelectedAmount(color: string): void;
  valuesWithStock: ValueWithStock[];
}

const GiftCardAmount = ({
  selectedAmount,
  setSelectedAmount,
  valuesWithStock,
}: GiftCardAmountProps) => {
  const t = useTranslations("ProductOverview");

  return (
    <RadioGroup
      value={selectedAmount}
      onChange={setSelectedAmount}
      className="mt-2"
    >
      <RadioGroup.Label className="sr-only" role="radio">
        {" "}
        {t("choose_a_size")}
      </RadioGroup.Label>
      <div className="grid grid-cols-4">
        {valuesWithStock.map(({ value, stock }) => (
          <RadioGroup.Option
            key={value}
            value={value}
            className={({ active, checked }) =>
              classNames(
                stock > 0
                  ? "cursor-pointer focus:outline-none"
                  : "cursor-default opacity-25",
                active ? "ring-2 ring-gray-900 ring-offset-2" : "",
                checked
                  ? "border-transparent bg-gray-900 text-white"
                  : "border-gray-200 bg-white text-gray-900 hover:bg-gray-50",
                "mb-2 me-2 flex min-h-[2.95rem] flex-1 items-center justify-center rounded-sm border text-sm font-medium uppercase sm:min-w-[2.95rem]"
              )
            }
            disabled={stock === 0}
          >
            <RadioGroup.Label as="span">{value}</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default GiftCardAmount;
