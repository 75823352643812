import { UserCircleIcon, CubeIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import ProfilePopover from "./profile/ProfilePopover";
import { AuthenticationContext, AuthenticationContextProps } from "src/context/authentication";
import { Fragment, useContext } from "react";
import { Popover, Transition } from "@headlessui/react";
import ThemePopoverButton from "../button/ThemePopoverButton";
import { useTranslations } from "next-intl";

interface AuthButtonProps {}

export default function AuthButton({}: AuthButtonProps) {
  const { user } = useContext(AuthenticationContext) as AuthenticationContextProps;
  const t = useTranslations("Authentication");

  return (
    <div className="ps-4">
      {user ? (
        <ProfilePopover />
      ) : (
        <div className="sm:relative">
          <Popover className="flex">
            {({ open, close }) => (
              <>
                <div className="relative flex">
                  <ThemePopoverButton id="profile_button" open={open}>
                    <UserCircleIcon className="h-7 w-7 text-gray-400 hover:text-gray-500" />
                  </ThemePopoverButton>
                </div>

                <Popover.Overlay className="fixed inset-0 -z-10 bg-black opacity-30" />

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Popover.Panel className="absolute inset-x-0 left-0 top-full z-10 w-full transform sm:left-auto sm:right-0 sm:w-48">
                    <div className="overflow-hidden rounded bg-white shadow-lg ring-1 ring-beige-700 ring-opacity-5" />
                    <div className="relative bg-white">
                      <div className="mx-auto max-w-7xl border-t">
                        <ul className="flex flex-col">
                          <li className="flex cursor-pointer items-center gap-3 border-b px-4 py-4 hover:bg-beige-500">
                            <Link
                              href={"/auth"}
                              id="header__auth-link"
                              className="flex items-center justify-center gap-1.5"
                              onClick={close}
                            >
                              <UserCircleIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" />
                              {t("sign_up")}
                            </Link>
                          </li>
                          <li className="flex cursor-pointer items-center gap-3 border-b px-4 py-4 hover:bg-beige-500">
                            <Link
                              href={"/wholesale-auth"}
                              id="header__auth-link"
                              className="flex items-center justify-center gap-1.5"
                              onClick={close}
                            >
                              <CubeIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" />
                              {t("sign_up_wholesale")}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
      )}
    </div>
  );
}
