import axios from "axios";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import getDraftOrderDiscountByType from "src/helpers/draft-order/getDraftOrderDiscountByType";
import getLocalStorage from "src/helpers/local-storage/getLocalStorage";
import preciseDecimal from "src/helpers/preciseDecimal";
import { DraftOrder } from "src/types/shopify-admin";
import { AuthenticationContext, AuthenticationContextProps } from "./authentication";
import { DraftOrderContext, DraftOrderContextProps } from "./draftOrder";
import useCountryData from "src/hooks/useCountryData";
import { DiscountType } from "src/types/discount/DiscountType";
import { ServerApiClient } from "src/apis/server-api-client";
import { ShemsiApiClient } from "src/apis/shemsi-api-client";
import { KoalaCart } from "src/types/koala/KoalaCart";
import { KoalaCheckout } from "src/types/koala/KoalaCheckout";

export interface CreditsContextProps {
  creditFactor: number | null;
  netCredits: number;
  creditsToSpend: number;
  spentCredits: number;
  creditsAreUsed: boolean;
  spendCredits(draftOrder: DraftOrder, creditsAmount: number, creditFactor: number): Promise<void>;
  unspendCredits(email: string, draftOrder: DraftOrder, creditsAmount: number, creditFactor: number): Promise<void>;
}

export const CreditsContext = createContext<CreditsContextProps | null>(null);

interface SpendCreditsResponse {
  success: boolean;
  message: string;
  sessionId: string;
  updatedDraftOrder?: DraftOrder;
  remainingCredits?: number;
}

interface KoalaSpendCreditsResponse {
  success: boolean;
  message: string;
  sessionId: string;
  updatedCart?: DraftOrder;
  remainingCredits?: number;
}

interface UnspendCreditsResponse {
  success: boolean;
  message: string;
  sessionId: string;
  updatedDraftOrder?: DraftOrder;
  addedCredits?: number;
}

interface KoalaUnspendCreditsResponse {
  success: boolean;
  message: string;
  sessionId: string;
  updatedCart?: DraftOrder;
  addedCredits?: number;
}

const serverApiClient = new ServerApiClient();
const shemsiApiClient = new ShemsiApiClient();

export default function CreditsProvider({ children }: { children: ReactNode }) {
  const { user } = useContext(AuthenticationContext) as AuthenticationContextProps;
  const countryData = useCountryData();
  const { draftOrder, setDraftOrder } = useContext(DraftOrderContext) as DraftOrderContextProps;

  const [netCredits, setNetCredits] = useState(0);
  const [creditsToSpend, setCreditsToSpent] = useState(0);
  const [spentCredits, setSpentCredits] = useState(0);
  const [creditFactor, setCreditFactor] = useState<number | null>(null);
  const [creditsAreUsed, setCreditsAreUsed] = useState<boolean>(false);

  /**
   * Sets user's net credits
   */
  useEffect(() => {
    if (user) {
      shemsiApiClient.getUserCredits().then((result) => {
        if (result.isOk() && result.value) setNetCredits(result.value.netAmount);
      });
    }
  }, [user]);

  /**
   * Sets credit factor
   */
  useEffect(() => {
    if (countryData) {
      serverApiClient.getCreditFactor(countryData.currency).then((result) => {
        if (result.isOk()) setCreditFactor(result.value);
      });
    }
  }, [countryData]);

  /**
   * Sets credits to spend
   */
  useEffect(
    function calculateCreditsToSpend() {
      if (netCredits && creditFactor && draftOrder) {
        // get shipping fees from draft order
        let shippingFees = 0;
        if (draftOrder.shipping_line) {
          shippingFees = Number(draftOrder.shipping_line.price);
        }

        /**
         * Get credit equivalent to draft order total price - shipping fees
         * as fees shouldn't be discounted from draft order
         */
        const draftOrderPriceToCredits = preciseDecimal(
          (Number(draftOrder.total_price) - shippingFees) * (1 / creditFactor)
        );

        let creditsToSpend = netCredits;
        if (netCredits > draftOrderPriceToCredits) {
          creditsToSpend = draftOrderPriceToCredits;
        }
        setCreditsToSpent(creditsToSpend);
      }
    },
    [netCredits, creditFactor, draftOrder]
  );

  /**
   * Set spentCredits and creditsAreUsed from the draft order
   */
  useEffect(() => {
    if (draftOrder && creditFactor) {
      const creditsDiscount = getDraftOrderDiscountByType(draftOrder, DiscountType.credits);

      if (creditsDiscount) {
        setCreditsAreUsed(true);
        const spentCredits = Math.round(creditsDiscount.value * (1 / creditFactor));
        setSpentCredits(spentCredits);
      } else {
        setCreditsAreUsed(false);
        setSpentCredits(0);
      }
    }
  }, [draftOrder, creditFactor]);

  const spendCredits = async (draftOrder: DraftOrder, creditsAmount: number, creditFactor: number): Promise<void> => {
    const spendCreditsResponse = await axios.put<SpendCreditsResponse>(`/api/credits/spend-credits/`, {
      countryCode: draftOrder.shipping_address.country_code,
      userCredits: creditsAmount,
      creditFactor,
      draftOrder,
    });

    const { updatedDraftOrder, remainingCredits, success, message } = spendCreditsResponse.data;

    if (!success) {
      // This an error for developers. Make sure to pass a customer error in the UI for users
      throw new Error(message);
    } else if (updatedDraftOrder && typeof remainingCredits === "number") {
      setNetCredits(remainingCredits);
      setDraftOrder(updatedDraftOrder);
    }
  };

  const unspendCredits = async (
    email: string,
    draftOrder: DraftOrder,
    creditsAmount: number,
    creditFactor: number
  ): Promise<void> => {
    const unspendCreditsResponse = await axios.put<UnspendCreditsResponse>(`/api/credits/unspend-credits/`, {
      email,
      countryCode: draftOrder.shipping_address.country_code,
      spentCredits: creditsAmount,
      creditFactor,
      draftOrder,
    });

    const { updatedDraftOrder, success, message } = unspendCreditsResponse.data;

    if (!success) {
      // This an error for developers. Make sure to pass a customer error in the UI for users
      throw new Error(message);
    } else if (updatedDraftOrder) {
      setNetCredits((credits) => preciseDecimal(credits + spentCredits));
      setDraftOrder(updatedDraftOrder);
    }
  };

  return (
    <CreditsContext.Provider
      value={{
        creditFactor,
        netCredits,
        creditsToSpend,
        spentCredits,
        creditsAreUsed,
        spendCredits,
        unspendCredits,
      }}
    >
      {children}
    </CreditsContext.Provider>
  );
}
