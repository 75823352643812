import { Cart, DiscountCode } from "src/types/storefront/Cart";

export default function getCartAppliedDiscount(
  cart: Cart
): DiscountCode | null {
  const appliedDiscount = cart.discountCodes.find(
    ({ code, applicable }) => code !== "free-shipping" && applicable
  );

  return appliedDiscount ?? null;
}
