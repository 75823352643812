import { memo } from "react";
import { useTranslations } from "next-intl";
import useCountryData from "src/hooks/useCountryData";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { CountryCode } from "src/types/localization/CountryCode";

type TopBarProps = {};

function TopBar({}: TopBarProps) {
  const t = useTranslations("Navigation");

  const countryData = useCountryData();
  const router = useRouter();

  if (!countryData || router.pathname.includes("/drshemsi")) return <></>;

  let content = (
    <>
      <span className="text-sm sm:hidden">
        {t("free_delivery_mobile", {
          currency: countryData.currency,
          amount: countryData.shipping.freeLimit,
        })}
      </span>
      <span className="hidden text-xs sm:flex xl:text-base">
        {t("free_delivery", {
          currency: countryData.currency,
          amount: countryData.shipping.freeLimit,
        })}
      </span>
    </>
  );

  if (countryData?.code === CountryCode.US) {
    content = (
      <Link href="/drshemsi" passHref legacyBehavior>
        <span className="flex cursor-pointer items-center">
          <Image
            src={"/images/dr-shemsi/dr-shemsi-chat.png"}
            alt="Chat with Dr Shemsi"
            width={48}
            height={48}
          />
          <span className=" flex gap-1 xs:text-sm lg:hidden">
            <span>{t("try_dr_shemsi_mobile")}</span>
            <span className="whitespace-nowrap font-bold">
              {t("dr_shemsi_mobile")}
            </span>
            <span>{t("ai_gynaecologist_mobile")}</span>
          </span>

          <span className="text-md hidden gap-1 lg:flex  xl:text-base">
            <span>{t("questions_about_period_desktop")}</span>
            <span>{t("try_dr_shemsi_mobile")}</span>
            <span className="font-bold">{t("dr_shemsi_mobile")}</span>
            <span>{t("anonymous_ai_doctor_desktop")}</span>
          </span>
        </span>
      </Link>
    );
  }

  if (countryData?.code === CountryCode.EG) {
    content = (
      <span className="text-xs lg:text-base">
        {t("free_delivery_and_sitewide_discount", {
          currency: countryData.currency,
          amount: countryData.shipping.freeLimit,
          discountPercentage: countryData.splitTestDiscount?.discountAmount,
        })}
      </span>
    );
  }

  return (
    <p className="flex h-[40px] items-center justify-center bg-beige-500 px-4 text-beige-800">
      {content}
    </p>
  );
}

export default memo(TopBar);
