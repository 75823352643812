import { Product } from "src/types/storefront";
import { Filter } from "src/types/filters";
import getValuesByName from "./getValuesByName";

export default function productMatchFilters(
	product: Product,
	filters: Filter[]
): boolean {
	return filters.every((filter) =>
		filter.values.some((value) =>
			getValuesByName(product.options, filter.name).includes(value)
		)
	);
}
