import { APIError } from "src/types/api-client/Error";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { Result, ok } from "neverthrow";
import handleApiError from "./handleApiError";
import axiosRetry, { IAxiosRetryConfig } from "axios-retry";

export default class ApiClient {
  private client: AxiosInstance;

  constructor(requestConfig: AxiosRequestConfig = {}) {
    this.client = this.createAxiosClient(requestConfig);
  }

  private createAxiosClient(requestConfig: AxiosRequestConfig): AxiosInstance {
    const axiosClient = axios.create({
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      ...requestConfig,
    });

    this.setRetryConfiguration(axiosClient);

    return axiosClient;
  }

  private setRetryConfiguration(apiClient: AxiosInstance): void {
    const retryConfig: IAxiosRetryConfig = {
      retries: 4,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition: (error) => {
        // Network errors
        if (
          error.message.toLowerCase().includes("network error") ||
          error.message.toLowerCase().includes("timeout exceeded")
        ) {
          console.count("Network error. Retrying. 🔁");
          console.error(JSON.stringify(error));
          return true;
        }

        if (error.response) {
          if (error.response.status === 500) {
            console.count("500 error. Retrying. 🔁");
            console.error(JSON.stringify(error));
            return true;
          }
        }

        if (axiosRetry.isRetryableError(error)) {
          console.count("retryable error. Retrying. 🔁");
          console.error(JSON.stringify(error));
          return true;
        }

        return false;
      },
    };

    axiosRetry(apiClient, retryConfig);
  }

  async request<TResponse>(
    config: AxiosRequestConfig
  ): Promise<Result<TResponse, APIError>> {
    try {
      const response = await this.client(config);
      return ok(response.data);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async get<TResponse>(
    path: string,
    config: AxiosRequestConfig<any> = { headers: {} }
  ): Promise<Result<TResponse, APIError>> {
    try {
      const response = await this.client.get<TResponse>(path, config);
      return ok(response.data);
    } catch (error: any) {
      return handleApiError<TResponse>(error);
    }
  }

  async post<TRequest, TResponse>(
    path: string,
    payload: TRequest,
    config: AxiosRequestConfig<any> = { headers: {} }
  ): Promise<Result<TResponse, APIError>> {
    try {
      const response = await this.client.post<TResponse>(path, payload, config);
      return ok(response.data);
    } catch (error) {
      return handleApiError<TResponse>(error);
    }
  }

  async put<TRequest, TResponse>(
    path: string,
    payload: TRequest,
    config: AxiosRequestConfig<any> = { headers: {} }
  ): Promise<Result<TResponse, APIError>> {
    try {
      const response = await this.client.put<TResponse>(path, payload, config);
      return ok(response.data);
    } catch (error) {
      return handleApiError<TResponse>(error);
    }
  }
}
