import Country, { KoalaCollectionConfigs } from "src/helpers/localization-helpers/countryClass";
import { SupportedPaymentMethod } from "src/types/enums/paymentMethods.enum";
import { CountryCode } from "src/types/localization/CountryCode";
import { CountryName } from "src/types/localization/CountryName";
import { Currency } from "src/types/localization/Currency";
import { LanguageCode } from "src/types/localization/LanguageCode";
import { LanguageName } from "src/types/localization/LanguageName";

const env = process.env.NEXT_PUBLIC_ENV;

const shopifyKeys = {
  STORE_NAME: process.env.NEXT_PUBLIC_SHOPIFY_STORE_NAME_US ?? "",
  STOREFRONT_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_TOKEN_US ?? "",
  ADMIN_TOKEN: process.env.SHOPIFY_ADMIN_TOKEN_US ?? "",
};

const collections = {
  COLLECTION_ALL_ACTIVE_PRODUCTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431060636 : 430752923966,
    name: "all",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ALL_SINGLE_PRODUCTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431158940 : 430752760126,
    name: "all_single",
    isActive: true,
    hasSeparateList: false,
    isCategory: false,
    isDefault: true,
    isInPopover: true,
    hasBundleDiscount: false,
  },
  COLLECTION_ALL_PACKS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431060636 : 430752858430,
    name: "packs",
    isActive: true,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: true,
    hasBundleDiscount: false,
  },
  COLLECTION_UNDERWEARS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 298026959004 : 430752923966,
    name: "underwears",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_LADIES: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297751806108 : 430752923966,
    name: "ladies",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_TEENS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297688531100 : 430752923966,
    name: "teens",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_GIFTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 298701947036 : 0,
    name: "gifts",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ACCESSORIES: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297751740572 : 430752923966,
    name: "accessories",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
};

const koalaCollections: KoalaCollectionConfigs = {
  COLLECTION_ALL_ACTIVE_PRODUCTS: {
    id: ["production", "preproduction"].includes(env) ? "66614badd9470fe2c0ea8a6b" : "6659ee2f7d16c96d87f57e71",
    name: "all",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_UNDERWEARS: {
    id: ["production", "preproduction"].includes(env) ? "666186bc5e55f7f77c3b1488" : "6659ee2f7d16c96d87f57e71",
    name: "underwears",
    isActive: true,
    hasSeparateList: false,
    isCategory: false,
    isDefault: true,
    isInPopover: true,
    hasBundleDiscount: true,
  },
  COLLECTION_LADIES: {
    id: "",
    name: "ladies",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_TEENS: {
    id: "",
    name: "teens",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ACCESSORIES: {
    id: "0",
    name: "accessories",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
};

const shipping = {
  line: {
    title: "Standard",
    price: "00.00",
  },
  // Calculated at checkout for US
  fees: 0,
  freeLimit: 0,
};

const socialInfo = {
  WHATSAPP_SUPPORT_NUMBER: "+12127048057",
  FACEBOOK_PAGE_HANDLE: "myshemsi",
  INSTAGRAM_HANDLE: "myshemsi",
  YOUTUBE_CHANNEL: "UCS0B5xRuLR8KZTsQTmDmrHg",
  TIKTOK_CHANNEL: "myshemsi",
  SUPPORT_START_HOUR: 8,
  SUPPORT_END_HOUR: 1,
};

const productsData = [
  {
    internalName: "ipanema",
    shopifyIds: [
      // Dev
      7060457160840, 7075133456520,

      // Prod
      7933561700579,

      // Prod packs
      7937883013347,

      // staging single
      7481930645660,

      // staging packs
      7481929564316,

      // shemsi usa
      8042252108094, 8042253615422,
    ],
    ratings: 12500,
  },
  {
    internalName: "bahia",
    shopifyIds: [
      // Dev
      7060425605256, 7075128737928,

      // Prod
      7937881014499,

      // Prod packs
      7937882947811,

      // staging single
      7481930383516,

      // staging packs
      7481929891996,

      // shemsi usa
      8042252599614, 8042253189438,
    ],
    ratings: 5600,
  },
  {
    internalName: "alexandria",
    shopifyIds: [
      // Dev
      7060417052808, 7075132670088,

      // Prod
      7937880948963,

      // Prod pack alexandria
      7937882915043,

      // staging single
      7481930481820,

      // staging packs
      7481930121372,

      // shemsi usa
      8042252435774, 8042252927294,
    ],
    ratings: 7520,
  },
  {
    internalName: "havana",
    shopifyIds: [
      // Dev
      7075118612616, 7060449493128,

      // Prod
      7937881112803,

      // Prod pack
      7937882980579,

      // staging single
      7481930285212,

      // staging packs
      7481929760924,

      // shemsi usa
      8042252730686, 8042253353278,
    ],
    ratings: 9500,
  },
];

const US = new Country({
  name: CountryName["United States"],
  code: CountryCode.US,
  currency: Currency.$,
  numberOfCents: 100,
  region: "NA",
  phoneExample: "(862) 812-3224",
  provinces: [],
  provincyRequiredInCheckout: false,
  zipRequiredInCheckout: true,
  supportedLanguages: [
    {
      label: LanguageName.English,
      value: LanguageCode.en,
      default: true,
    },
  ],
  shopifyKeys,
  omsConnector: "shopify-usa",
  collections,
  koalaCollections,
  checkoutMethod: "in-house",
  shipping,
  productsData,
  freeShipping: false,
  socialInfo,
  referralConfig: {
    newRefereeOnly: true,
    referralCredits: {
      amount: 50,
      amountType: "fixed_amount",
    },
    refereeCredits: {
      amount: 50,
      amountType: "fixed_amount",
    },
  },
  paymentMethods: [SupportedPaymentMethod.creditCard],
  hasPricesAbTest: false,
  christmasTheme: false,
  multiCategory: false,
  multiColors: false,
  pargoPickupPoints: false,
  hasGiftCards: false,
  hasAccessories: false,
  ramadanTheme: false,
  areDiscountsManagedFromShopify: true,
  isDiscountCodeAllowed: true,
  homeHeroImage: ["production", "preproduction"].includes(env)
    ? 8042252435774
    : ["staging", "ci"].includes(env)
    ? 7481930481820
    : 7060417052808,
  // oms: ["production", "preproduction", "ci"].includes(env) ? "shopify" : "koala",
  oms: "koala",
  orderScopes: ["fulfil", "nps_free", "referral"],
  pl3Connector: "external-US",
});

export default US;
