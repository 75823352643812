import {
  cloneDeep,
  flow,
  isMatch,
  matches,
  matchesProperty,
  eq,
  property,
  sumBy,
  first,
  multiply,
  divide,
  partial,
  add,
  round,
  find,
  flatMap,
  sortBy,
} from "lodash";
import { gte, lt, lte, omit, overEvery } from "lodash/fp";
import { KoalaOption, KoalaProduct } from "src/types/koala/KoalaProduct";
import { KoalaSelectedOption, KoalaVariant } from "src/types/koala/KoalaVariant";
import { ParsedUrlQuery } from "querystring";
import consts from "src/config/consts";
import { KoalaCart, KoalaInsertLineItem, KoalaLineItem, ShippingFees } from "src/types/koala/KoalaCart";
import { BundleDiscountSystem } from "src/types/bundle-discounts/BundleDiscountSystem";
import getBundleDiscountBySku from "../bundle-discounts/getBundleDiscountBySku";
import { DiscountType } from "src/types/discount/DiscountType";
import { someArrayElementIn, isIn } from "../fp/fpUtils";
import Country, { CollectionConfig, KoalaCollectionConfig } from "../localization-helpers/countryClass";
import { DiscountDetails } from "src/types/discount/DiscountDetails";
import { KoalaCheckout } from "src/types/koala/KoalaCheckout";
import { DeliveryOption } from "src/context/payment";

export interface ValueWithStock {
  value: string;
  stock: number;
}

const koalaUtils = {
  getProductsByCollectionIds(products: KoalaProduct[], collectionIds: string[]) {
    products = cloneDeep(products);
    collectionIds = cloneDeep(collectionIds);

    const result = products.filter(flow(property("collectionIds"), someArrayElementIn(collectionIds)));
    return result;
  },

  getFilteredProducts(products: KoalaProduct[], filters: KoalaOption[]) {
    products = cloneDeep(products);
    filters = cloneDeep(filters);

    const result = products.filter(this.productMatchesFilters(filters));
    return result;
  },

  sortProductsByCollection(products: KoalaProduct[], collectionIds: string[]) {
    products = cloneDeep(products);
    collectionIds = cloneDeep(collectionIds);

    let result: KoalaProduct[] = [];

    for (const collectionId of collectionIds) {
      this.getProductsByCollectionIds(products, [collectionId]).forEach(function matchAndAddProduct(product) {
        if (!result.some(matchesProperty("_id", product._id))) {
          result.push(product);
        }
      });
    }

    return result;
  },

  productMatchesFilters(filters: KoalaOption[]) {
    return (product: KoalaProduct) => {
      return filters.every((filter) => {
        const productOptionValues = this.getOptionValuesByName(product.options, filter.name);
        return filter.values.some(isIn(productOptionValues));
      });
    };
  },

  getOptionValuesByName(options: KoalaOption[], optionName: string) {
    return options.find(({ name }) => name === optionName)?.values || [];
  },

  getCheapestVariant(product: KoalaProduct) {
    product = cloneDeep(product);
    if (product.variants.length == 0) return;

    let cheapestVariant = product.variants[0];

    for (const variant of product.variants) {
      if (variant.price < cheapestVariant.price) {
        cheapestVariant = variant;
      }
    }

    return cheapestVariant;
  },

  getVariant(product: KoalaProduct, selectedOptions: KoalaSelectedOption[]) {
    product = cloneDeep(product);
    const variant = product.variants.find(matches({ selectedOptions }));
    return variant;
  },

  getAvailableVariant(product: KoalaProduct, selectedOptions: KoalaSelectedOption[]) {
    product = cloneDeep(product);
    selectedOptions = cloneDeep(selectedOptions);

    // 1. sort options by priority: Absorption => Color => Size
    const sortOrder: { [key: string]: number } = {
      Absorption: 1,
      Color: 2,
      Size: 3,
    };
    selectedOptions.sort((a, b) => sortOrder[a.name] - sortOrder[b.name]);

    for (let i = selectedOptions.length; i > 0; i--) {
      const variant = product.variants.find(
        overEvery([matches({ selectedOptions: selectedOptions.slice(0, i) }), this.isVariantAvailable.bind(this)])
      );

      if (variant) {
        return variant;
      }
    }

    return undefined;
  },

  mapQueryToSelectedOptions(query: ParsedUrlQuery) {
    query = cloneDeep(query);

    const selectedOptions: KoalaSelectedOption[] = [];
    for (const key in query) {
      if (consts.VARIANT_OPTIONS.includes(key)) {
        selectedOptions.push({ name: key, value: decodeURIComponent(query[key] as string) });
      }
    }

    return selectedOptions;
  },

  getVariantFromQuery(product: KoalaProduct, query: ParsedUrlQuery) {
    product = cloneDeep(product);
    query = cloneDeep(query);

    const selectedOptions = this.mapQueryToSelectedOptions(query);
    const variant = product.variants.find(matches({ selectedOptions }));
    return variant;
  },

  /**
   *
   * @param product
   * @param optionName e.g. Color
   * @param variantFilters e.g. [{Absorption: "Ultra"}]
   * @returns  e.g. [{value: "black", stock: 7}, {value: "blue", stock: 9}, ...]
   */
  getOptionValuesWithStock(product: KoalaProduct, optionName: string, variantFilters: KoalaSelectedOption[] = []) {
    product = cloneDeep(product);
    variantFilters = cloneDeep(variantFilters);

    const productOption = product.options.find(matchesProperty("name", optionName));
    if (!productOption) return null;

    const valuesWithStock = addStockToValues(product, productOption.values, optionName, variantFilters);

    return valuesWithStock;

    function addStockToValues(
      product: KoalaProduct,
      values: string[],
      optionName: string,
      variantFilters: KoalaSelectedOption[] = [] // [{name: "Color", value: "blue"}, {name: "Size", value: "xl"}]
    ): ValueWithStock[] {
      // [{value: "black", stock: 0}, {value: "blue", stock: 0}, ...]
      let valuesWithStock = values.map(function formatToObjectWith0Stock(value) {
        return { value, stock: 0 };
      });

      const isVariantAvailable = flow(property("quantity"), lt(0));
      const availableVariants = product.variants.filter(isVariantAvailable);

      for (const variant of availableVariants) {
        if (isMatch(variant, { selectedOptions: variantFilters })) {
          // Then this variant's value for the current option will be added to stock
          const valueToIncrease = variant.selectedOptions.find(matchesProperty("name", optionName))?.value;
          if (valueToIncrease) {
            valuesWithStock = valuesWithStock.map(function matchByValueAndIncrementStock({ value, stock }) {
              if (eq(valueToIncrease, value)) {
                return { value, stock: stock + 1 };
              }
              return { value, stock };
            });
          }
        }
      }

      return valuesWithStock;
    }
  },

  isVariantAvailable: flow(property("quantity"), lt(0)),

  getOOSItems(cart: KoalaCart, products: KoalaProduct[]) {
    const oosItems = flatMap(cart.lineItems, function isOOS(item) {
      const variant: KoalaVariant = find(flatMap(products, "variants"), matchesProperty("_id", item.variantId));
      if (variant && variant.quantity < item.quantity) {
        return [{ ...item, quantityAvailable: variant.quantity }];
      }
      return [];
    });

    return oosItems;
  },

  getCartItemByVariantId(cart: KoalaCart, variantId: string) {
    cart = cloneDeep(cart);

    const lineItem = cart.lineItems.find(matchesProperty("variantId", variantId));
    return lineItem;
  },

  getCartTotalQuantity(lineItems: KoalaInsertLineItem[]): number {
    lineItems = cloneDeep(lineItems);

    const result = sumBy(lineItems, property("quantity"));
    return result;
  },

  flattenCartLineItems(lineItems: KoalaLineItem[]): KoalaLineItem[] {
    lineItems = cloneDeep(lineItems);

    const result = lineItems.flatMap(function flatten(lineItem) {
      const toPush = Array(lineItem.quantity).fill({ ...lineItem, quantity: 1 });
      return toPush;
    });

    return result;
  },

  cartHasVariant(lineItems: KoalaLineItem[], variantId: string) {
    lineItems = cloneDeep(lineItems);

    const result = lineItems.some(matchesProperty("variantId", variantId));
    return result;
  },

  updateCartItemQuantity(lineItems: KoalaLineItem[], updatedItem: KoalaInsertLineItem) {
    lineItems = cloneDeep(lineItems);
    updatedItem = cloneDeep(updatedItem);

    let newItems: KoalaInsertLineItem[] = [];

    if (this.cartHasVariant(lineItems, updatedItem.variantId)) {
      newItems = lineItems.map(function updateByVariantId(lineItem) {
        if (lineItem.variantId == updatedItem.variantId) {
          return updatedItem;
        }
        return lineItem;
      });
    } else {
      newItems = [...lineItems, updatedItem];
    }

    return newItems;
  },

  applyBundleDiscountToCartItems(lineItems: KoalaInsertLineItem[], bundleDiscountSystems: BundleDiscountSystem[]) {
    lineItems = cloneDeep(lineItems);
    bundleDiscountSystems = cloneDeep(bundleDiscountSystems);

    // 1. Check which bundle discount system the cart is qualified to
    bundleDiscountSystems.sort((a, b) => b.minimumItems - a.minimumItems);
    const bundleDiscountSystem = bundleDiscountSystems.find(
      flow(property("minimumItems"), gte(this.getCartTotalQuantity(lineItems)))
    );
    if (!bundleDiscountSystem) {
      return lineItems.map(omit("discount"));
    }

    // 2. Start mapping the bundle system to line items
    lineItems = lineItems.map(function apply(lineItem) {
      const bundleDiscount = getBundleDiscountBySku(lineItem.sku, bundleDiscountSystem);
      lineItem.discount = {
        type: DiscountType.bundle,
        name: bundleDiscount.bundleType,
        value: bundleDiscount.amount,
        valueType: bundleDiscount.type,
      };
      return lineItem;
    });
    return lineItems;
  },

  getSelectedFiltersFromQuery(query: ParsedUrlQuery) {
    query = cloneDeep(query);

    // [["Color", "Red"], ["Absorption", "String"]]
    const selectedOptions2dArray = Object.entries(query).filter(flow(first, isIn(consts.VARIANT_OPTIONS)));
    // [{name: "Color", values: ["Red", "Black"]}]
    const formattedSelectedOptions = selectedOptions2dArray.map(function format([name, values]) {
      return {
        name,
        values: typeof values === "string" && values.length > 0 ? values.split(",") : [],
      };
    });
    return formattedSelectedOptions;
  },

  createOptions(products: KoalaProduct[]) {
    products = cloneDeep(products);

    const resultOptions: KoalaOption[] = [];

    const allOptions = products.flatMap(property<KoalaProduct, KoalaOption>("options"));

    for (const option of allOptions) {
      const optionIndex = resultOptions.findIndex(matchesProperty("name", option.name));

      if (optionIndex == -1) {
        resultOptions.push(option);
      } else {
        resultOptions[optionIndex].values = Array.from(
          new Set(resultOptions[optionIndex].values.concat(option.values))
        );
      }
    }

    return resultOptions;
  },

  getValuesWithAvailableProducts(optionName: string, values: string[], products: KoalaProduct[]) {
    products = cloneDeep(products);

    // [{value: "red", availableProducts: 0}, ...]
    const valuesWithAvailableProducts = values.map((value) => ({
      value,
      avaialbleProducts: 0,
    }));
    products.forEach((product) => {
      const valuesToIncrease = product.options.find(matchesProperty("name", optionName))?.values || [];
      valuesWithAvailableProducts.forEach(({ value }, index, array) => {
        if (valuesToIncrease.includes(value)) array[index].avaialbleProducts++;
      });
    });

    return valuesWithAvailableProducts;
  },

  getActiveCollection(countryData: Country, collectionId?: string) {
    countryData = cloneDeep(countryData);

    const collections: (CollectionConfig | KoalaCollectionConfig)[] = Object.values(
      countryData.oms == "shopify" ? countryData.collections : countryData.koalaCollections
    );
    const activeCollectionById = collections.find(function isValid(collection) {
      return collection.isActive && String(collection.id) == collectionId;
    });
    if (activeCollectionById) return activeCollectionById;

    const firstActiveCollection = collections.find(property("isActive"));
    return firstActiveCollection;
  },

  isCollectionIdValid(countryData: Country, collectionId?: string) {
    countryData = cloneDeep(countryData);

    if (!collectionId) return false;

    const collections: (CollectionConfig | KoalaCollectionConfig)[] = Object.values(
      countryData.oms == "shopify" ? countryData.collections : countryData.koalaCollections
    );
    const activeCollectionById = collections.find(function isValid(collection) {
      return collection.isActive && String(collection.id) == collectionId;
    });

    return Boolean(activeCollectionById);
  },

  calculateSingleDiscount(price: number, discount: DiscountDetails) {
    discount = cloneDeep(discount);

    const discountValueType = discount.valueType || "fixed_amount";
    if (discountValueType == "fixed_amount") {
      return discount.value;
    }
    return round(multiply(price, divide(discount.value, 100)));
  },

  calculateCheckoutTotalDiscount(checkout: KoalaCheckout) {
    const subtotal = this.calculateSubtotalPrice(checkout.lineItems);
    const cartDiscount = sumBy(checkout.discounts, partial(this.calculateSingleDiscount, subtotal));
    const itemsDiscount = this.calculateLineItemsDiscount(checkout.lineItems);

    return add(cartDiscount, itemsDiscount);
  },

  calculateLineItemPrice(lineItems: KoalaLineItem) {
    lineItems = cloneDeep(lineItems);

    return multiply(lineItems.price, lineItems.quantity);
  },

  calculateSubtotalPrice(lineItems: KoalaLineItem[]) {
    const result = sumBy(lineItems, this.calculateLineItemPrice);
    return result;
  },

  calculateLineItemsDiscount(lineItems: KoalaLineItem[]) {
    lineItems = cloneDeep(lineItems);

    const result = sumBy(lineItems, this.calculateLineItemDiscount.bind(this));
    return result;
  },

  calculateLineItemDiscount(lineItem: KoalaLineItem) {
    lineItem = cloneDeep(lineItem);

    if (lineItem.discount) {
      return this.calculateSingleDiscount(this.calculateLineItemPrice(lineItem), lineItem.discount);
    }
    return 0;
  },

  isBundle(cart: KoalaCart, bundleDiscountSystems: BundleDiscountSystem[]) {
    const totalQuantity = this.getCartTotalQuantity(cart.lineItems);

    for (const system of bundleDiscountSystems) {
      if (totalQuantity >= system.minimumItems) {
        return true;
      }
    }

    return false;
  },

  calculateShippingFees(totalPrice: number, countryData: Country, deliveryMethod: DeliveryOption): ShippingFees {
    const shippingConfig =
      deliveryMethod === DeliveryOption.pickup && countryData.pickupShipping
        ? countryData.pickupShipping
        : countryData.shipping;

    const shippingFees =
      totalPrice >= shippingConfig.freeLimit
        ? { amount: 0, type: "free-shipping" }
        : { amount: shippingConfig.fees, type: "standard" };

    return shippingFees;
  },
};

export default koalaUtils;
