import { Product } from "src/types/storefront";
import getProductsByCollectionIds from "./getProductsByCollectionIds";

/**
 * @param products
 * @param collectionIds array of collection IDs to sort products by
 * @returns sorted array or products
 */
export default function sortProductsByCollection(
  products: Product[],
  collectionIds: number[]
): Product[] {
  let result: Product[] = [];

  for (const collectionId of collectionIds) {
    getProductsByCollectionIds(products, [collectionId]).forEach((product) => {
      if (!result.some((item) => item.id === product.id)) {
        result.push(product);
      }
    });
  }

  return result;
}
