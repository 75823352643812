import { memo } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import useCountryCode from "src/hooks/useCountryCode";

type LanguageButtonProps = {
  setLanguageToCookies: (language: string) => void;
};

function LanguageButton({ setLanguageToCookies }: LanguageButtonProps) {
  const { pathname, locale, query, asPath } = useRouter();
  const countryCode = useCountryCode();
  const language = locale ? locale.split("-")[0] : "ar";

  if (!countryCode) return <></>;

  // bilingual button
  return (
    <Link
      href={{
        pathname,
        query,
      }}
      as={asPath}
      locale={language === "ar" ? `en-${countryCode}` : `ar-${countryCode}`}
      className="language-button flex items-center text-sm font-semibold"
      onClick={() => setLanguageToCookies(language === "ar" ? "en" : "ar")}
    >
      {/* language-button class is added for e2e testing */}

      {language === "ar" ? "English" : "العربية"}
    </Link>
  );
}

export default memo(LanguageButton);
