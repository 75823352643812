import { Popover } from "@headlessui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { Bars3Icon, ShoppingBagIcon } from "@heroicons/react/24/outline";
import MobileMenu from "./components/MobileMenu";
import CartSidebar from "../cart-sidebar/CartSidebar";
import { CartContext, CartContextProps } from "src/context/cart";
import ShopPopover from "./components/ShopPopover";
import LearnPopover from "./components/LearnPopover";
import CountryPopover from "src/components/country-popover/CountryPopover";
import LanguageSelector from "src/sections/header/components/LanguageComponents/LanguageSelector";
import Image from "next/image";
import Link from "next/link";
import useCountryCode from "src/hooks/useCountryCode";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import AuthButton from "src/components/auth/AuthButton";
import useCountryData from "src/hooks/useCountryData";
import { Line } from "src/types/storefront/Cart";
import flattenCartLineItems from "src/helpers/cart/flattenCartLineItems";
import classNames from "src/helpers/classNames";
import Logo from "src/components/logo/Logo";
import consts from "src/config/consts";
import RamadanLogo from "src/components/logo/RamadanLogo";
import KoalaCartSidebar from "../cart-sidebar/KoalaCartSidebar";
import { useStoreActions, useStoreState } from "src/hooks/storeHooks";
import koalaUtils from "src/helpers/koala/koalaUtils";

type HeaderProps = {};

export default function Header({}: HeaderProps) {
  const t = useTranslations("Navigation");
  const countryCode = useCountryCode();
  const countryData = useCountryData();
  const { pathname } = useRouter();
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);

  const { cart, setSidebarExpanded: setCartSidebarExpanded } = useContext(CartContext) as CartContextProps;
  const koalaCart = useStoreState((state) => state.cart);
  const setKoalaCartSidebarExpanded = useStoreActions((actions) => actions.setIsCartSidebarExpanded);

  const isCountryChristmasThemeActive = countryData?.christmasTheme;
  const isCountryRamadanThemeActive = countryData?.ramadanTheme;

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // line items
  let lineItems: Line[] = [];
  if (cart) {
    lineItems = flattenCartLineItems(cart);
  }
  const koalaLineItems = koalaCart ? koalaUtils.flattenCartLineItems(koalaCart.lineItems) : [];

  function handleHeaderStickyState() {
    setIsHeaderSticky(window.scrollY >= consts.CHRISTMAS_TOPBAR_HEIGHT_IN_PX);
  }

  function handleCartClick() {
    if (countryData?.oms == "shopify") {
      setCartSidebarExpanded(true);
    } else {
      setKoalaCartSidebarExpanded(true);
    }
  }

  useEffect(() => {
    if (isCountryChristmasThemeActive) {
      handleHeaderStickyState();
      window.addEventListener("scroll", handleHeaderStickyState);
    }

    return () => window.removeEventListener("scroll", handleHeaderStickyState);
  }, [countryData]);

  // console.log("🦇", countryData?.oms, { lineItems, koalaLineItems });

  return (
    <header>
      {/* Mobile menu */}
      <MobileMenu open={mobileMenuOpen} setOpen={setMobileMenuOpen} />

      {countryData?.oms == "shopify" ? <CartSidebar /> : <KoalaCartSidebar />}

      <nav className="relative z-10" aria-label="Top">
        {/* navigation */}
        <div className={classNames("transition", isHeaderSticky || !isCountryChristmasThemeActive ? "bg-white" : "")}>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="border-b border-gray-200">
              <div className="flex h-16 justify-between">
                {/* Logo (lg+) */}
                <div className="relative hidden lg:me-6 lg:flex lg:items-center">
                  <Link href={"/"} className="py-3">
                    {isCountryRamadanThemeActive ? (
                      <RamadanLogo />
                    ) : (
                      <Logo className={classNames("w-auto", isCountryChristmasThemeActive ? "h-9" : "h-6")} />
                    )}
                  </Link>
                </div>

                {/* Flyout menus */}
                <div className="hidden lg:flex lg:h-full">
                  <Popover.Group className="inset-x-0 bottom-0 px-4">
                    <div className="flex h-full justify-center">
                      <ShopPopover />
                      <div className="ms-7 flex">
                        <LearnPopover />
                      </div>
                    </div>
                  </Popover.Group>
                </div>

                {/* Mobile menu */}
                <div className="flex flex-1 items-center lg:hidden">
                  <button
                    type="button"
                    aria-label="open mobile menu"
                    id="mobile_menu"
                    className="-ms-2 rounded-sm p-3 text-gray-400"
                    onClick={() => setMobileMenuOpen(true)}
                  >
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Logo (lg-) */}
                <Link href={"/"} className="relative flex items-center py-3 lg:hidden">
                  {isCountryRamadanThemeActive ? (
                    <RamadanLogo />
                  ) : (
                    <Logo className={classNames("w-auto", isCountryChristmasThemeActive ? "h-9" : "h-6")} />
                  )}
                </Link>

                {/* Right */}
                <div className="flex flex-1 items-center justify-end gap-x-8">
                  {/* Create a Set */}
                  {countryData?.bundleDiscountSystems && (
                    <Link className="hidden rounded-full bg-beige-500 px-2 py-1 text-sm lg:block" href="/customize-set">
                      {t("create_set")}
                    </Link>
                  )}

                  {/* Language and Country settings */}
                  <Popover.Group className="relative hidden lg:flex">
                    {countryCode === "US" ? (
                      <div className="relative flex items-center">
                        <Image
                          className="w-5"
                          src={`/images/coded-flags/${countryCode}.svg`}
                          alt=""
                          width={0}
                          height={0}
                        />
                        <span className="ms-2 leading-4 lg:ms-2.5 lg:flex lg:flex-col lg:items-start">
                          {t("deliver_to")} <span className="font-bold">{t("country")}</span>
                        </span>
                      </div>
                    ) : (
                      <CountryPopover />
                    )}
                    {countryCode !== "US" && <span className="mx-4 self-center text-gray-400">|</span>}
                    <div className="flex">
                      <LanguageSelector />
                    </div>
                    {/* )} */}
                  </Popover.Group>

                  {/* Cart & Authentication*/}
                  <div className="flex">
                    <div className="flex">
                      {/* #cart-sidebar-toggler is added for e2e testing */}
                      <button
                        id="cart-sidebar-toggler"
                        className="group -m-2 flex items-center px-2 py-3"
                        type="button"
                        onClick={handleCartClick}
                      >
                        <ShoppingBagIcon
                          className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        <span className="ms-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                          {countryData?.oms == "shopify" ? lineItems.length : koalaLineItems.length}
                        </span>
                      </button>
                    </div>
                    {pathname !== "/auth" && (
                      <div className="flex items-center">
                        <AuthButton />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
