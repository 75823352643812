import { captureException } from "@sentry/nextjs";
import { useState } from "react";
import highlightError from "src/helpers/highlight/highlightError";
import retryAsync from "src/helpers/retryAsync";

interface Config {
  customError?: string;
  retry?: boolean;
  retryCount?: number;
  retryTimeout?: number;
}

type FunctionReturningPromise = (...args: any[]) => Promise<any>;

export default function useEnhancedAsync<T extends FunctionReturningPromise>(
  asyncFunction: T,
  config: Config = { retry: false, retryTimeout: 0, retryCount: 3 }
) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [developerError, setDeveloperError] = useState<string | null>(null);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const enhancedAsyncFunction = async function (
    ...args: Parameters<T>
  ): Promise<Awaited<ReturnType<T>> | null> {
    setLoading(true);

    try {
      const result = await (config.retry
        ? retryAsync(
            () => asyncFunction(...args),
            config.retryCount,
            config.retryTimeout
          )
        : asyncFunction(...args));
      setError(null);
      setLoading(false);
      setIsSuccessful(true);
      return result;
    } catch (error: any) {
      console.error(error);
      highlightError(error, error.message);
      const developerError =
        error instanceof Error ? error.message : "Error loading the data";
      setError(config.customError ?? developerError);
      setDeveloperError(developerError);
      setLoading(false);
      return null;
    }
  };

  const result: [
    (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>> | null>,
    boolean,
    string | null,
    string | null,
    boolean
  ] = [enhancedAsyncFunction, loading, error, developerError, isSuccessful];

  return result;
}
