import { Product } from "src/types/storefront";

export default function getProductsByCollectionIds(
  products: Product[],
  collectionIds: number[]
) {
  const result = products.filter((product) =>
    product.collections.some((collection) =>
      collectionIds.some((id) => collection.id.includes(String(id)))
    )
  );

  return result;
}
