import gql from "src/helpers/gql";
import withoutEdgesAndNodes from "src/helpers/storefront-helpers/withoutEdgesAndNodes";
import { Cart, StorefrontCart } from "src/types/storefront/Cart";
import storefront, { StorefrontError } from "../storefront";
import { CountryCode } from "src/types/localization/CountryCode";
import { ServerApiClient } from "src/apis/server-api-client";
import highlightError from "src/helpers/highlight/highlightError";
import Country from "src/helpers/localization-helpers/countryClass";

const mutation = gql`
  mutation CartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        id
        createdAt
        lines(first: 100) {
          edges {
            node {
              id
              attributes {
                key
                value
              }
              quantity
              discountAllocations {
                discountedAmount {
                  amount
                  currencyCode
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  sku
                  image {
                    url
                    altText
                  }
                  product {
                    handle
                    title
                    images(first: 50) {
                      edges {
                        node {
                          altText
                          url
                        }
                      }
                    }
                    variants(first: 1) {
                      edges {
                        node {
                          id
                          sku
                          price {
                            amount
                            currencyCode
                          }
                          compareAtPrice {
                            amount
                            currencyCode
                          }
                          selectedOptions {
                            name
                            value
                          }
                          quantityAvailable
                        }
                      }
                    }
                  }
                  price {
                    amount
                    currencyCode
                  }
                  compareAtPrice {
                    amount
                    currencyCode
                  }
                  selectedOptions {
                    name
                    value
                  }
                  quantityAvailable
                }
              }
            }
          }
        }
        attributes {
          key
          value
        }
        cost {
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalAmount {
            amount
            currencyCode
          }
        }
        discountCodes {
          applicable
          code
        }
        discountAllocations {
          discountedAmount {
            amount
            currencyCode
          }
        }
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`;

interface UpdateResponse {
  data: {
    cartDiscountCodesUpdate: {
      cart: StorefrontCart | null;
      userErrors: StorefrontError[];
    };
  };
}

const serverApiClient = new ServerApiClient();

export default async function updateCartWithDiscountCodes(
  countryData: Country,
  cartId: string,
  discountCodes: string[]
): Promise<Cart | null> {
  console.log("updateCartWithDiscountCodes");

  // 1. Check discount code in the database
  if (!countryData.areDiscountsManagedFromShopify) {
    const dbDiscount = await serverApiClient.getDiscountCode(discountCodes[0]);
    if (dbDiscount.isErr()) {
      highlightError({
        name: "Server Error",
        message: dbDiscount.error.errorMessage || "",
      });
      discountCodes = [];
    } else if (dbDiscount.value.deActivated) {
      highlightError({
        name: "Discount Deactivated",
        message: `Discount: ${discountCodes[0]} is deactivated in the database`,
      });
      discountCodes = [];
    }
  }

  const updateResponse: UpdateResponse = await storefront(
    countryData.code,
    mutation,
    {
      cartId,
      discountCodes: discountCodes.map((code) => code.toLowerCase()),
    }
  );

  if (!updateResponse.data.cartDiscountCodesUpdate) {
    throw new Error(
      `Wrong data structure from storefront ${JSON.stringify(
        updateResponse.data
      )}, cart ID: ${cartId}, discount codes: ${JSON.stringify(discountCodes)}`
    );
  }

  const errors = updateResponse.data.cartDiscountCodesUpdate.userErrors;
  if (errors && errors.length > 0) {
    throw new Error(
      updateResponse.data.cartDiscountCodesUpdate.userErrors
        .map((error) => error.message)
        .join(", ")
    );
  }

  const cart = withoutEdgesAndNodes(
    updateResponse.data.cartDiscountCodesUpdate.cart
  );

  return cart;
}
