import { RadioGroup } from "@headlessui/react";
import Absorptions from "./Absorptions";
import classNames from "src/helpers/classNames";
import { ValueWithStock } from "src/helpers/inventory-helpers/getOptionValuesWithStock";

type Props = {
  selectedAbsorption: string | null;
  onChange(color: string): void;
  valuesWithStock: ValueWithStock[];
};

function AbsorptionPicker({
  valuesWithStock,
  selectedAbsorption,
  onChange,
}: Props) {
  return (
    <RadioGroup
      value={selectedAbsorption}
      // onChange={handleAbsorptionSelect}
      onChange={onChange}
    >
      <div className="flex gap-2">
        {valuesWithStock.map(({ value, stock }) => (
          <RadioGroup.Option
            key={value}
            value={value}
            className={({ checked, disabled, active }) =>
              classNames(
                "border p-1",
                (active || checked) && !disabled
                  ? "!border-gray-900"
                  : "border-gray-300",
                disabled
                  ? "cursor-default opacity-50"
                  : "cursor-pointer hover:border-beige-700"
              )
            }
            disabled={stock === 0}
          >
            <RadioGroup.Label className="cursor-[inherit]">
              <Absorptions value={value} imageClassName="w-3" withText={true} />
            </RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}

export default AbsorptionPicker;
