import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { ReferralContext, ReferralContextProps } from "./ReferralContext";
import { useRouter } from "next/router";
import getTrackingMetadata from "src/helpers/event-tracking/getTrackingMetadata";
import { ReferralConfig } from "src/types/localization/ReferralConfig";
import { ParsedUrlQuery } from "querystring";

export interface ConversionSourceContextProps {
  conversionSources: string[];
  referrerUrl: string;
}

export const ConversionSourceContext =
  createContext<ConversionSourceContextProps>({
    conversionSources: [],
    referrerUrl: "",
  });

export default function ConversionSourceProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [conversionSources, setConversiontSources] = useState<string[]>([]);
  const [referrerUrl, setReferrerLink] = useState("");

  // Dependencies
  const router = useRouter();
  const { activeReferralConfig } = useContext(
    ReferralContext
  ) as ReferralContextProps;

  function handleReferrerLink() {
    setReferrerLink(document.referrer);
  }

  function handleConversionSource(
    activeReferralConfig: ReferralConfig | null,
    query: ParsedUrlQuery,
    setConversiontSources: Dispatch<SetStateAction<string[]>>
  ) {
    const trackingMetadata = getTrackingMetadata();

    enum ConversionSource {
      facebook = "facebook",
      snapchat = "snapchat",
      tiktok = "tiktok",
      barcode = "barcode",
      referral = "referral",
      affiliate = "affiliate",
      klaviyo = "klaviyo",
      fb_whatsapp = "fb_whatsapp",
    }

    const conversionSourceChecks = {
      facebook: trackingMetadata.fbp && trackingMetadata.fbc,
      snapchat: trackingMetadata.ScCid,
      tiktok: trackingMetadata.ttclid,
      barcode: query.referrer === "box_barcode",
      referral: activeReferralConfig && !activeReferralConfig.affiliate,
      affiliate: activeReferralConfig && activeReferralConfig.affiliate,
      klaviyo: query._kx,
      fb_whatsapp: query.referrer === "fb_whatsapp",
    };

    setConversiontSources((prevConversionSources) => {
      let newConversionSources = [...prevConversionSources];

      for (const source in conversionSourceChecks) {
        const isActiveSource =
          conversionSourceChecks[source as ConversionSource];

        if (isActiveSource) {
          newConversionSources.push(source);
        }
      }

      // Add organic if none of the defined sources exist
      const conversionSourceExists = newConversionSources.some((source) =>
        Object.keys(conversionSourceChecks).includes(source)
      );
      if (conversionSourceExists) {
        newConversionSources = newConversionSources.filter(
          (source) => source !== "organic"
        );
      } else {
        newConversionSources.push("organic");
      }

      // Remove dulpicates
      newConversionSources = Array.from(new Set(newConversionSources));

      return newConversionSources;
    });
  }

  useEffect(() => {
    handleConversionSource(
      activeReferralConfig,
      router.query,
      setConversiontSources
    );
  }, [activeReferralConfig, router.query, setConversiontSources]);

  useEffect(() => {
    handleReferrerLink();
  }, []);

  return (
    <ConversionSourceContext.Provider
      value={{ conversionSources, referrerUrl }}
    >
      {children}
    </ConversionSourceContext.Provider>
  );
}
