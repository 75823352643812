enum GoogleAnalyticsEventsEnum {
  VIEW_ITEM_DETAIL_PAGE = "view_item",
  PAGE_VIEW = "page_view",
  ADD_ITEM_TO_CART = "add_to_cart",
  REMOVE_ITEM_FROM_CART = "remove_from_cart",
  ADD_SHIPPING_INFO = "add_shipping_info",
  ADD_PAYMENT_INFO = "add_payment_info",
  VISIT = "visit",
  GENERATE_LEAD = "generate_lead",
  CHANGE_VARIANT = "variant_change",
  VIEW_CHECKOUT_PAGE = "begin_checkout",
  COMPLETE_PURCHASE = "complete_purchase_fallback",
  SUBSCRIBED_NEWSLETTER = "subscribed_to_newsletter",
  SENT_EMAIL_USING_FORM = "contact",
  VIDEO_PLAY = "video_play",
}

export default GoogleAnalyticsEventsEnum;
