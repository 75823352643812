import { captureException } from "@sentry/nextjs";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import getCollectionById from "src/apis/storefront-latest-version/collections/getCollectionById";
import Country from "src/helpers/localization-helpers/countryClass";
import useCountryData from "src/hooks/useCountryData";
import { Collection } from "src/types/storefront";

async function validateStoreMetadata(
  countryData: Country,
  storeMetadata: Collection,
  setStoreMetadata: Dispatch<SetStateAction<Collection>>
) {
  const configCurrency =
    countryData.currency === "$" ? "USD" : countryData.currency;
  const storefrontCurrency =
    storeMetadata.products[0].variants[0].price.currencyCode;
  const isCurrencyMismatch = storefrontCurrency !== configCurrency;

  if (isCurrencyMismatch) {
    console.log("💀 Storefront mismatch!", {
      storefrontCurrency,
      configCurrency,
    });
    try {
      const correctMetadata = await getCollectionById(
        countryData.code,
        countryData.collections.COLLECTION_ALL_ACTIVE_PRODUCTS.id,
        true
      );
      setStoreMetadata(correctMetadata);
    } catch (error: any) {
      captureException(error.message);
    }
  }
}

interface StoreMetadataProviderProps {
  children: ReactNode;
  storeMetadata: Collection;
}

export const StoreMetadataContext = createContext<Collection | null>(null);

function StoreMetadataProvider({
  children,
  storeMetadata: serverStoreMetadata,
}: StoreMetadataProviderProps) {
  const countryData = useCountryData();
  const [storeMetadata, setStoreMetadata] = useState(serverStoreMetadata);

  useEffect(() => {
    setStoreMetadata(serverStoreMetadata);
  }, [serverStoreMetadata]);

  useEffect(() => {
    const env = process.env.NEXT_PUBLIC_ENV;
    // The environment check is to prevent validating in staging environment as currency for all deployment is EGP
    if (
      storeMetadata &&
      countryData &&
      ["production", "preproduction"].includes(env)
    ) {
      validateStoreMetadata(countryData, storeMetadata, setStoreMetadata);
    }
  }, [storeMetadata]);

  return (
    <StoreMetadataContext.Provider value={storeMetadata}>
      {children}
    </StoreMetadataContext.Provider>
  );
}

export default StoreMetadataProvider;
