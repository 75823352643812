import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import React from "react";

type ArrowIconProps = {
  rtl: boolean;
  className: string;
};

const ArrowIcon = ({ rtl, className }: ArrowIconProps) => {
  return rtl ? (
    <ArrowLeftIcon className={className} />
  ) : (
    <ArrowRightIcon className={className} />
  );
};

export default ArrowIcon;
