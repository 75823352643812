export default function removeFromLocalStorage(...keys: string[]) {
	if (typeof window === "undefined") return null;

	keys.forEach((key) => {
		try {
			window.localStorage.removeItem(key);
		} catch (error) {
			const message =
				error instanceof Error ? error.message : `Error removing ${key}.`;
			console.log({ message });
		}
	});
}
