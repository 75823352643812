import axios from "axios";
import EventTracker from "./event-tracking/eventTrackingManager";
import ApiClient from "./api-client/apiClient";
import { Result, err, ok } from "neverthrow";
import { APIError } from "src/types/api-client/Error";

interface NewsletterProps {
  email: string;
  source: string;
  country?: string;
  deployment?: string;
  mixpanelUserId?: string;
}

const apiClient = new ApiClient();

export default async function subscribeToNewsletter({
  email,
  source,
  country,
  deployment,
  mixpanelUserId,
}: NewsletterProps): Promise<Result<any, APIError>> {
  type Request = {
    email: string;
    source: string;
    country?: string;
    deployment?: string;
    mixpanelUserId?: string;
  };

  type Response = {
    success: boolean;
    error?: string;
  };

  const result = await apiClient.post<Request, Response>(
    "/api/newsletter/newsletter",
    {
      email,
      source,
      country,
      deployment,
      mixpanelUserId,
    }
  );

  if (result.isErr()) {
    return err(result.error);
  }

  if (!result.value.success) {
    return err({
      errorMessage: result.value.error,
    });
  }

  EventTracker.subscribeToNewsletter(email, source, 0);
  return ok(result.value);
}
