import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { NewPriceCollectionContext } from "src/context/NewPriceCollectionContext";
import { Variant } from "src/types/storefront";
import useCountryData from "./useCountryData";
import getVariantBySkuFromCollection from "src/helpers/storefront-helpers/getVariantBySkuFromCollection";
import useGrowthbookFeature from "./useGrowthbookFeature";

export default function useAbVariantPrice(
  variant: Variant | null
): number | null {
  const countryData = useCountryData();
  const newPriceCollection = useContext(NewPriceCollectionContext);

  const [price, setPrice] = useState<number | null>(null);

  const isPriceTestActiveGrowthbook = useGrowthbookFeature(
    "10-percent-price-decrease",
    false
  );

  useEffect(() => {
    const isNewPriceTestActive = Cookies.get("is_price_test_active") === "true";
    if (variant) {
      let price = Number(variant.price.amount);
      if (
        countryData?.hasPricesAbTest &&
        isNewPriceTestActive &&
        newPriceCollection
      ) {
        const newPriceVariant = getVariantBySkuFromCollection(
          newPriceCollection,
          variant.sku
        );
        if (newPriceVariant) price = Number(newPriceVariant.price.amount);
      }
      setPrice(price);
    }
  }, [variant, countryData, newPriceCollection]);

  return price;
}
