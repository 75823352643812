import getCookie from "./cookie/getCookie";

const getGoogleAnalyticsClientId = () => {
  const clientId = getCookie("_ga") || "";
  if (getCookie("_ga")) {
    return clientId.slice(6);
  }
};

export default getGoogleAnalyticsClientId;
