import Image from "next/image";
import classNames from "src/helpers/classNames";
import { KoalaImage } from "src/types/koala/KoalaImage";

type Props = {
  images: KoalaImage[];
  imageClassName?: string;
};

function KoalaPreview({ images, imageClassName = "" }: Props) {
  return (
    <div className={classNames("relative aspect-[6/8] animate-pulse-bg", imageClassName)}>
      {images.map((image, index) => (
        <Image
          key={index}
          className={classNames(
            "w-full object-cover object-center transition-opacity",
            index === 1 ? "opacity-0 transition-opacity group-hover:opacity-100" : ""
          )}
          src={image.url}
          alt={""}
          sizes="
            (min-width: 20em) 12rem,
            (min-width: 27em) 21rem,
            (min-width: 64em) 15rem,
            50vw"
          fill
          width={0}
          height={0}
        />
      ))}
    </div>
  );
}

export default KoalaPreview;
