import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { DBUser } from "src/types/database/DBUser";
import { captureException } from "@sentry/nextjs";
import useEnhancedAsync from "src/hooks/useEnhancedAsync";
import { ShemsiApiClient } from "src/apis/shemsi-api-client";
import Cookies from "js-cookie";

export interface AuthenticationContextProps {
  user: DBUser | null;
  setUser: Dispatch<SetStateAction<DBUser | null>>;
  isUserLoading: boolean;
}

interface AuthenticationProviderProps {
  children: ReactNode;
}

export const AuthenticationContext =
  createContext<AuthenticationContextProps | null>(null);

const shemsiApiClient = new ShemsiApiClient();

export function AuthenticationProvider({
  children,
}: AuthenticationProviderProps) {
  const [user, setUser] = useState<DBUser | null>(null);
  const [getAndSetUser, isUserLoading] = useEnhancedAsync(async () => {
    const userResult = await shemsiApiClient.getUserByToken();

    if (userResult.isErr()) {
      captureException(JSON.stringify(userResult.error));
      return;
    }

    setUser(userResult.value);
  });

  useEffect(() => {
    if (Cookies.get("token")) getAndSetUser();
  }, []);

  return (
    <AuthenticationContext.Provider value={{ user, setUser, isUserLoading }}>
      {children}
    </AuthenticationContext.Provider>
  );
}
